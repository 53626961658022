// Theming
import * as React from 'react';
import {Tile} from 'react-bulma-components';
import Skeleton from '@mui/material/Skeleton';

  
export default function SkeletonHeaderOrFooter (props) {

    return (
        <Tile className={'skeleton-header-or-footer'}>
            <Tile kind="parent" vertical size={12}>
                <Skeleton variant="rectangular" className={''} height={40} />
            </Tile>
        </Tile>
)
}
