// Theming
import * as React from 'react';
import * as utils from '../../../../core/utils';

export default function FeaturedDescription (props) {

    // We'll get title and description from the description object
    const descriptionObject = utils.getDescriptionObjectForProperty(props.for, props.t, props.lang);
    const descriptionUnformatted = descriptionObject ? descriptionObject.description : null;

    // Change all the '~' symbols to 2 enters to make the description look better
    const description = descriptionUnformatted ? descriptionUnformatted.replace(/~/g,  '<br>') : null;
    
    // debugger;
    return (
        <div className="featured-description-container">
            <div className="result-info-description" onClick={props.handleSetSelectedAndOpenDetail} itemID={props.for.id} data-cod-ofer={props.for.cod_ofer}>
                
                <span dangerouslySetInnerHTML={{ __html: description }} />
                <span className="ellipsis-gradient"></span>
            </div>
        </div>
    )
}
