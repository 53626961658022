// Theming
import { Tile, Tag, Button, Box, Level } from 'react-bulma-components';
import * as React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import SummaryNavigator from '../summaryNavigator';
import ReactTooltip from 'react-tooltip';
  
export default function SummaryFooter (props) {

    const { handleNotSelected } = props;

    // Rebuild tooltips
    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    const renderGoBackToSearch = () => {
        return <Tile className="one-filter go-back-search">
        <Tag.Group hasAddons onClick={handleNotSelected}>
        <Tag color="transparent" size={'large'}>
        
            {/* Go Back Icon */}
            <ArrowBackIcon color='light'/>
        
        </Tag>
        <Tag color="transparent" size={'large'}>
            {props.t("searchEngine.label.goBackToSearch")}
        </Tag>
    </Tag.Group>

    </Tile>
    }

    const renderGoToTopLink = () => {
        return (
            <div className="go-to-top">
                <Button
                    className="go-to-top-button"
                    onClick={props.executeScroll}
                    data-tip={props.t("searchEngine.label.gotoTopText")}
                    data-border="false"
                    data-size="medium"
                    data-effect="solid"
                    data-offset="{'top': 10, 'left': 0}"
                    data-place="top"
                    data-html="true"
                    data-delay-show={100}
                >
                    <ArrowUpwardIcon />
                </Button>
            </div>
        );
    };
    
    return (
        <div className="search-results-summary-container">
            <Box className="summary is-shady ">
                <Level className="summary-level is-fullwidth is-mobile">
                <Level.Side align='left'>
                    <Level.Item>
                        {renderGoBackToSearch()}
                    </Level.Item>
                </Level.Side>
                <Level.Side align='right'>
                        <Level.Item>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <SummaryNavigator {...props} />
                
                        </Level.Item>
                    </Level.Side>
                </Level>
            </Box>
            {renderGoToTopLink()}
        </div>
    )
}
