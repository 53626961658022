// Theming
import { Image, Box, Heading, Button,  } from 'react-bulma-components';
import CallIcon from '@mui/icons-material/Call';
import ChatBubbleOutlineTwoToneIcon from '@mui/icons-material/ChatBubbleOutlineTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import * as React from 'react';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import * as utils from '../../../../core/utils';
import { PersonSearch } from '@mui/icons-material';



// Renders a Widget with all the relevant info for Real Estate Agent. A sample of the record is:

// const realStateAgentSample = {
//     "nombreagente": "CELSY",
//     "apellidosagente": "CARRERA ROMERO",
//     "emailagente": "celsy@cazatucasa.com",
//     "telefono1agente": 660621171,
//     "numsucursal": 5111,
// };


export default function RealEstateAgent (props) {

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])

    const [isProfileVisible, setIsProfileVisible] = React.useState(false);

    const toggleAgentProfileVisibility = () => {
        setIsProfileVisible(!isProfileVisible);
    }
    const onEmail = () => {
        // Generate html link
        const mailHref = utils.getEmailHref(props.for);
        window.open(mailHref, "_blank");
    }

    const onCall = () => {
        // window.open("tel:" + utils.getAgentPhone(props.for), "_blank");
        const inmovillaTel = "607561293";
        window.open("tel:" + inmovillaTel, "_blank");
    }

    const renderProfileAgent = () => {

        const IS_PROFILE_VISIBLE = isProfileVisible === true;
        const className = IS_PROFILE_VISIBLE ? "real-state-agent-profile-container" : "real-state-agent-profile-container is-hidden";

        const emailAgent = utils.getEmailLinkFromEmail({emailagente: 'oficina@cazatucasa.com'});

        return (
            <div className={className}>
                {
                false && <Image
                    fallback="http://bulma.io/images/placeholders/640x480.png"
                    // src={props.for.fotoagente}
                    src={'http://bulma.io/images/placeholders/640x480.png'}
                    className="result-image"
                    onClick={props.handleSetSelectedAndOpenDetail}
                    itemID={props.for.keyagente}
                />
                }

                <div className="real-state-agent-name">
                    Cazatucasa
                </div>
                <div className="real-state-agent-email">
                    {emailAgent}
                </div>
                <div className="real-state-agent-phone">
                    <a href="tel:0034928821153" rel="noreferrer">928 821 153</a> | <a href="https://wa.link/hsf8uh" target="_blank" rel="noreferrer">607 561 293</a>
                </div>
                <br/>

            </div>
        );}

    return (
            <Box className="is-shady floating-box">
                
                <Heading size={3} className="has-text-centered detail-header">
                    <PersonSearch fontSize="large" />
                    &nbsp;
                    {props.t("searchEngine.label.agent")}
                </Heading>
                <Heading subtitle size={4} className="has-text-centered action-header">{props.t("searchEngine.label.seeProfile")}</Heading>
                <Button className="is-fullwidth" color="info" onClick={toggleAgentProfileVisibility}>
                    <AccountCircleTwoToneIcon />
                </Button>
                {renderProfileAgent()}

                <Heading subtitle size={4} className="has-text-centered action-header">{props.t("searchEngine.label.callAgent")}</Heading>
                <Button className="is-fullwidth" color="info" onClick={onCall}>
                    <CallIcon />
                </Button>
                <Heading subtitle size={4} className="has-text-centered action-header">{props.t("searchEngine.label.sendMessage")}</Heading>
                <Button className="is-fullwidth" color="info" onClick={onEmail}>
                    <ChatBubbleOutlineTwoToneIcon />
                </Button>
            </Box>
    )
}
