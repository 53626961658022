// Theming
import { Box, Level } from 'react-bulma-components';
import * as React from 'react';
import Select from '@mui/material/Select';
import * as Constants from '../../../../core/constants';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import SummaryNavigator from '../summaryNavigator';
import * as utils from '../../../../core/utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
};
  
export default function SummaryHeader (props) {

    
    const handleSortOperations = (event) => {
        props.setSearchSortBy(event.target.value);
    };
    const handleSortByDirections = (event) => {
        props.setSearchSortByDirection(event.target.value);
    };
    const renderSortingOptions = () => {
        return (<>
            <p className="subtitle has-text">
                    {props.t("searchEngine.filters.sortBy.placeholder")}:
                    &nbsp; &nbsp; 
            </p>
            <Select
                    labelId="searchSort"
                    id="searchSort"
                    value={props.searchSortBy}
                    onChange={handleSortOperations}
                    MenuProps={MenuProps}
                    size={"small"}
                    renderValue = {(selected) => props.t("searchEngine.filters.sortBy.options." + utils.camelize(selected))}
                    
                >
                    {Constants.searchSort.map((searchSortElement) => (
                    <MenuItem key={searchSortElement} value={searchSortElement}>
                    {props.t("searchEngine.filters.sortBy.options." + utils.camelize(searchSortElement))}
                    </MenuItem>
                ))}
            </Select>
            &nbsp;&nbsp;
            &nbsp;&nbsp;
            <Select
                labelId="searchSortByDirection"
                id="searchSortByDirection"
                value={props.searchSortByDirection}
                onChange={handleSortByDirections}
                MenuProps={MenuProps}
                size={"small"}
                renderValue = {(selected) => selected}
                
            >
                {Constants.searchSortDirection.map((searchSortElement) => (
                <MenuItem key={searchSortElement} value={searchSortElement}>
                <ListItemText primary={searchSortElement} />
                </MenuItem>
            ))}
            </Select>
        </>);
    }
    
    return (
        <div className="search-results-summary-container">
            <Box className="summary is-shady ">
                <Level className="summary-level is-fullwidth is-mobileee">
                    <Level.Side align="left">
                        <Level.Item>
                            <SummaryNavigator {...props} />
                        </Level.Item>
                    </Level.Side>
                    <Level.Side align="right">
                        <Level.Item className=''>
                            {renderSortingOptions()}
                        </Level.Item>
                    </Level.Side>
                </Level>
            </Box>
        </div>
    )
}
