// Theming
import * as React from 'react';

export default function FeaturedReference (props) {
    return (
        <div className="featured-reference-container">
            <div className="result-info-reference" onClick={props.handleSetSelectedAndOpenDetail} itemID={props.for.id}>
                {props.for.ref}
                <span className="ellipsis-gradient"></span>
            </div>
        </div>
    )
}
