import {useRef, useEffect} from 'react';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CallIcon from '@mui/icons-material/Call';
import * as React from 'react';
import {Image, Tile, Button, Box, Heading } from 'react-bulma-components';
import FeaturesList from '../elements/featuresList';
import FeaturedImage from '../elements/featuredImage';
import PhotosMiniSlider from './photosMiniSlider';
import FeaturesButtons from '../elements/featuresButtons';
import FeaturedTitle from '../elements/featuredTitle';
import ReactTooltip from 'react-tooltip';
import RealEstateAgent from './realEstateAgent';
import * as utils from '../../../core/utils';
import FeaturedDescription from '../elements/featuredDescription';
import FeaturedReference from '../elements/featuredCode';
import FeaturedBasicInfo from '../elements/featuredBasicInfo';
import * as Constants from '../../../core/constants';
import VideoMiniPlayer from './videoMiniPlayer';
import { DirectionsRunTwoTone } from '@mui/icons-material';
import SearchResultsMap from '../results/map';
import TourVirtualPlayer from './tourVirtualPlayer';

export default function SearchDetail (props) {
    
    // Para el scroll
    const titleScrollPointer = useRef(null);
    const photosScrollPointer = useRef(null);
    const videosScrollPointer = useRef(null);
    const tourVirtualScrollPointer = useRef(null);
    const descriptionScrollPointer = useRef(null);
    const locationScrollPointer = useRef(null);

    const executeTitleScroll = () => {
        
        console.log('execute Title Scroll');
        // A la caja de Resultados
        titleScrollPointer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const executePhotosScroll = () => {
        
        console.log('execute Photos Scroll');
        // A la caja de Resultados
        photosScrollPointer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const executeVideosScroll = () => {

        console.log('execute Videos Scroll');
        // A la caja de Resultados
        videosScrollPointer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const executeDescriptionScroll = () => {

        console.log('execute Description Scroll');
        // A la caja de Resultados
        descriptionScrollPointer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const executeLocationScroll = () => {

        console.log('execute Location Scroll');
        // A la caja de Resultados
        locationScrollPointer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const scrollToSelectedSection = () => {
        setTimeout(() => {
            // Take the selected section and scroll to it
            if (props.selectedSection) {
                switch (props.selectedSection) {

                    case Constants.SEARCH_DETAIL_SECTION_TITLE:
                        executeTitleScroll();
                        break;
                    case Constants.SEARCH_DETAIL_SECTION_PHOTOS:
                        executePhotosScroll();
                        break;
                    case Constants.SEARCH_DETAIL_SECTION_VIDEOS:
                        executeVideosScroll();
                        break;
                    case Constants.SEARCH_DETAIL_SECTION_DESCRIPTION:
                        executeDescriptionScroll();
                        break;
                    case Constants.SEARCH_DETAIL_SECTION_LOCATION:
                        executeLocationScroll();
                        break;
                    default: 
                        break;
                    }
            }
    
            // console.log("2. !!!Selected Section in Search Detail: " + props.selectedSection);
            }, 100);
    }
    
    // Para los tooltips
    useEffect(() => {
        ReactTooltip.hide();
    }, [])

    useEffect (() => {
        
       // Scroll to section

       scrollToSelectedSection();
        
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected, props.selectedSection]);
    
    // Guarrería para que baje a los videos siempre que se cambie de propiedad
    useEffect (() => {
        
        // Scroll to section
         setTimeout(() => {
            
            if (props.selectedSection !== 'photos') {
                scrollToSelectedSection();
                console.log('Scroll to section only first time if selected is video');
            }
            
         }, 1000);

         // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.selectedSection]);
     
    /*const renderProps = () => {

        const propsToRender = { ...props };
        
        delete propsToRender.results;


        return <div>
            <h3>props</h3>
            <pre>
                {JSON.stringify(propsToRender, null, 2)}
            </pre>
        </div>
    }*/
    const onCall = () => {
        window.open("tel:" + utils.getAgentPhone(props.selected), "_blank");
    }
    const renderActions = () => { 
        return (
            <Box className="is-shady floating-box">
            <Heading size={3} className="has-text-centered detail-header">
                <DirectionsRunTwoTone fontSize="large" />
                &nbsp;
                {props.t("searchEngine.label.actions")}
            </Heading>
            
            <Heading subtitle size={4} className="has-text-centered action-header">{props.t("searchEngine.label.goBackToSearch")}</Heading>
            <Button className="is-fullwidth" color="info" onClick={props.handleNotSelected}>
                <SearchTwoToneIcon />
            </Button>
            <Heading subtitle size={4} className="has-text-centered action-header">{props.t("searchEngine.label.deleteFromSearch")}</Heading>
            <Button className="is-fullwidth" color="danger" itemID={props.selected.id} onClick={props.handleDeleteEntryFromSearch}>
                <DeleteTwoToneIcon />
            </Button>
            <Heading subtitle size={4} className="is-hidden has-text-centered action-header">{props.t("searchEngine.label.callAgent")}</Heading>
            <Button className="is-fullwidth is-hidden" color="info" onClick={onCall}>
                <CallIcon />
            </Button>
        </Box>  
        );
    };
    const renderLeftSide = () => {
        // console.log("Selected Section in Search Detail: " + selectedSection);

        return (<>
            <Box className="is-shady">

            {/* Title Component */}
            <FeaturedTitle className="has-text-centered" for={props.selected} refFor={titleScrollPointer} handleSetSelectedAndOpenDetail={props.handleSetSelectedAndOpenDetail} t={props.t} lang={props.lang}/>

            <div className="detail">
                <div className="detail-image">
                    <FeaturedImage
                        t={props.t}
                        for={props.selected}
                        handleSetSelectedAndOpenPhotos={props.handleSetSelectedAndOpenPhotos}
                        handleSetSelectedAndOpenDetail={props.handleSetSelectedAndOpenDetail}
                        handleSetSelectedAndOpenVideo={props.handleSetSelectedAndOpenVideo}
                    />
                </div>

                <Box className="detail-info" colorVariant={'transparent'}>

                    {/* Features List Component */}
                    <FeaturesList for={props.selected} {...props} />

                    {/* // Buttons for photos and original ad */}
                    <FeaturesButtons
                        t={props.t}
                        for={props.selected}
                        handleSetSelectedAndOpenPhotos={props.handleSetSelectedAndOpenPhotos}
                        handleSetSelectedAndOpenDescription={props.handleSetSelectedAndOpenDescription}
                        handleSetSelectedAndOpenVideo={props.handleSetSelectedAndOpenVideo}
                        handleSetSelectedAndOpenLocation={props.handleSetSelectedAndOpenLocation}
                        
                    />
                    {/* Features List Component */}

                    </Box>
                </div>
            </Box>

        </>);
    };

    const renderBottomSide = () => {
        // console.log("Selected Section in Search Detail: " + selectedSection);

        return (<>
            <Box className="detail-info0 is-shady" colorVariant={'transparent'}>
                
                {/* Reference */}
                <div className="detail-info-description">
                    <Heading subtitle size={4}>{props.t("searchEngine.label.ref")}</Heading>
                    <FeaturedReference for={props.selected} t={props.t} lang={props.lang} />
                </div> 
                
                {/* Description */}
                <div className="detail-info-description">
                    <Heading subtitle size={4}>{props.t("searchEngine.label.description")}</Heading>
                    <FeaturedDescription for={props.selected} t={props.t} lang={props.lang} />
                </div> 
                
                {/* Fotos Mini-slider */}
                <div className="detail-info-photos" >
                    <div className="anchor pointer" ref={photosScrollPointer} ></div>
                    <Heading subtitle size={4}>{utils.capitalizeFirst(props.t("searchEngine.label.photos"))}</Heading>
                    <PhotosMiniSlider for={props.selected} results={props.results} lang={props.lang} />
                    <br />
                </div> 

                {/* Video Mini-player */}
                <div className="detail-info-video" >
                    <div className="anchor pointer" ref={videosScrollPointer} ></div>
                    <Heading subtitle size={4}>{utils.capitalizeFirst(props.t("searchEngine.label.videos"))}</Heading>
                    <VideoMiniPlayer for={props.selected} results={props.results} t={props.t} />
                    <br />
                </div> 

                {/* Tour Virtual 360º */}
                <div className="detail-info-tour-virtual" >
                    <div className="anchor pointer" ref={tourVirtualScrollPointer} ></div>
                    <Heading subtitle size={4}>{utils.capitalizeFirst(props.t("searchEngine.label.tourVirtual"))}</Heading>
                    <TourVirtualPlayer for={props.selected} results={props.results} t={props.t} />
                    <br />
                </div> 

                {/* Basic Info */}
                <div className="detail-info-description">
                    <div className="anchor pointer" ref={descriptionScrollPointer} ></div>
                    <Heading subtitle size={4}>{props.t("searchEngine.label.basicFeatures")}</Heading>
                    <FeaturedBasicInfo for={props.selected} t={props.t}keysToShow={Constants.basicInfoKeys}/>
                </div> 


                {/* Location Info Info */}
                <div className="detail-info-description">
                    <Heading subtitle size={4}>{props.t("searchEngine.label.location")}</Heading>
                    <FeaturedBasicInfo for={props.selected} t={props.t}keysToShow={Constants.locationInfoKeys}/>
                </div> 

                {/* Map */}
                <div className="detail-info-description">
                    <div className="anchor pointer" ref={locationScrollPointer} ></div>
                    <Heading subtitle size={4}>{props.t("searchEngine.label.map")}</Heading>
                    <SearchResultsMap {...props} />
                </div> 

                {/* PEDIDO POR TINA */}
                {/* Equipment */}
                {/* <div className="detail-info-description">
                    <Heading subtitle size={4}>{props.t("searchEngine.label.equipment")}</Heading>
                    <FeaturedBasicInfo for={props.selected} t={props.t}keysToShow={Constants.equipmentKeys}/>
                </div>  */}

                {/* Offer Info */}
                {/* <div className="detail-info-description">
                    <Heading subtitle size={4}>{props.t("searchEngine.label.offerInfo")}</Heading>
                    <FeaturedBasicInfo for={props.selected} t={props.t}keysToShow={Constants.offerInfoKeys}/>
                </div>  */}

                {/* Enery Info */}
                {/* <div className="detail-info-description">
                    <Heading subtitle size={4}>{props.t("searchEngine.label.energeticCertification")}</Heading>
                    <FeaturedBasicInfo for={props.selected} t={props.t}keysToShow={Constants.energyInfoKeys}/>
                </div>  */}


                {/* // Buttons for photos and original ad */}
                {/* <FeaturedActionButtons for={props.selected} handleSetSelectedAndOpenDetail={props.handleSetSelectedAndOpenDetail} handleDeleteEntryFromSearch={props.handleDeleteEntryFromSearch}  handleShowElementInMap = {props.handleShowElementInMap}  /> */}

            </Box>
        </>);
    };
    
return (<Box className='detail-box is-shady is-full-width' size={12}>
        
    <Tile kind="ancestor" size={12}>
        <Tile size={8} vertical>
            <Tile kind="parent" vertical>
                <Tile renderAs="article" kind="child" color="primary">
                    {/* LEFT SIDE */}
                    {/* Selected Section: {props.selectedSection} */}
                    {renderLeftSide()}
                    <Image src={'img/detail.png'} size={12}  className="is-hidden" style={{height: "50px"}}/>

                </Tile>
            </Tile>
        </Tile>
        <Tile kind="parent" className='is-hidden-mobile' size={4}>
            <Tile renderAs="article" kind="child" color="primary">
                {/* Right SIDE */}
                {true && renderActions()}
                <RealEstateAgent {...props} for={props.selected} />
            </Tile>
        </Tile>
    </Tile>
    {renderBottomSide()}


    <div className="is-hidden-desktop is-hidden-tablet">
        {renderActions()}
        <RealEstateAgent {...props} for={props.selected} />
    </div>
</Box>);


}
