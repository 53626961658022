import * as React from 'react';
import {Level} from 'react-bulma-components';
import { RoomTwoTone, KingBedTwoTone, EuroTwoTone, AspectRatioTwoTone } from '@mui/icons-material';
export default function FeaturesList (props) {

    const price = props.for.precio_calculado;
    const SHOW_PRICE = props.showZeros === false ? Number(price.replaceAll(",","")) > 0 : true;
    const SHOW_ROOMS = props.showZeros === false ? props.for.total_hab > 0 : true;
    const SHOW_M2 = props.showZeros === false ? props.for.m_cons > 0 : true;
    // debugger;
    return (<div className="features-list-container">
            <Level className="result-info" alignItems='left' alignContent='left' align='left' >
                {SHOW_PRICE ?<Level.Item>
                    <div className="result-info-icon">
                        <EuroTwoTone />
                    </div>
                    <div className="result-info-text">
                        <span>{price}</span>
                    </div>
                </Level.Item> : null}
                {SHOW_ROOMS ?<Level.Item>
                    <div className="result-info-icon">
                        <KingBedTwoTone />
                    </div>
                    <div className="result-info-text">
                        <span><b>{props.for.total_hab}</b> {props.t("searchEngine.label.roomTextCompressed")}</span>
                    </div>
                </Level.Item> : null}
                {SHOW_M2 ?<Level.Item>
                    <div className="result-info-icon">
                        <AspectRatioTwoTone />
                    </div>
                    <div className="result-info-text">
                        <span><b>{props.for.m_cons}</b> m²</span>
                    </div>
                </Level.Item> : null}
                {!props.isCard && <Level.Item className="map-level" onClick={props.handleShowElementInMap} itemID={props.for.id}>
                    <div className="result-info-icon">
                        <RoomTwoTone />
                    </div>
                    <div className="result-info-text">
                        <span>{props.t("searchEngine.label.verEnElMapa")}</span>
                    </div>
                </Level.Item>}
            </Level>
        </div>
    )
}
