// Theming
import { Tile, Tag, Box, Level } from 'react-bulma-components';
import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SummaryNavigator from '../summaryNavigator';

export default function SummaryHeader (props) {
    const { handleNotSelected } = props;

    const renderGoBackToSearch = () => {
        return <Tile className="one-filter go-back-search">
        <Tag.Group hasAddons onClick={handleNotSelected}>
        <Tag color="transparent" size={'large'}>
        
            {/* Go Back Icon */}
            <ArrowBackIcon color='light'/>
        
        </Tag>
        <Tag color="transparent" size={'large'}>
            {props.t("searchEngine.label.goBackToSearch")}
        </Tag>
    </Tag.Group>

    </Tile>
    }

    const renderSortingOptions = () => {
        return (<>
            <p className="subtitle has-text is-hidden">
                    {props.t("searchEngine.label.ordenarPor")}:
                    &nbsp; &nbsp; 
            </p>
            {renderGoBackToSearch()}
        </>);
    }
    
    const className = props.isMapFullScreen ? 'search-detail-summary-container is-map-full-screen' : 'search-detail-summary-container';
    return (
        <div className={className}>
            <Box className="summary is-shady ">
                <Level className="summary-level filter-tiles is-full-width is-mobileee">
                    <Level.Side align="left">
                        <Level.Item>
                            {renderSortingOptions()}
                        </Level.Item>
                    </Level.Side>
                    <Level.Side align="right">
                        <Level.Item className=''>
                        <SummaryNavigator {...props} />
                        </Level.Item>
                    </Level.Side>
                </Level>
            </Box>
        </div>
    )
}
