// Theming
import * as React from 'react';
import * as utils from '../../../../core/utils';
import {Level} from 'react-bulma-components';
export default function FeaturedBasicInfo (props) {
    
    // Get all the elements for the property
    const elementsFromProperty = utils.getElementsForProperty(props.for, props.keysToShow, props.t);

    // Filter the elements to show only the ones that have a value
    const elements = utils.removeElementsForPropertyIfEmptyOrZero(elementsFromProperty);
    // debugger;
    return (
        <div className="featured-basic-info-container">
            <Level className="result-info" alignItems='left' alignContent='left' align='left'>
            
                {elements.map((element, index) => {


                    // Get the value of the element in function of the key
                    let elementValue = element.value;
                   //  console.log(element.key);
                    switch (element.key) {
                        case 'nbtipo':
                            elementValue = props.t('searchEngine.filters.types.options.' +element.value.toString().toLowerCase());
                            break;
                            case 'nbconservacion':
                                elementValue = props.t('searchEngine.features.nbconservacion_options.' +element.value.toString());
                                break;
                            case 'tipomensual':
                                elementValue = props.t('searchEngine.features.tipomensual_options.' +element.value.toString());
                                break;   
                            default:
                                break;             
                    }           


                    return (
                        <Level.Item key={index} className={element.key}>
                            <div className="result-info-icon">
                                {element.icon}
                            </div>
                            <div className="result-info-label">
                                <span className={element.key}>{props.t('searchEngine.features.' + element.key)}: </span>
                            </div>
                            <div className="result-info-value">
                                <span>{elementValue}</span>
                            </div>
                        </Level.Item>
                    )})
                }
            </Level>
        </div>
    )
}
