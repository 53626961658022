import { Content } from 'react-bulma-components';
import * as React from 'react';
import {useState} from 'react';

/** MUI ONE */
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import SearchIcon from '@mui/icons-material/Search';
import FsLightbox from 'fslightbox-react';



export default function PhotosMiniSlider (props) {
    

    // if toggler is updated when lightbox is closed it will open it
    // if toggler is updated when lightbox is opened it will close it
    const [lightboxController, setLightboxController] = useState({
            toggler: false,
            slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
           toggler: !lightboxController.toggler,
            slide: number
        });
    }
    const renderNoPhotos = () => {
        return (
            <Content>
                <p>No photos available</p>
            </Content>
        );
    }

    const renderSampleMansory = () => {

        // Get photos array from props
        const HAS_PHOTOS = props.for.fotos && props.for.fotos[props.for.cod_ofer] && props.for.fotos[props.for.cod_ofer].length > 0;
        const photosArray = HAS_PHOTOS ? props.for.fotos_etiquetas[props.for.cod_ofer] : [];
        // debugger;
        // Convert photos Array to MUI ImageList
        const photosMUI = photosArray.map((photo) => {
        
            return {
                img: photo.foto,
                title: photo.etiqueta,
            }
        });

        return (
            <Content className="photo-masonry" sx={{ width: 500, height: 450, overflowY: 'scroll' }}>
                <ImageList variant="masonry" cols={3} gap={8}>
                    {photosMUI.map((item, index) => (
                    <ImageListItem key={item.img} onClick={() => openLightboxOnSlide(index+1)}>
                        <img
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        />
                        <span className='photo-masonry__overlay'>
                            <SearchIcon />
                        </span>
                    </ImageListItem>
                    ))}
                </ImageList>
            </Content>
        );
    }
    const renderCaption = (photo, description) => {
        const HAS_DESCRIPTION = description && description.length > 0;
        return (<>
            {HAS_DESCRIPTION ? <div className="lightbox__caption">
                <h3 className="">&nbsp;</h3>
                <p>{description}</p>
            </div> : null}
        </>
        )
    }

    const renderFullScreenSlideShow = () => {
        
        // Get photos array from props
        const HAS_PHOTOS = props.for.fotos && props.for.fotos[props.for.cod_ofer] && props.for.fotos[props.for.cod_ofer].length > 0;
        const photosEtiquetasArray = HAS_PHOTOS ? props.for.fotos_etiquetas[props.for.cod_ofer] : [];
        const photosArray = photosEtiquetasArray.map(photo => photo.foto);
        const photosCustomAttributesArray = photosEtiquetasArray.map(photo => ({alt: photo.etiqueta, title: photo.etiqueta}));
        const photosCaptionsArray = photosEtiquetasArray.map(photo => (renderCaption(photo, photo.etiqueta)));

        // // The first in the index is the spanish description. The second the english one. Select the one that matches the language
        // const descriptionsArrayLanguages = HAS_DESCRIPTIONS ? props.for.descripciones[props.for.cod_ofer]:[];
        // const descriptionsArray = descriptionsArrayLanguages.map(description => description[props.lang === 'es' ? 1 : 2]);
        // const HAS_DESCRIPTIONS = props.for.descripciones && props.for.descripciones[props.for.cod_ofer] && props.for.descripciones[props.for.cod_ofer] !== null;

        
        return (
        <>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={photosArray}
                customAttributes={photosCustomAttributesArray}
                type="image"
                slide={lightboxController.slide}
                captions={photosCaptionsArray}
                initialAnimation="scale-in-long"
                slideChangeAnimation="scale-in" 
                
            />
        </>
        );
    }
           const HAS_PHOTOS = props.for.fotos && props.for.fotos[props.for.cod_ofer] && props.for.fotos[props.for.cod_ofer].length > 0;

    return (

        <div className="photos-mini-slider-container">
            { HAS_PHOTOS && renderSampleMansory() }
            { HAS_PHOTOS && renderFullScreenSlideShow() }
            { !HAS_PHOTOS && renderNoPhotos() }
        </div>
    )
}
