// Theming
import {Image, Box} from 'react-bulma-components';
import * as React from 'react';
import SearchResultElement from '../../elements/searchResultElement';

export default function SearchResultsList (props) {

// debugger;
    // Render
    return (<Box className="results is-shadiest">
        <Image src={'img/ejemplo.png'} size={12}  className="is-hidden" />
        
        {props.results.map((result, index) => {
            return (
                <SearchResultElement key={index} result={result} {...props} />
            )
        })}
    </Box>)
}

