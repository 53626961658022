// Theming
import { Image, Button, Level } from 'react-bulma-components';
import * as React from 'react';
import {useEffect } from 'react';
import { SlideshowTwoTone, CollectionsTwoTone, ArrowLeftTwoTone, ArrowRightTwoTone } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';
export default function FeaturedImage (props) {

    // Keep photos array in state 
    const [photos, setPhotos] = React.useState([]);
    const [currentPhoto, setCurrentPhoto] = React.useState(0);
    
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])

    // Get photos from props
    useEffect(() => {
        // debugger;
        setPhotos(props.for.fotos_etiquetas);
    }, [props.for.fotos_etiquetas])
    
    const handlePreviousImage = () => {
        if (currentPhoto > 0) {
            setCurrentPhoto(currentPhoto - 1);
        } else {
            setCurrentPhoto(photos[props.for.cod_ofer].length - 1);
        }
    }
    const handleNextImage = () => {
        if (currentPhoto < photos[props.for.cod_ofer].length - 1) {
            setCurrentPhoto(currentPhoto + 1);
        } else {
            setCurrentPhoto(0);
        }
    }
    const renderStaticImage = () => {

        // If there are photos, get the first photo
        const HAS_PHOTOS = photos  && photos[props.for.cod_ofer] && photos[props.for.cod_ofer].length > 0;
        const photosEtiquetasArray = HAS_PHOTOS ? props.for.fotos_etiquetas[props.for.cod_ofer] : [];
        const photosArray = HAS_PHOTOS ? photosEtiquetasArray.map((photo) => {
            return photo.foto;
        }) : [];

        const photo = HAS_PHOTOS ? photosArray[currentPhoto] : props.for.foto;
        const id = HAS_PHOTOS ? photosEtiquetasArray[currentPhoto].id : props.for.id;
        
        return (
            <Image
                fallback="http://bulma.io/images/placeholders/640x480.png"
                src={photo}
                className="result-image"
                onClick={props.handleSetSelectedAndOpenPhotos}
                itemID={id}
                height="100%"
                width="100%"
                fit="cover"
                loading="lazy"
                easing="ease-in"
                duration={1000}

            />            
    );
    }
    const renderleftArrow = () => {
        return (
            <Button
                className="left is-transparent"
                
                itemID={props.for.id} data-cod-ofer={props.for.cod_ofer}
            >
                <ArrowLeftTwoTone  onClick={handlePreviousImage}/>
            </Button>
        );
    }
    const renderRightArrow = () => {
        return (
            <Button
                className="right is-transparent"
                
                itemID={props.for.id} data-cod-ofer={props.for.cod_ofer}
            >
                <ArrowRightTwoTone onClick={handleNextImage} />
            </Button>
        );
    }
    // debugger;
    const HAS_VIDEO = props.for.videos && props.for.videos[props.for.cod_ofer] && props.for.videos[props.for.cod_ofer].length > 0;
    const HAS_TOUR_VIRTUAL = props.for.tour_virtual === 1;
    // Get photos array from props
    const HAS_PHOTOS = typeof props.for.fotos !== 'undefined' && props.for.fotos[props.for.cod_ofer] && props.for.fotos[props.for.cod_ofer].length > 0;

    return (
        <div className="featured-image-container">

            {HAS_PHOTOS && renderleftArrow()}
            {renderStaticImage()}
            {HAS_PHOTOS && renderRightArrow()}

            <Level className="featured-image-level is-mobile">
                <Level.Side align="left">
                    <Level.Item>                    
                        {(true || HAS_VIDEO) && 
                        <Button itemID={props.for.id} data-cod-ofer={props.for.cod_ofer} className="is-outlined video-button cazatucasa-tooltip" color="light" size="large" onClick={props.handleSetSelectedAndOpenVideo} data-tipppp={props.t("searchEngine.label.seeVideo")} data-place="top" data-html="true"  data-effect="solid" data-type="info">
                            
                            <SlideshowTwoTone className= {'button-icon'} />
                            <span className='how-many'>{props.for.tour_virtual}</span>
                            <span className="text-button is-hidden"> {props.for.tour_virtual === 1 ? 'video': props.t("searchEngine.label.videos")}</span>
                        </Button>}
                    </Level.Item>
                </Level.Side>
                <Level.Side align="right">
                    <Level.Item>
                        <Button itemID={props.for.id} data-cod-ofer={props.for.cod_ofer} className="is-outlined pictures-button" color="light" size="" onClick={props.handleSetSelectedAndOpenPhotos} data-tippppp={props.t("searchEngine.label.seePhotos")} data-place="top" data-html="true"  data-effect="solid" data-type="info">
                            <CollectionsTwoTone className= {'button-icon'} />
                            <span className='how-many'>{props.for.numfotos} </span>
                            <span className="text-button is-hidden">{props.t("searchEngine.label.photos")}</span>
                        </Button>
                    </Level.Item>
                </Level.Side>
            </Level>
        </div>
    )
}
