import { LaunchTwoTone } from '@mui/icons-material';
import * as React from 'react';
import * as utils from '../../../../core/utils';


export default function TourVirtualPlayer (props) {

    const HAS_TOUR_VIRTUAL = props.for.ficha[1].tipotour=== 1 || props.for.tour_virtual === 1;
    const tourVirtualLink = HAS_TOUR_VIRTUAL ? utils.getLinkForTourVirtualFor(props.for) : [];
    const className = 'tour-virtual-player-container ' + (HAS_TOUR_VIRTUAL ? 'has-tour-virtual' : 'no-tour-virtual');
    // debugger;
    return (
        <div className={className}>
            {HAS_TOUR_VIRTUAL ? (
                // Link to tour virtual
                <a className="has-color-link" href={tourVirtualLink} target="_blank" rel="noreferrer">
                    {props.t("searchEngine.label.seeTourVirtual")}
                    
                    <LaunchTwoTone className= {'link-icon'} />

                </a>

            ) : (
                <div className="tour-virtual-player">
                    <div className="tour-virtual-player-placeholder">
                        <div className="tour-virtual-player-placeholder-text result-info-description">
                            <span>{props.t("searchEngine.label.noTourVirtual")}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
