// Theming
import * as React from 'react';
import {Tile, Content } from 'react-bulma-components';
import Skeleton from '@mui/material/Skeleton';

  
export default function SkeletonElement (props) {

    return (
        <Tile className={props.isCard ? 'is-card skeleton-element': 'skeleton-element'}>
            <Tile kind="parent" vertical size={props.isCard ? 12 : 3 }>
                <Skeleton variant="rectangular" className={'skeleton-results-img'} />
            </Tile>
            
            <Tile kind="parent" className="result-summary" vertical size={props.isCard ? 12 : 9 }>
                <Tile kind="child" renderAs={Content} color="transparent">
                    
                    <Skeleton variant="rectangular" height={20} />
                    <br />
                {/* Description Component */}
                    <Skeleton variant="rectangular" height={'80%'} />
                </Tile>
            </Tile>
    </Tile>
)
}
