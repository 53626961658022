// Theming
import {Button } from 'react-bulma-components';
import * as React from 'react';
import ChatBubbleOutlineTwoToneIcon from '@mui/icons-material/ChatBubbleOutlineTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CallIcon from '@mui/icons-material/Call';
import * as utils from '../../../../core/utils';
import ReactDOMServer from 'react-dom/server';

export default function FeaturedActionButtons (props) {

    // const emailLinkAsHtml = utils.getEmailLinkFromEmail(props.for);
    const mailData = props.for;
    mailData.emailagente = 'oficina@cazatucasa.com';
    const emailLinkAsHtml = utils.getEmailLinkFromEmail(mailData);
    const agentPhoneAsHtml = utils.getCazatucasaPhones();


    // Convert react element to string
    const emailLink = ReactDOMServer.renderToStaticMarkup(emailLinkAsHtml);
    const agentPhone = ReactDOMServer.renderToStaticMarkup(agentPhoneAsHtml);
    return (
        <div className="featured-action-buttons">
            <Button
                color="dark"
                onClick={props.cleanFiltersAndSearch}
                className="is-centered is-link is-inverted is-phone-button"
                itemID={props.for.id}
                size="medium"
            
                data-tip={agentPhone}
                data-border="false"
                data-effect="solid"
                data-place="top"
                data-html="true"
                // data-delay-show={1000}
                data-event="click"
                data-class="is-tooltip-for-phone action-button-tooltip"
                data-clickable="true"
                // data-event-off="mouseout"

            >
                
                <CallIcon />
                <span>&nbsp; {props.t("searchEngine.label.seePhone")}</span>
            </Button>
            <Button
                color="dark"
                onClick={props.cleanFiltersAndSearch}
                className="is-centered is-link is-inverted is-contact-button"
                itemID={props.for.id}
                size="medium"
                
                data-tip={emailLink}
                data-border="false"
                data-effect="solid"
                data-offset="{'top': 0, 'left': 0}"
                data-place="top"
                data-html="true"
                // data-delay-show={1000}
                data-event="click"
                data-class="is-tooltip-for-contact action-button-tooltip"
                data-clickable="true"
                // data-event-off="mouseout"

                >
                
                
                <ChatBubbleOutlineTwoToneIcon />
                <span>&nbsp; {props.t("searchEngine.label.contact")}</span>
            </Button>
            <Button
                color="dark"
                onClick={props.handleDeleteEntryFromSearch}
                className="is-centered is-link is-inverted is-delete-button"
                itemID={props.for.id}
                size="medium">
                
                
                <DeleteTwoToneIcon /> 
                <span>&nbsp; {props.t("searchEngine.label.delete")}</span>
            </Button>
        </div>
    )
}
