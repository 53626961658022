// Theming
import { Button } from 'react-bulma-components';
import * as React from 'react';
import { FirstPageTwoTone, LastPageTwoTone, ChevronLeftTwoTone, ChevronRightTwoTone, RoofingTwoTone } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import ReactTooltip from 'react-tooltip';

export default function SummaryNavigator (props) {

    // Rebuild tooltips
    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    const renderPageResultsTextSelected = () => {

        let start = Number ((props.currentPage-1) * (props.results.length - 1)) + 1;
        let end = Number (start + props.results.length - 1);
        if (end > (props.paginationInfo.total-1)) {
            end = (props.paginationInfo.total);
        }
        // Get position in the list for the selected element
        let selectedElement = props.selected ? props.selected : props.results[0];
        let positionInArray = props.results.indexOf (selectedElement);
        // debugger;

        return (
            <div className="page-results-text">
                <RoofingTwoTone />
                <span className="subtitle has-text">
                    {start + positionInArray} - {end}{props.t("searchEngine.label.of")}<b>{props.paginationInfo.total}</b> {props.t("searchEngine.label.resultados")}
                </span>
            </div>
        );
    };

    // Get position in the list for the selected element
    // debugger;
    let selectedElement = props.selected ? props.selected : props.results[0];

    // Get position in the array for the selected element
    let positionInArray = props.results.indexOf (selectedElement);
                    
    let isFirstDisabled = positionInArray === 0;
    let isLastDisabled = positionInArray === props.paginationInfo.total-1;
    let isPreviousDisabled = positionInArray === 0;
    let isNextDisabled = positionInArray === props.paginationInfo.total-1;

    // let isPreviousDisabled = props.selected.id === 1;
    // let isNextDisabled = props.paginationInfo.total < props.selected.id;
    return (
            <div className="page-results-buttons">
                <div className="mobile-page-results-page">
                {renderPageResultsTextSelected()}
                </div>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button
                        className="page-results-button"
                        onClick={props.handleFirstSelected}
                        disabled={isFirstDisabled}
                    >
                        <FirstPageTwoTone />
                    </Button>
                    <Button
                        className="page-results-button"
                        onClick={props.handlePreviousSelected}
                        disabled={isPreviousDisabled}
                    >
                        <ChevronLeftTwoTone />
                    </Button>
                    {renderPageResultsTextSelected()}
                    <Button
                        className="page-results-button"
                        onClick={props.handleNextSelected}
                        disabled={isNextDisabled}
                    >
                        <ChevronRightTwoTone />
                    </Button>
                    <Button

                        className="page-results-button"
                        onClick={props.handleLastSelected}
                        disabled={isLastDisabled}
                    >
                        <LastPageTwoTone />
                    </Button>
                </ButtonGroup>
            </div>
    )
}
