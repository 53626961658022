// Theming
import * as React from 'react';
import {Heading } from 'react-bulma-components';
import * as utils from '../../../../core/utils';
export default function FeaturedTitle (props) {


    // We'll get title and description from the description object
    const descriptionObject = utils.getDescriptionObjectForProperty(props.for, props.t, props.lang);
    const title = descriptionObject ? descriptionObject.title : null;
    
    return (
        <div className="featured-title-container" ref={props.refFor}>
            <Heading className={props.className} onClick={props.handleSetSelectedAndOpenDetail} itemID={props.for.id} data-cod-ofer={props.for.cod_ofer}>
                {title}
            </Heading>
            <Heading subtitle className='is-hidden'>With image Tile...</Heading>
        </div>
    )
}
