// Theming
import {Content, Tile, Box} from 'react-bulma-components';
import * as React from 'react';
import FeaturesList from '../featuresList';
import FeaturedImage from '../featuredImage';
import FeaturedTitle from '../featuredTitle';
import FeaturedDescription from '../featuredDescription';
import FeaturedActionButtons from '../featuredActionButtons';

export default function SearchResultElement (props) {

    // Render
    return (<Box className="result is-shadyy" key={props.index}>
        <Tile kind="ancestor">
            <Tile size={12} vertical>
                <Tile className={props.isCard ? 'is-card': ''}>
                <Tile kind="parent" vertical size={props.isCard ? 12 : 3 }>
                    <FeaturedImage
                        t={props.t}
                        for={props.result}
                        handleSetSelectedAndOpenPhotos={props.handleSetSelectedAndOpenPhotos}
                        handleSetSelectedAndOpenDetail={props.handleSetSelectedAndOpenDetail}
                        handleSetSelectedAndOpenVideo={props.handleSetSelectedAndOpenVideo}
                    />

                </Tile>
                
                <Tile kind="parent" className="result-summary" vertical size={props.isCard ? 12 : 9 }>
                    <Tile kind="child" renderAs={Content} color="transparent">
                        
                        {/* Title Component */}
                        <FeaturedTitle for={props.result} handleSetSelectedAndOpenDetail={props.handleSetSelectedAndOpenDetail} t={props.t} lang={props.lang}/>

                        {/* Features List Component */}
                        <FeaturesList for={props.result} isCard={props.isCard} {...props} />

                        {/* Description Component */}
                        <FeaturedDescription for={props.result} handleSetSelectedAndOpenDetail={props.handleSetSelectedAndOpenDetail} t={props.t} lang={props.lang} />
                    </Tile>

                    <Tile kind="parent" className='result-actions is-hiddenn'>
                        <Tile kind="child" renderAs={Content} color="danger" sizee={12}>

                        <FeaturedActionButtons for={props.result} handleSetSelectedAndOpenDetail={props.handleSetSelectedAndOpenDetail} handleDeleteEntryFromSearch={props.handleDeleteEntryFromSearch} handleShowElementInMap = {props.handleShowElementInMap} isCard={props.isCard} t={props.t} />
                        </Tile>
                    </Tile>
                </Tile>

                </Tile>
            </Tile>
        </Tile>
    </Box>
    )
}

