import {useEffect, useRef} from 'react';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ReactTooltip from 'react-tooltip';
import {Button, Box, Tile, Form } from 'react-bulma-components';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import * as Constants from '../../core/constants';
import * as utils from '../../core/utils';
import RoomTwoToneIcon from '@mui/icons-material/RoomTwoTone';
import SearchDetail from './detail';
import RoofingTwoToneIcon from '@mui/icons-material/RoofingTwoTone';
import FiltersSummary from './search/filtersSummary';
import SummaryHeader from './results/summaryHeader';
import SearchResultsDetailHeader from './detail/summaryHeader';
import DetailSummaryFooter from './detail/summaryFooter';
import ResultsSummaryFooter from './results/summaryFooter';
import SearchResultsList from './results/list';
import SearchResultsMap from './results/map';
import { Trans } from 'react-i18next';
import SearchResultSkeletonElement from './results/skeletonElement';
import SearchResultSkeletonHeaderOrFooter from './results/skeletonHeaderOrFooter';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SearchEngine (props) {

    const [openOperations, setOpenOperations] = React.useState(false);
    const [openTypes, setOpenTypes] = React.useState(false);
    const [openRoomsNumbers, setOpenRoomsNumbers] = React.useState(false);
    const [openPlaces, setOpenPlaces] = React.useState(false);
    const { results, selected, isSearching, setIsSearching, setResults } = props;
    
    const ARE_FILTERS_EMPTY = props.areFiltersEmtpy();
    const HAS_RESULTS = results && results.length > 0;
    const NO_RESULTS = results && results.length === 0;
    const HAS_SELECTED = selected && selected.id >= 0;
    const IS_SEARCHING = isSearching;
    const IS_LOCALHOST = window.location.href.includes('localhost');
    const extraPaddingClass = IS_LOCALHOST ? ' extra-padding' : ''; 
    const IS_SEARCH_SIMPLE_TAB = props.selectedTab === 'search-simple';
    const IS_SEARCH_MAP_TAB = props.selectedTab === 'search-map';

    // run this function from an event handler or an effect to execute scroll 

    // Para los tooltips
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])

    // Efects -> Refresh Search Results
    useEffect (() => {
    
        setIsSearching(props.isSearching);
        setResults(props.results);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSearching, props.results]);

    // Effect -> Show Details
    // useEffect (() => {
    //     if (props.selected && props.open === false) {
    //         console.log('props.selected', props.selected);
    //     }
    // }, [props.selected, props.open]);
    
    // Para el scroll
    const scrollPointer = useRef(null);

    // Enable Filter fixes & Triple Click (run once)
    useEffect (() => {
        // console.log('Enable Filter fixes & Triple Click (run once)');
        // console.log(props);
        document.addEventListener('mouseoutttt', e => {
            const button = e.target.closest('.MuiPaper-root');
            if (button) {
                
                const elementClassName = e.toElement.constructor.name;
                if (elementClassName === 'HTMLDivElement') {
                    // console.log('SearchEngine: MouseOut');

                    // In function of the origin of the mouse out, we will close the select
                    const originList = e.target.closest('.MuiList-root');
                    if (originList) {
                        const ariaLabel = originList.getAttribute('aria-labelledBy');
                        switch (ariaLabel) {
                            case 'operations':
                                handleCloseOperations();
                                break;
                            
                            case 'types':
                                handleCloseTypes();
                                break;
                                
                            case 'roomsNumbers':
                                handleCloseRoomsNumbers();
                                break;
                            
                            case 'places':
                                handleClosedPlaces();
                                break;

                            case 'language':
                                break;
                                    
                            default:
                                console.log('ariaLabel not found: %o', ariaLabel);
                                break;
    
    
                        }                    }
                }
            }
        });

          // Enable Triple Click Search
          const searchBoxHeader = document.querySelector('.header-search-box-section');
          if (searchBoxHeader) {

            searchBoxHeader.addEventListener('click', e => {
              
                // check number of clicks from event
                const clickCount = e.detail;
    
                if (clickCount === 3) {
                    // console.log('Triple click');
                    props.initializeSomeFilters();
                }
              });
  

            /*searchBoxHeader.addEventListener('click', e => {
                const searchBox = e.target.closest('.header-search-box');
                if (searchBox) {
                    const searchBoxInput = searchBox.querySelector('.header-search-box-input');
                    if (searchBoxInput) {
                        searchBoxInput.focus();
                    }
                }
            })*/
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Scroll to results if search is done
    useEffect (() => {
        // console.log('SearchEngine: useEffect: props');
        // console.log(props);

        // Scroll to results if search is done
        if (props.isSearching===false && props.results && props.results.length > 0 && props.selected === null) {

            // console.log('SearchEngine: useEffect: props.isSearching');
            // console.log(props.isSearching);

            // Scroll to results
            executeScroll();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSearching, props.results, props.searchSortBy, props.searchSortByDirection, props.selectedTab, props.selected, props.selectedSection])
    
    // Triggers

    const executeScroll = () => {
        
        // console.log('executeScroll');
        // A la caja de Resultados
        scrollPointer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // Handlers for selectors   
    const handleCloseOperations = () => {
        setOpenOperations(false);
    };
    const handleOpenOperations = () => {
        setOpenOperations(true);
    };
    const handleCloseTypes = () => {
        setOpenTypes(false);
    };
    const handleOpenTypes = () => {
        setOpenTypes(true);
    };
    const handleCloseRoomsNumbers = () => {
        setOpenRoomsNumbers(false);
    };
    const handleOpenRoomsNumbers = () => {
        setOpenRoomsNumbers(true);
    };
    const handleClosedPlaces = () => {
        setOpenPlaces(false);
    };
    const handleOpenPlaces = () => {
        setOpenPlaces(true);
    };   
    const handleSearchBoxChange = (event) => {
        props.setTextSearch(event.target.value);
    }
    const handleChangeResultsPerPage = (event) => {
        const {
            target: { value },
        } = event;

        props.setCurrentPage(1);
        props.setResultsPerPageInCookie(value);
    };
    // Partial renders
    const renderHeaderSearchBoxSection = () => {
    
        return <div className="header-search-box-section">
            <LocationSearchingIcon />
            <Trans i18nKey="searchEngine.label.searchBoxSlogan" >
                Estás a un click de encontrar <b> la casa de tus sueños</b>
            </Trans>
        </div>
    }
    function handleOnInput (event) {

        // If enter, we will search
        
        if (event.key === 'Enter') {
            props.handleSearchButtonClick()
        }
    }

    const renderSearchSection = () => {
        return <div className="search-box">
            <Form.Control point="search-box" onSubmit={handleSearchBoxChange}>
            <Form.Input type="text" value={props.textSearch} onChange={handleSearchBoxChange} onKeyPress={handleOnInput} className="search-input" placeholder={props.t ('searchEngine.label.searchBoxPlaceholder')} />
            <button className="search-button is-transparent" onClick={props.handleSearchButtonClick}>
                <SearchTwoToneIcon />
            </button>
            </Form.Control>
        </div>
    }
    const renderSearchButton = () => {

        const IS_DISABLED = props.isSearchTextEmpty() && props.areFiltersEmtpy();

        return <Button
            variant={ IS_DISABLED ? "disabled" :"outlined" }
            color="error"
            className="search-button"
            onClick={props.handleSearchButtonClick}
            disabled={IS_DISABLED}
            data-tipp={'Lista de Resultados'}
            data-border="false"
            data-size="medium"
            data-effect="solid"
            data-offset="{'top': 40, 'left': 0}"
            data-place="bottom"
            data-html="true"
            data-delay-show={1000}
        >
            {props.t ('searchEngine.label.searchBoxButton')}
        </Button>
    }
    const renderMapSearchButton = () => {

        const IS_DISABLED = props.isSearchTextEmpty() && props.areFiltersEmtpy();

        return <Button
            variant={ IS_DISABLED ? "disabled" :"outlined" }
            color="error"
            className="map-search-button is-1"
            onClick={props.handleSearchMapButtonClick}
            disabled={IS_DISABLED}
            data-tipp={'props.t("searchEngine.label.verEnElMapa")'}
            data-border="false"
            data-size="medium"
            data-effect="solid"
            data-offset="{'top': -4, 'left': 0}"
            data-place="bottom"
            data-html="true"
            data-delay-show={1000}
        >
            <RoomTwoToneIcon />
        </Button>
    }
    const renderSlider = () => {


        const value = props.rangePrice;
        // debugger;
        const initialValue = props.rangePrice[0];
        const finalValue = props.rangePrice[1];
        let initialValueConverted = utils.convertToKFormat(initialValue);
        if (initialValue === Constants.rangePrice.min) {
            initialValueConverted = '0';
        }
        let finalValueConverted = utils.convertToKFormat(finalValue);
        if (finalValue === Constants.rangePrice.max + 1) {
            finalValueConverted = props.t ('searchEngine.label.noLimit');
        }
        const valueFormattedAsString = initialValueConverted + ' - ' + finalValueConverted;
        // ;
        // debugger;
        return <div className="slider-container">
            <div className="slider-label">
                <span className="slider-label-text is-centered">
                    {props.t ('searchEngine.label.priceRangeLabel')}
                </span>
            </div>
            
            <Slider
                value={value}
                getAriaValueText={(value)=> "€ " + value}
                valueLabelDisplay={ finalValue === Constants.rangePrice.max + 1 ? "off" : "auto"}
                step={10000}
                marks={false}
                min={0}
                max={Constants.rangePrice.max + 1}
                size="small"
                color="primary"
                onChange={props.handleChangeRangePrices}
            />
            <div className="slider-label">
                <span className="slider-label-value is-centered">{valueFormattedAsString}</span>
            </div>
        
        </div>
    }
    const renderCleanFiltersLink = () => {
        
        const isHiddenClass = ARE_FILTERS_EMPTY ? 'is-hidden' : '';
        return <div className={"clean-filters-link " + isHiddenClass}>
            <button className="is-transparent has-color-link" onClick={props.cleanFilters}><DeleteTwoToneIcon /> 
                {props.t ('searchEngine.label.cleanFiltersLink')}
            </button>
        </div>
    }
    const renderFilterSection = () => {
        const operationsText = props.t ('searchEngine.filters.operations.placeholder');
        const locationsText = props.t ('searchEngine.filters.locations.placeholder');
        const typesText = props.t ('searchEngine.filters.types.placeholder');
        const roomsNumbersText = props.t ('searchEngine.filters.roomsNumber.placeholder');

        return <Tile kind="ancestor" className="filter-tiles">
            <Tile size={12} vertical>
                <Tile>
                    {/* FILTRO 1: ¿Qué Buscas? */}
                    <Tile kind="parent" size={2.4} vertical className="tile-vertical-aligned-top">

                        <Tile>
                        <FormControl fullWidth>
                            <Select
                                labelId="operations"
                                id="operations"
                                displayEmpty
                                multiple
                                value={props.operationsArray}
                                onChange={props.handleChangeOperations}
                                input={<OutlinedInput label= {operationsText} />}
                                renderValue={(selected) => {return operationsText}}
                                MenuProps={MenuProps}
                                size={"small"}
                                open={openOperations}
                                onClose={handleCloseOperations}
                                onOpen={handleOpenOperations}
                            >
                                {Constants.operations.map((operation) => (
                                <MenuItem key={operation} value={operation}>
                                <Checkbox checked={props.operationsArray.indexOf(operation) > -1} />
                                <ListItemText primary={props.t("searchEngine.filters.operations.options." + utils.camelize(operation))} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        </Tile>
                        
                        <Tile className="tile-absolute">
                        <ul className="filter-results">
                            {utils.renderArrayAsList(props.t, 'operations',props.operationsArray, true)}
                        </ul>         
                        </Tile>
                    </Tile>
                                                            
                    {/* FILTRO 2: Ubicación */}
                    <Tile kind="parent" size={2.4} vertical className="tile-vertical-aligned-top">
                        <Tile>
                        <FormControl fullWidth >
                        {/* <InputLabel id="demo-multiple-checkbox-label">{props.t("searchEngine.label.location")}</InputLabel> */}
                        <Select
                            labelId="places"
                            id="places"
                            multiple
                            value={props.placesArray}
                            onChange={props.handleChangePlaces}
                            input={<OutlinedInput label={locationsText} />}
                            renderValue={(selected) => {return locationsText}}
                            displayEmpty
                            MenuProps={MenuProps}
                            size={"small"}
                            open={openPlaces}
                            onClose={handleClosedPlaces}
                            onOpen={handleOpenPlaces}

                        >
                            {Constants.INMOVILLA_CITIES.map((place) => (
                                <MenuItem key={place.city} value={place.city}>
                                <Checkbox checked={props.placesArray.indexOf(place.city) > -1} />
                                <ListItemText primary={place.city} />
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        </Tile>
                        <Tile className="tile-absolute">
                        <ul className="filter-results">
                            {utils.renderArrayAsList(props.t,'locations',props.placesArray, true)}
                        </ul>
                        </Tile>
                    </Tile>

                    {/* FILTRO 3: Tipo de Propiedad */}
                    <Tile kind="parent" size={2.4} vertical className="tile-vertical-aligned-top">
                    <Tile>
                        <FormControl fullWidth>
                            {/* <InputLabel id="demo-multiple-checkbox-label">Inmueble</InputLabel> */}
                            <Select
                                labelId="types"
                                id="types"
                                displayEmpty
                                multiple
                                value={props.typesArray}
                                onChange={props.handleChangeTypes}
                                input={<OutlinedInput label={typesText} />}
                                renderValue={(selected) => {return typesText}}
                                MenuProps={MenuProps}
                               size={"small"}
                               open={openTypes}
                               onClose={handleCloseTypes}
                               onOpen={handleOpenTypes}

                            >
                                {Constants.INMOVILLA_TYPES.map((type) => (
                                    <MenuItem key={type.tipo} value={type.tipo}>
                                    <Checkbox checked={props.typesArray.indexOf(type.tipo) > -1} />
                                    <ListItemText primary={props.t("searchEngine.filters.types.options." + utils.camelize(type.tipo))} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Tile>
                        <Tile className="tile-absolute">
                        <ul className="filter-results">
                            {utils.renderArrayAsList(props.t,'types',props.typesArray, true)}
                        </ul>
                        </Tile>
                    </Tile>
                    
                    {/* FILTRO 4: Dormitorios */}
                    <Tile kind="parent" size={2.4} vertical className="tile-vertical-aligned-top">
                        <Tile>
                        <FormControl fullWidth>
                        <Select
                            labelId="roomsNumbers"
                            id="roomsNumbers"
                            // multiple
                            value={props.roomsNumbersArray}
                            onChange={props.handleChangeRoomsNumber}
                            input={<OutlinedInput label={roomsNumbersText} />}
                            renderValue={(selected) => {return roomsNumbersText}}
                            displayEmpty
                            MenuProps={MenuProps}
                            size={"small"}
                            open={openRoomsNumbers}
                            onClose={handleCloseRoomsNumbers}
                            onOpen={handleOpenRoomsNumbers}


                        >
                            {Constants.roomsNumbers.map((roomsNumber) => (
                                <MenuItem key={roomsNumber} value={roomsNumber}>
                                <Checkbox checked={props.roomsNumbersArray.indexOf(roomsNumber) > -1} />
                                <ListItemText primary={roomsNumber} />
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        </Tile>
                        <Tile className="tile-absolute">
                        <ul className="filter-results">
                            {utils.renderArrayAsList(props.t,'roomsNumber',props.roomsNumbersArray, true)}
                        </ul>
                        </Tile>
                    </Tile>
                    
                    {/* FILTRO 5: Precios */}
                    <Tile kind="parent" size={2.4}>
                        {renderSlider()}
                    </Tile>
                </Tile>
                <Tile className="clean-filters-link-tile">
                    {renderCleanFiltersLink()}
                </Tile>
            </Tile>
        </Tile>
    }              
    const renderProps = () => {

        const propsToRender = { ...props };
        
        delete propsToRender.results;


        return <div>
            <h3>props</h3>
            <pre>
                {JSON.stringify(propsToRender, null, 2)}
            </pre>
        </div>
    }
    // Renders 
    const renderSearching = () => {
        return (<div className="search-results-container">
            
            <Tile size={12} vertical>
                <Box className="result result-skeletonNNN is-shady" key={props.index}>            
                    <SearchResultSkeletonHeaderOrFooter {...props} />
                </Box>
                <Box className="result result-skeleton is-shady p-0" key={props.index}>            
                    <SearchResultSkeletonElement {...props} />
                </Box>
                <Box className="result result-skeleton is-shady p-0" key={props.index}>            
                    <SearchResultSkeletonElement {...props} />
                </Box>
                <Box className="result result-skeletonNNN is-shady p-0" key={props.index}>
                    <SearchResultSkeletonHeaderOrFooter {...props} />
                </Box>
            </Tile>
            </div>
        )
    }
    const renderNoResults = () => {
        return (
            <Box className="no-results is-full-width is-shady">
                <Tile kind="ancestor">
                    <Tile kind="parent" vertical>
                        <Tile kind="child" color="primary">
                            <p className="title is-centered">
                                <RoofingTwoToneIcon />
                                &nbsp; 
                                {props.t('searchEngine.label.noResults')}
                                </p>
                            <hr />
                            {/* Link to reset filters */}
                            <div className="clean-filter-message is-centered">
                                <p className='is-centered'>
                                    {props.t('searchEngine.label.cleanFiltersMessage')}
                                   &nbsp;&nbsp;
                                    </p>
                                
                                <Button onClick={props.cleanFiltersAndSearch} className="is-centered" size="small" color="transparent">
                                    
                                    <DeleteTwoToneIcon /> {props.t('searchEngine.label.cleanFiltersMessageLink')}
                                </Button>
                            </div>
                        </Tile>
                    </Tile>
                </Tile>
            </Box>
        )
    } 

    // Render the component

    return  <div className="search-box-container">
        {/* Título y Filtros  */}
        <Tile kind="ancestor" width="100%" className="title-and-filters-section">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="is-full-width is-shady">
                <Tile size={12} vertical>
                    <Tile kind="parent" width="100%">
                        {false && renderHeaderSearchBoxSection()}

                    </Tile>
                    <Tile kind="parent" width="100%" className="search-filter-section">
                        {renderFilterSection()}
                    </Tile>
                </Tile>
            </Box>
        </Tile>

        {/* Input de búsqueda  */}
        <Tile kind="ancestor" width="100%" className="search-box-section">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="is-shady is-full-width">
                {renderSearchSection()}
            </Box>
        </Tile>

        {/* Scroll Pointer */}
        <span className={"scroll-pointer" + extraPaddingClass} ref={scrollPointer}>scroll pointer</span>


        {/* Boton de búsqueda  */}
        <Tile kind="ancestor" width="100%">
        <Tile kind="parent" size="9" className='control-buttons-tile'>
                <FiltersSummary {...props} />
            </Tile>
            <Tile kind="parent" size="3" width="100%" className='control-buttons-tile'>
            {renderMapSearchButton()}
            {renderSearchButton()}
            </Tile>
        </Tile>

        {/* Boton de búsqueda  */}
        <Tile kind="ancestor" width="100%">
            <Tile kind="parent" width="100%" className="search-results-section">
                {false &&  renderProps()}

                {/* SEARCHING... */}
                {/* SEARCHING... */}
                {/* SEARCHING... */}
                {IS_SEARCHING && renderSearching()}

                {/* NO RESULTS */}
                {/* NO RESULTS */}
                {/* NO RESULTS */}
                {!IS_SEARCHING && NO_RESULTS && renderNoResults()}
                {/* LIST */}
                {/* LIST */}
                {/* LIST */}
                {!IS_SEARCHING && HAS_RESULTS && !HAS_SELECTED && IS_SEARCH_SIMPLE_TAB &&
                    <div className="search-results-container">
                    <SummaryHeader {...props} />

                    <SearchResultsList {...props} />

                    <ResultsSummaryFooter
                        {...props}
                        executeScroll={executeScroll}
                        handleChangeResultsPerPage={handleChangeResultsPerPage}
                    />
                    </div>
                }
                {/* MAP */}
                {/* MAP */}
                {/* MAP */}
                {!IS_SEARCHING && HAS_RESULTS && IS_SEARCH_MAP_TAB &&  
                    <div className="search-results-container">
                        <SearchResultsDetailHeader {...props} />

                        <SearchResultsMap {...props} />

                        <DetailSummaryFooter
                            {...props}
                            executeScroll={executeScroll}
                            handleChangeResultsPerPage={handleChangeResultsPerPage}
                        />
                    </div>
                }
                {/* DETAIL */}
                {/* DETAIL */}
                {/* DETAIL */}
                {!IS_SEARCHING && HAS_RESULTS && HAS_SELECTED && IS_SEARCH_SIMPLE_TAB &&
                    <div className="search-detail-container">
                        <SearchResultsDetailHeader {...props} />
                        <SearchDetail
                            {...props}
                            executeScroll={executeScroll}    
                        />
                        <DetailSummaryFooter
                            {...props}
                            executeScroll={executeScroll}
                            handleChangeResultsPerPage={handleChangeResultsPerPage}
                        />
                    </div>
                }
            </Tile>
        </Tile>
    </div>
}
