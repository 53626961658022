// Theming
import * as React from 'react';
import {Button } from 'react-bulma-components';
import OtherHousesTwoToneIcon from '@mui/icons-material/OtherHousesTwoTone';
import CollectionsTwoTone from '@mui/icons-material/CollectionsTwoTone';
import { RoomTwoTone } from '@mui/icons-material';


export default function FeaturesButtons (props) {
    const HAS_VIDEO = true || props.for.tour_virtual === 1;
    return (
    <div className="detail-buttons">

        <Button itemID={props.for.id} className="is-outlined has-text-centere" color="dark" size="medium" onClick={props.handleSetSelectedAndOpenPhotos}>
            <CollectionsTwoTone className= {'button-icon'} />
            {props.for.numfotos} {props.t("searchEngine.label.photos")}
        </Button>
        {HAS_VIDEO && <Button itemID={props.for.id} className="is-outlined has-text-centere" color="dark" size="medium" onClick={props.handleSetSelectedAndOpenVideo}>
            <CollectionsTwoTone className= {'button-icon'} />
            {props.for.tour_virtual === 0 ? '0 ' + props.t("searchEngine.label.videos") : '1 video'}
        </Button>}
        <Button itemID={props.for.id} className="is-outlined is-hiddenn" color="dark" size="medium" onClick={props.handleSetSelectedAndOpenDescription}>
            <OtherHousesTwoToneIcon className= {'button-icon'} />
            {props.t("searchEngine.label.features")}
        </Button>
        <Button itemID={props.for.id} className="is-outlined is-hiddenn" color="dark" size="medium" onClick={props.handleSetSelectedAndOpenLocation}>
            <RoomTwoTone className= {'button-icon'} />
            {props.t("searchEngine.label.viewLocation")}
        </Button>
    </div>
    )
}
