import React, { Suspense } from 'react';
import classNames from 'classnames';

// Freqtrade Comopnents
import IsLoading from 'layout/isLoading';
import SearchEngine from 'components/browser';

// Tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Scrollbars
import { Scrollbars } from 'react-custom-scrollbars-2';

// Theming
import { Block, Section ,Tile} from 'react-bulma-components';

// Tooltips
import ReactTooltip from "react-tooltip";

// Icons
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// Styles
import 'styles/App.sass';
import 'bulma-helpers/css/bulma-helpers.min.css'
import 'bulma/css/bulma.min.css';
import Footer from '../layout/skeleton/footer';
// import { MapContainer, TileLayer } from 'react-leaflet';


  function TabPanel(props) {

    const { children, value, index, tabs, mode, ...other } = props;
    const IS_THE_ONE_TO_RENDER = tabs[index] ? value === tabs[index].value : false;
    return IS_THE_ONE_TO_RENDER ? <div
                role="tabpanel"
                hidden={!IS_THE_ONE_TO_RENDER}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                className={IS_THE_ONE_TO_RENDER ? 'tab-panel is-active' : 'tab-panel'}
                {...other}
            >
                <Block sx={{ p: 3 }}>
                    {children}
                </Block>
            </div> : <></>;
  }
  
// Suspense Navbar
const NavBarSuspense = React.lazy(() => import('layout/skeleton/navbar'));

// Main class
export default function CazaTuCasaSearchEngine (props) {
    
    {/*  -------------------------  */}
    {/*       1. 🔈 Events          */}
    {/*  -------------------------  */}
    const handleSelectedTabChange = (event, value) => {
        props.setSelectedTab(value);
    }
 
    {/*  -------------------------  */}

    {/*  -------------------------  */}
    {/*      2. ⚙️ Renderers         */}
    {/*  -------------------------  */}


    {/*                             */}
    {/*   -  ⎍ Tabs section  -     */}
    {/*                             */}
    const renderTabsSection = () => {

        // Declarate tabs
        const tabs = [
            {
                label: props.t ? props.t('searchEngine.label.searchBoxButton') : 'Buscar',
                value: 'search-simple',
                icon: <ManageSearchIcon className="header-icon"/>

            },
            {
                label: props.t ? props.t('searchEngine.label.searchMapButton') : 'Mapa',
                value: 'search-map',
                icon: <TravelExploreIcon className="header-icon"/>

            }
        ];

        // Find active tab coming from props
        let selectedTab = tabs.find(tab => tab.value === props.selectedTab);
        selectedTab = selectedTab || (props.mode === "embedded" ? tabs[0] : tabs[1]);

        // console.log("🚀 ~ file: index.js ~ line 180 ~ renderTabsSection ~ selectedTab", selectedTab);
        // Print tabs
        return <article className="tabs-container clean-article is-fullheight is-full-width is-bordered-1 is-relative">
          <p className="title is-hidden">
                {selectedTab.icon}
                {selectedTab.label}
           </p>

            {/* Tab Box */}
            <Block sx={{ borderBottom: 1, borderColor: 'divider' }} className="show-on-hover">
            <Tabs
                        value={selectedTab.value}
                        onChange={handleSelectedTabChange}
                        // variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        centered
                        // textColorPrimary="green"
                        // textColorSecondary="red"
                >
                            {tabs.map(tab => (
                                <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                            ))}
                </Tabs>
            </Block>        

            {/* Balance section */}
            <TabPanel value={selectedTab.value} tabs={tabs} name="search-simple"  index={0}>
                {renderSearchEngine()}
            </TabPanel>

            {/* Dependency Graph section */}    
            <TabPanel value={selectedTab.value} tabs={tabs} name="search-map"  index={1}>
                {renderSearchEngine()}
            </TabPanel>     
            </article>;
    }
        
    {/*                             */}
    {/*   -  ⏱ Dash section   -    */}
    {/*                             */}
    const renderSearchEngine = () => {
        return (
            <div className="static-content content is-hiddenn">
                <SearchEngine {...props} />
            </div>
        );
    }
    {/*                             */}
    {/* - 👩‍💻 Developmnt section  -  */}
    {/*                             */}
    /*const renderDevelSection = () => {
        return (
            <div className="content">
                <img src="img/digraph/dependencygraph-server.svg" alt="graph server" />
                <img src="img/digraph/dependencygraph-client.svg" alt="graph client" />
            </div>
        );
    }*/
    {/*                             */}
    {/*  -  🦶 Footer section  -    */}
    {/*                             */}
    const renderFooterSection = () => {
        return (
            <article className="clean-article  is-bordered-1 is-hiddenn">
            <p className="title">
                <img src="img/docker-logo.png" className="docker-header-icon" alt="Freqtrade" />
                Docker Images
                </p>
                <div className="content">
                    {true &&
                        <>
footer content                        </>
                    }
                </div>
            </article>
        );
    }
    {/*  -------------------------  */}
    {/*    -    🤖 MAIN APP    -    */}
    {/*  -------------------------  */}
    const renderCazaTuCasaSearchEngine = () => {

        const IS_DARK = props.theme === 'dark';
        const IS_LIGHT = props.theme === 'light';

        const themeHeroClass = IS_DARK ? 'hero is-dark' : 'hero is-light';
        const reactTooltipType = IS_LIGHT ? 'light' : 'dark';
        const IS_LOADING = props.loading === true && false;
        const IS_STANDALONE = props.mode && props.mode === "standalone";
        const IS_LOCALHOST = window.location.href.includes('localhost');
        const appClass = classNames({
            //            'is-hidden': !props.isAppReady,
            //            'is-loading': !props.isAppReady,
                    'main-container': true,
                    'is-theme-dark': IS_DARK,
                    'is-theme-light': IS_LIGHT,
                    'is-standalone': IS_STANDALONE,
                    'is-localhost': IS_LOCALHOST,     
        });
        // App
        let app = (
            <Scrollbars
                // This will activate auto hide
                autoHide
                // Hide delay in ms
                // autoHideTimeout={700}
                // Duration for hide animation in ms.
                // autoHideDuration={200}
                autoHeight
                autoHeightMin={'calc(100vh - 0px)'}
                >

                <div className={appClass}>
                
                    { /* 9. Navbar */ }
                    {!IS_STANDALONE && 
                    <Suspense fallback={<div className='fallback-navbar'>Loading...</div>}>
                        <NavBarSuspense
                            {...props}
                        />
                    </Suspense>
                    
                    }
                    <Section id="cazatucasa_search_engine_v1" className={"hero is-bold is-fullheight-with-navbar " + themeHeroClass}>                

                        { /* IS LOADING BOX */ }
                        {IS_LOADING && <IsLoading />}

                        { /* MAIN CONTENT */ }
                        {!IS_LOADING &&
                        <>
                            <Block className="">
                                {/* <RefreshButton setJsonConfig={props.setJsonConfig} configJson={props.jsonConfig} lastUpdate={props.lastUpdate} reloadData={props.reloadData}/> */}
                                <Tile kind="ancestor">
                                    <Tile size={12} vertical className="tabs-tile">
                                        <Tile kind="parent" size={12} className="tab-widget-container">
                            
                                            {true && renderTabsSection()}
                                        </Tile>
                                        {!IS_STANDALONE && <Tile kind="parent" width="100%">
                                            {false && renderFooterSection()}
                                        </Tile>}
                                    </Tile>
                                </Tile>
                            </Block>
                        </>}
                    </Section>
                    <Footer
                        className={themeHeroClass + ' is-hidden'}
                        textLeft={'Sangra la napia por la pestaña'}
                        textRight={'Sangra más'}
                    />
                                
                    <ReactTooltip type={reactTooltipType} clickable={true} />

                </div>
            </Scrollbars>
        );
        return app;
    }
    
    {/*  -------------------------  */}
    {/*    3. 💪 Some Functions     */}
    {/*  -------------------------  */}
    
    {/*  -------------------------  */}
    {/*  -  🤖 MAIN APP RENDERER -  */}
    {/*  -------------------------  */}
    const cazaTuCasaSearchApp = renderCazaTuCasaSearchEngine();
    
    return cazaTuCasaSearchApp;
}
