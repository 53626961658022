export const translation = {
    navbar: {
        label: {
            reactVersion: 'React version',
            lang: {
                language: 'Language',
                es: 'Spanish',
                en: 'English',
                fr: 'French',
                de: 'German'
            },
            undoRedo: {
                step: 'Step',
                undo: 'Undo',
                redo: 'Redo',
            },        
        }
    },
    searchEngine: {
        label: {

            // Search Engine Labels
            // searchBoxPlaceholder: 'Apartment in Playa Blanca, Detached house for rent in Teguise...',
            searchBoxPlaceholder: 'Playa Blanca, Teguise...',
            searchBoxSlogan: 'You are a click away from finding <1>your dream home</1>',
            searchBoxButton: 'Search',
            searchMapButton: 'Map',
            searchMapButtonTooltip: 'View on map',
            searchMapButtonTooltipClose: 'Close map',
            searchMapButtonTooltipOpen: 'Open map',
            priceRangeLabel: 'Price',
            cleanFiltersLink: 'Clean filters',
            cleanFiltersMessage: 'Try to clean the filters and search again',
            cleanFiltersMessageLink: 'Clean filters and start again',

            // Search Engine Results            
            noResults: 'No results found',
            dormitorio: 'bedroom',
            dormitorios: 'bedrooms',
            resultado: 'result',
            resultados: 'results',
            verEnElMapa: 'See in map',
            ordenarPor: 'Sort by',
            precio: 'Price',
            relevancia: 'Relevance',
            habitaciones: 'Rooms',
            price: 'Price',
            baños: 'Bathrooms',
            superficie: 'Surface',
            from: 'From',
            to: 'To',
            of: ' of ',
            sale: 'sale',
            rent: 'rent',
            on: ' on ',
            in: ' in ',
            for: ' for ',
            map: ' Map',
            viewLocation: 'Location',
            yes: 'Yes',
            no: 'No',
            noLimit: 'No limit',
            code: 'Code',
            ref: 'Reference',
            
            // Detail of the property
            photo: 'photo',
            photos: 'photos',
            video: 'video',
            videos: 'videos',
            noVideos: 'There are no videos for this property',
            features: 'Features',
            description: 'Description',
            basicFeatures: 'Basic features',
            location: 'Location',
            equipment: 'Equipment',
            offerInfo: 'Offer info',
            energeticCertification: 'Energetic certification',

            // Actions
            actions: 'Actions',
            callAgent: 'Call agent',
            deleteFromSearch: 'Delete from search',
            agent: 'Agent',
            seeProfile: 'See profile',
            sendMessage: 'Send message',

            // Description of the property
            thisBuildingText: 'This building',
            isInText: 'is allocated in',
            andHasText: 'and has',
            roomsText: 'rooms',
            bathroomsText: 'bathrooms',
            roomText: 'room',
            bathroomText: 'bathroom',
            sizeText: 'with a total size of',
            priceText: 'and its price is ',
            roomTextCompressed: 'rooms',
            goBackToSearch: 'Go back to search',
            seeVideo: 'See video', 
            seePhotos: 'See photos', 

            // Map 
            fullScreenMap: 'Full screen map',
            exitFullScreenMap: 'Exit full screen map',

            // Actions
            seePhone: 'See phone',
            contact: 'Contact',
            delete: 'Delete',
            
            // Footer Toolbar
            show: 'Show',
            resultsPerPageText: 'Results per page',
            gotoTopText: 'Go to top',

            // Tour Virtual
            tourVirtual: 'Virtual tour', 
            noTourVirtual: 'There is no virtual tour for this property', 
            seeTourVirtual: 'See virtual tour', 

        },
        filters: {
            operations: {
                placeholder: 'What are you looking for?',
                options: {
                    alquilar: 'Rent',
                    comprar: 'Sale',
                    traspasar: 'Transfer'
                }
            },
            locations: {
                placeholder: 'Location',
                options: {
                    arrecife: 'Arrecife',
                    haria: 'Haría',
                    tias: 'Tías',
                    sanBartolome: 'San Bartolomé',
                    teguise: 'Teguise',
                    tinajo: 'Tinajo',
                    yaiza: 'Yaiza',
                }
            },
            types: {
                placeholder: 'Building Type',
                options: {
                    apartamento: 'Apartment',
                    ático: 'Attic',
                    atico: 'Attic',
                    casa: 'House',
                    chalet: 'Chalet',
                    duplex: 'Duplex',
                    dúplex: 'Duplex',
                    localComercial: 'Commercial premises',
                    "local comercial": 'Commercial premises',
                    piso: 'Flat',
                    restaurante: 'Restaurant',
                    solar: 'Plot',
                    terrenoUrbano: 'Urban land',
                    "terreno urbano": 'Urban land',
                    villa: 'Villa',
                    parcela: 'Plot',

                },
            },
            roomsNumber: {
                placeholder: 'Rooms Number',
                options: {
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    masDe5: 'More than 5'
                }
            },
            sortBy: {
                placeholder: 'Sort by',
                options: {
                    precio: 'Price',
                    relevancia: 'Relevance',
                    habitaciones: 'Rooms',
                    metros: 'Surface area',
                }
            }
        },
        features: {
            fechacambio: 'Date change',
            agencia: 'Agency',
            cod_ofer: 'Offer code',
            ref: 'Reference',
            vistasalmar: 'Sea views',
            energiarecibido: 'Energy received',
            vistasdespejadas: 'Clear views',
            tipomensual: 'Monthly type',
            tipomensual_options: {
                'MES': 'Month',
                'TRIMESTRE': 'Quarter',
                'SEMESTRE': 'Semester',
                'AÑO': 'Year',
            },
            price: 'Price',
            habitaciones: 'Rooms',
            habdobles: 'Double rooms',
            bathrooms: 'Bathrooms',
            size: 'Size',
            city: 'City',
            operation: 'Operation',
            type: 'Type',
            place: 'Place',
            latitude: 'Latitude',
            longitude: 'Longitude',
            m_parcela: 'Parcel size',
            balcon: 'Balcony',
            m_cons: 'Construction size',
            m_uties: 'Useful size',
            conservacion: 'Conservation',
            calefacentral: 'Central heating',
            airecentral: 'Central air conditioning',
            plaza_gara: 'Parking space',
            terraza: 'Terrace',
            ascensor: 'Elevator',
            montacargas: 'Lift',
            muebles: 'Furniture',
            calefaccion: 'Heating',
            aire_con: 'Air conditioning',
            primera_line: 'First line',
            piscina_com: 'Community pool',
            piscina_prop: 'Private pool',
            total_hab: 'Total rooms',
            sumaseos: 'Total bathrooms',
            parking: 'Parking',
            todoext: 'All exterior',
            distmar: 'Distance to the sea',
            ciudad: 'City',
            zona: 'Zone',
            nbtipo: 'Type',
            nbconservacion: 'Conservation',
            nbconservacion_options: {
                "Para reformar": "To reform",
                "De origen": "Of origin",
                "Reformar Parcialmente": "Partially reform",
                "Entrar a vivir": "Enter to live",
                "Buen estado": "Good state",
                "Semi Reformado": "Semi reform",
                "Reformado": "Reform",
                "Semi nuevo": "Semi new",
                "Nuevo": "New",
                "Obra Nueva": "New work",
                "En construcción": "In construction",
                "En proyecto": "In project",
                "Ninguno": "None",
            },
            precio_calculado: 'Calculated price',
            mascotas: 'Pets',
            aconsultar: 'To consult',
            outlet: 'Outlet',
            aseos: 'Bathrooms',
            m_terraza: 'Terrace size',
            keycalefa: 'Key heating',
            keycalefa_options: {
                1: "Natural gas",
                2: "Gas oil",
                3: "Electricity",
                4: "Pellets",
                5: "Solar",
                6: "Biomass",
                7: "Others",
                8: "No",
            },            
            eninternet: 'Internet',
            zonaauxiliar: 'Auxiliary area',
            urbanizacion: 'Urbanization',
        }                                 
    }
}