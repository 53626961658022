import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as translationEs from '../i18n/es.js';
import * as translationEn from '../i18n/en.js';
import * as translationFr from '../i18n/fr.js';
import * as translationDe from '../i18n/de.js';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            es: {
                translation: translationEs.translation,
            },
            en: {
                translation: translationEn.translation,
            },
            fr: {
                translation: translationFr.translation,
            },
            de: {
                translation: translationDe.translation,
            }
        }
    });
    

export default i18n;