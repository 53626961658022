import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import * as React from 'react';
import * as utils from '../../../../core/utils';
import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone';
import RoomTwoToneIcon from '@mui/icons-material/RoomTwoTone';
import KingBedTwoToneIcon from '@mui/icons-material/KingBedTwoTone';
import EuroTwoToneIcon from '@mui/icons-material/EuroTwoTone';
import ChatBubbleOutlineTwoToneIcon from '@mui/icons-material/ChatBubbleOutlineTwoTone';
import {Tile, Tag, Box} from 'react-bulma-components';
import * as Constants from '../../../../core/constants';

export default function FiltersSummary (props) {

    const {operationsArray, typesArray, roomsNumbersArray, placesArray, rangePrice} = props;

    const HAS_OPERATIONS = operationsArray.length > 0;
    const HAS_TYPES = typesArray.length > 0;
    const HAS_ROOMS_NUMBERS = roomsNumbersArray.length > 0;
    const HAS_PLACES = placesArray.length > 0;
    const HAS_PRICE_RANGE = rangePrice.length > 0 && (rangePrice[0] !== 0 || rangePrice[1] !== Constants.rangePrice.max + 1);
    const HAS_TEXT_SEARCH = props.textSearch.length > 0;
    const ARE_FILTERS_EMPTY = props.areFiltersEmtpy();
    const IS_SEARCH_TEXT_EMPTY = props.isSearchTextEmpty();
    const dormitorioString = props.roomsNumbersArray[0] === ['1'] && !props.roomsNumbersArray[1] ? props.t ('searchEngine.label.dormitorio') : props.t ('searchEngine.label.dormitorios');
    const isInvisibleClass = (ARE_FILTERS_EMPTY && IS_SEARCH_TEXT_EMPTY)? 'is-invisible' : '';

    const initialValue = props.rangePrice[0];
    const finalValue = props.rangePrice[1];
    let initialValueConverted = utils.convertToKFormat(initialValue);
    if (initialValue === Constants.rangePrice.min) {
        initialValueConverted = '0';
    }
    let finalValueConverted = utils.convertToKFormat(finalValue);
    if (finalValue === Constants.rangePrice.max + 1) {
        finalValueConverted = props.t ('searchEngine.label.noLimit');
    }

    // ;
    return <Box className={"filters-summary-box is-shadiest is-full-width " + isInvisibleClass}>
        <Tile kind="parent" size={12} className="filters-summary-tile">
            <Tile kind="child" size={12} className="filters-summary-tile">

                {HAS_OPERATIONS &&
                    <Tile className="one-filter">
                        <Tag.Group hasAddons>
                            <Tag color="info">
                            <ChatBubbleOutlineTwoToneIcon className="tag-icon" />
                            </Tag>
                            <Tag>
                                <ul className="filters-summary-list">
                                    {utils.renderArrayAsList(props.t, 'operations', props.operationsArray, true)}
                                </ul>
                            </Tag>
                        </Tag.Group>

                    </Tile>
                }
                {HAS_PLACES &&
                    <Tile className="one-filter">
                        <Tag.Group hasAddons>
                            <Tag color="info">
                            <RoomTwoToneIcon className="tag-icon" />
                            </Tag>
                            <Tag>
                            <ul className="filters-summary-list">
                                {utils.renderArrayAsList(props.t, 'locations', props.placesArray, true)}
                            </ul>
                            </Tag>
                        </Tag.Group>
                    </Tile>
                }
                {HAS_TYPES &&
                    <Tile className="one-filter">
                        <Tag.Group hasAddons>
                            <Tag color="info">
                            <CottageTwoToneIcon className="tag-icon" />
                            </Tag>
                            <Tag>
                                <ul className="filters-summary-list">
                                    {utils.renderArrayAsList(props.t, 'types', props.typesArray, true)}
                                </ul>
                            </Tag>
                        </Tag.Group>
                    </Tile>
                }
                {HAS_ROOMS_NUMBERS &&
                    <Tile className="one-filter">
                        <Tag.Group hasAddons>
                            <Tag color="info">
                            <KingBedTwoToneIcon className="tag-icon" />
                            </Tag>
                            <Tag>
                                <ul className="filters-summary-list">
                                
                                    {utils.renderArrayAsList(props.t, 'roomsNumber', props.roomsNumbersArray, true)}
                                    
                                    &nbsp;{dormitorioString}
                                </ul>
                                
                            </Tag>
                        </Tag.Group>
                    </Tile>
                }
                {HAS_PRICE_RANGE &&
                    <Tile className="one-filter">
                            <Tag.Group hasAddons>
                                <Tag color="info">
                                <EuroTwoToneIcon className="tag-icon" />
                                </Tag>
                                <Tag>
                                <ul className="filters-summary-list">
                                {props.t("searchEngine.label.from") + " " + utils.convertToKFormat(rangePrice[0]) + " " + props.t ("searchEngine.label.to") + " " + finalValueConverted}
                                </ul>
                                </Tag>
                            </Tag.Group>
                    </Tile>
                }
                {HAS_TEXT_SEARCH &&
                    <Tile className="one-filter">
                        <Tag.Group hasAddons>
                            <Tag color="info">
                                <SearchTwoToneIcon className="tag-icon" />
                            </Tag>
                            <Tag>
                                {props.textSearch}
                            </Tag>
                        </Tag.Group>
                    </Tile>
                }
            </Tile>
        </Tile>
    </Box>
    }
