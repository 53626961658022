// Theming
import { Button, Box, Level } from 'react-bulma-components';
import * as React from 'react';
import Select from '@mui/material/Select';
import * as Constants from '../../../../core/constants';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ReactTooltip from 'react-tooltip';

import SummaryNavigator from '../summaryNavigator';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
};
  
export default function SummaryFooter (props) {

    // Rebuild tooltips
    React.useEffect(() => {
        ReactTooltip.rebuild();
    });
    
    const renderGoToTopLink = () => {
        return (
            <div className="go-to-top">
                <Button
                    className="go-to-top-button"
                    onClick={() => props.executeScroll()}
                    data-tip={'Volver al inicio'}
                    data-border="false"
                    data-size="medium"
                    data-effect="solid"
                    data-offset="{'top': 10, 'left': 0}"
                    data-place="top"
                    data-html="true"
                    data-delay-show={100}
                >
                    <ArrowUpwardIcon />
                </Button>
            </div>
        );
    };
    
    // if (props.results.length > props.currentPage.length) {
    //     resultsAsText = `${props.results.length} resultados (mostrando ${props.currentPage.length})`;
    // }
    const renderPaginatedOptions = () => {
        return (
            <div className="page-results-select">
                <p className="page-results-text">{props.t("searchEngine.label.show")}</p>
                    <Select
                                    labelId="resultsPerPage"
                                    id="resultsPerPage"
                                    value={props.resultsPerPage ? props.resultsPerPage : 1}
                                    onChange={props.handleChangeResultsPerPage}
                                    MenuProps={MenuProps}
                                    size={"small"}
                                    renderValue = {(selected) => selected}
                                    data-tip={'Resultados por página'}
                                    data-border="false"
                                    data-size="medium"
                                    data-effect="solid"
                                    data-offset="{'top': 10, 'left': 0}"
                                    data-place="top"
                                    data-html="true"
                                    data-delay-show={100}
                  
                                >
                                    {Constants.resultsPerPageOptions.map((resultPerPageOption) => (
                                    <MenuItem key={resultPerPageOption} value={resultPerPageOption}>
                                    <ListItemText primary={resultPerPageOption} />
                                    </MenuItem>
                                ))}
                                </Select>
            </div>
        );
    }
    return (
        <div className="search-results-summary-container">
            <Box className="summary is-shady ">
                <Level className="summary-level is-fullwidth is-mobile">
                <Level.Side align='left'>
                    <Level.Item>
                    <SummaryNavigator {...props} />
                    </Level.Item>
                </Level.Side>
                <Level.Side align='right'>
                        <Level.Item>
                            {renderPaginatedOptions()}
                        </Level.Item>
                    </Level.Side>
                </Level>
            </Box>
            {renderGoToTopLink()}
        </div>
    )
}
