import {useEffect} from 'react';
import React from 'react';
import {Box, Button } from 'react-bulma-components';
import { MapContainer, TileLayer, Marker, Popup  } from 'react-leaflet'
import L from 'leaflet';
import SearchResultElement from '../../elements/searchResultElement';
import { FullscreenTwoTone, FullscreenExitTwoTone } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';


export default function SearchResultsMap (props) {

    const [map, setMap] = React.useState(null);
    const mapRef = React.useRef();
    const initialZoom = 11;
    const initialPosition = [29.0229,-13.6361]; // Centro Lanzarote más o menos
    const myIconProperties = {
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    };

    // Para los tooltips
    useEffect(() => {
        ReactTooltip.rebuild();
        console.debug(map);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    // Update fullScreen status
    useEffect (() => {
        console.log('Fullscreen set to: %o', props.isMapFullScreen);
        props.setIsMapFullScreen(props.isMapFullScreen);

        // Trigger a resize event to force the map to resize
        if (mapRef.current) {
            mapRef.current.invalidateSize();

        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isMapFullScreen])

    // Update map position
    const abre = () => {
        const selectedProperty = props.selected ? props.selected : props.results[0];


        // Take the HTML element of the marker and click it        
        const marker = document.querySelector('img.marker-for-' + selectedProperty.id);
        
        if (marker) {
            marker.click();
        }
        
        // Update the map position
        if (mapRef.current) {
            mapRef.current.invalidateSize();
            mapRef.current.setView([selectedProperty.latitud, selectedProperty.altitud], initialZoom);

            // Add an offset to the map position
            mapRef.current.panBy([0, -50]);

        }
    }
    // Update fullScreen status
    useEffect (() => {
      
        // Opening selected property

        // setTimeout(() => {abre();}, 500);
        setTimeout(() => {abre();}, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected, props.selectedTab])

    const resizeMapScreen = (e) => {
        // e.preventDefault();
        
        // Take the div of the map and apply it a class to fully cover the screen
        ReactTooltip.hide();
        props.setIsMapFullScreen(!props.isMapFullScreen);

    }
    const renderTopPart = () => {
        const dataTip = props.isMapFullScreen ? props.t("searchEngine.label.exitFullScreenMap") : props.t("searchEngine.label.fullScreenMap");
        return (<Button className="full-screen-link" href="#" onClick={resizeMapScreen} target="_blank"
            data-tip={dataTip}
            data-border="false"
            data-effect="solid"
            data-offset="{'top': 0, 'left': 0}"
            data-place="left"
            data-html="true"
            data-delay-show={100}
            data-delay-hide={100}
            data-multiline="true"
            data-class="is-tooltip-for-map"

        >
            {
                props.isMapFullScreen ? <FullscreenExitTwoTone /> : <FullscreenTwoTone />                
            }
        </Button>
        );
    }
    /*const renderBottomPart = () => {
        return(<Level className="is-mobile">
        <Level.Side align="left">
            <Level.Item>
                <small>&nbsp;</small>
            </Level.Item>
        </Level.Side>
        <Level.Side align="right">
            <Level.Item>
                <small>
                    <a href="https://www.openstreetmap.org/#map=10/29.0328/-13.6835" target="_blank">Ver en openstreetmap.org</a>
                </small>
            </Level.Item>
        </Level.Side>
    </Level>
    );
    }*/
    const renderMarkers = () => {
        return props.results.map((result, index) => {

            // // If there is a selected property and we are in the detail tab, show only that property
            if ((props.selected && props.selected.id !== result.id) && props.selectedTab === 'search-simple') {
                return null;
            }
            // Create a marker icon inherited from the default one
            const markerIcon = L.icon({
                ...myIconProperties,
                className: `marker-for-${result.id}`
            });
            const IS_DETAIL_TAB = props.selectedTab === 'search-simple';
        
            return (
                <Marker key={index} className="cazatucasa-marker" position={[result.latitud, result.altitud]} icon={markerIcon} isActive={true}>
                    
                    {!IS_DETAIL_TAB && <Popup>
                        <SearchResultElement key={index} result={result} {...props} isCard />
                    </Popup>}
                </Marker>
            )
        })
    }
    const renderMapSection = () => {

        const IS_DETAIL_TAB = props.selectedTab === 'search-simple';

        const center = props.selected ? [props.selected.latitud, props.selected.altitud] : initialPosition;
        return (
            // Render Box with Map
            <Box className={"is-full-width is-shady is-map-container " + (props.isEmbedded ? 'embedded': '')}>

                {/* Render top part */}
                {true && !IS_DETAIL_TAB && renderTopPart()}


                <MapContainer
                    ref={mapRef}
                    center={center}
                    zoom={initialZoom}
                    whenCreated={setMap}
                    id="myMap"
                    scrollWheelZoom={!IS_DETAIL_TAB}
                >
                
                    <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                    {/* Render all markers from results */}
                    {renderMarkers()}

                </MapContainer>
                
                {/* Render bottom part */}
                {/* {renderBottomPart()} */}
                                
            </Box>
        );
    }

    // Render
    const className = props.isMapFullScreen ? 'search-results-map is-map-full-screen' : 'search-results-map';
    return (<div className={className}>
            {renderMapSection()}
        </div>
    )
}

