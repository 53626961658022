import { createContext, useState, useEffect, useMemo } from 'react';
import React from 'react';
import CazatuCasaSearchEngine from './components/CazaTuCasaSearchEngine';
import { ErrorBoundary } from 'core/errorBoundary';
import * as api from 'core/api';
import * as utils from 'core/utils';
import { withCookies } from 'react-cookie';
// import i18n (needs to be bundled ;))
import './core/i18n';
import * as Constants from 'core/constants';
import { useTranslation } from 'react-i18next';
import CazaTuCasaCarousel from 'components/CazaTuCasaCarousel';

export const AuthContext = createContext({
    isAuthenticated: false,
    user: {},
    login: () => {},
    logout: () => {},
    toggleAuth: () => {},
});
// If url contains 'localhost', then it's a local environment
const IS_LOCALHOST = window.location.href.includes('localhost');
// console. log ( 'is locahost: ' , IS_LOCALHOST);

const App = (props) => {

    // -----------------------------------
    //            0. Setup
    // -----------------------------------

    // const [mode] = useState(IS_LOCALHOST ? 'embedded' : 'standalone');
    const [mode] = useState(props.mode || 'standalone');
    const cookies = useMemo(() => props.cookies, [props.cookies]);
    
    const [loading] = useState(true);
    const [theme, setTheme] = useState(cookies.get('theme') || 'light');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(cookies.get('selectedTabxxxxxx') || (IS_LOCALHOST ? 'search-simple' : 'search-simple'));
    const [isAuthenticated, setIsAuthenticated] = useState(false);
 
    // -----------------------------------
    const [operationsArray, setOperationsArray] = React.useState(cookies.get('selectedOperations') || []);
    const [typesArray, setTypesArray] = React.useState(cookies.get('selectedTypes') || []);
    const [roomsNumbersArray, setRoomsNumbersArray] = React.useState(cookies.get('selectedRoomsNumbers') || []);
    const [placesArray, setPlacesArray] = React.useState(cookies.get('selectedPlaces') || []);
    const [rangePrice, setRangePrice] = React.useState(cookies.get('selectedRangePrices') || [Constants.rangePrice.min, Constants.rangePrice.max + 1]);
    const [textSearch, setTextSearch] = React.useState('');
    const [codOffer, setCodOffer] = React.useState(null);
    const [results, setResults] = React.useState(null); // Means no search in place. Distinct to [] (0 results)
    const [featuredResults, setFeaturedResults] = React.useState(null);
    const [isSearching, setIsSearching] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    
    const [selectedSection, setSelectedSection] = React.useState(null);
    const [searchSortBy, setSearchSortBy] = React.useState(cookies.get('selectedSearchSortBy') || 'Precio');
    const [searchSortByDirection, setSearchSortByDirection] = React.useState(cookies.get('selectedSearchSortByDirection') || 'asc');
    const [isMapFullScreen, setIsMapFullScreen] = React.useState(true); 
    const [lang, setLang] = React.useState(cookies.get('selectedLang') || props.lang || 'es');

    // Pagination
    const [resultsPerPage, setResultsPerPage] = React.useState(parseInt(props.cookies.get('selectedResultsPerPage'), 10) || 10);
    const [currentPage, setCurrentPage] = React.useState(parseInt(props.cookies.get('selectedCurrentPage'), 10) || 1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [paginationInfo, setPaginationInfo] = React.useState(null);
    
    // Cookies
    const setResultsPerPageInCookie = (resultsPerPage) => {    

        // Set the theme in the cookies
        const resultsPerPageAsNumber = parseInt(resultsPerPage, 10);
        const { cookies } = props;
        cookies.set('selectedResultsPerPage', resultsPerPage, { path: '/' });

        setResultsPerPage(resultsPerPageAsNumber);
    }
    // Translation
    const { i18n, t } = useTranslation();


    // First time setup
    // run once!
    useEffect(() => {

        // -----------------------------------
        //            1. Get data
        // -----------------------------------
        // Log init app
        utils.log('0. App Initialized...', 'MAIN_END', new Date().toISOString());
        utils.log('1. Asking for last commit...', 'MAIN_START', new Date().toISOString());
        
        if (featuredResults === null) {
            // Get the last featured results from the api
            api.getFeaturedResults().then((featuredResults) => {
                
                let featuredResultsAsJson = JSON.parse(featuredResults);
                                    // Create a new field with the price calculated
                 let   realDataArray = featuredResultsAsJson.map((element) => {
                        element.precio_calculado = utils.getPriceFromProperty(element);
                        return element;
                    });

                setFeaturedResults(realDataArray);

                console.log ('featuredResults: ', realDataArray);
            }).catch((error) => {
                console.error('Error getting featured results: ', error);
            });
        }
                
        // Enable history buttons interceptor. This is needed to intercept the browser history buttons.
        window.addEventListener('popstate', (event) => processBackAndForwardButtons(event, 'back'));
        window.addEventListener('pushstate', (event) => processBackAndForwardButtons(event, 'forward'));



        utils.log('2. Checking if selected property...', 'MAIN_END', new Date().toISOString());

        // Check for codOffer parameter in the url and search only for codOffer
        const urlParams = new URLSearchParams(window.location.search);
        const codOfferParam = urlParams.get('codOffer');
        if (codOfferParam !== null) {
            console.log('codOfferParam: ', codOfferParam);
            setCodOffer(codOfferParam);
        }

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, selected, paginationInfo, selectedTab, selectedSection);

        // TODO fix esto
        // Remove dependency warning
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);

    // Recover selected property if selected parameter is present in the url
    useEffect (() => {

        if (codOffer !== null) {
            console.log('3. Searching for selected property...');
            searchData();
        }

    }, [codOffer]);
    

    // Make a search
    useEffect (() => {
        // console.log (isSearching, results)
        if (isSearching === true && results === null) {

            console.log('3. Searching...');
            searchData();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearching, results]);

    // Update search results when resultsPerPage changes
    useEffect (() => {
        if (results !== null) {
            
            cleanSearch();
            setIsSearching(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultsPerPage]);
  
    // Sort search
    useEffect (() => {
        // console.log (isSearching, results)
        if (isSearching === false && results !== null) {

            updateSearchInfo(paginationInfo, results);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchSortBy, searchSortByDirection]);
 
    // Finish with the refreshing of the section (GUARRERÍA MÁXIMA)
    useEffect (() => {

        // console.log('4. Refreshing section data...', selectedSection);
        
        // If selected section starts with 'refresh-', then it's a refresh
        if (selectedSection && selectedSection.startsWith('refresh-')) {
            // console.log('4. Stop refreshing...');
            // Stop refreshing

            // Take the end part of the string (the section name)
            const section = selectedSection.split('-')[1];
            // console.log('section: ', section);

            // Stop refreshing
            setSelectedSection(section);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [selectedSection]);

    // Updating the language when it changes
    useEffect (() => {
        
        // console.log('5. Updating language...', lang);
        i18n.changeLanguage(lang);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]); 

    // Get details of the property when selected
    useEffect (() => {

        if (selected && !selected.fotos) {

            console.log('6. Getting details of the property...', selected.cod_ofer);

            // Get the details of the property
            api.getPropertyDetails(selected).then( propertyDetails => {
                updateSelectedOffer(propertyDetails);
            }).catch(utils.showError);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    // 
    // -----------------------------------
    //            2. Cookie-Setters
    // -----------------------------------
    const setThemeInCookie = (theme) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('theme', theme, { path: '/' });

        setTheme(theme);
    }
    const setSelectedTabInCookie = (selectedTab) => {

        // Set the current tab in the cookies
        const { cookies } = props;
        cookies.set('selectedTab', selectedTab, { path: '/' });

        setSelectedTab(selectedTab);
    }
    const setSelectedOperationsInCookie = (selectedOperations) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedOperations', selectedOperations, { path: '/' });

        setOperationsArray(selectedOperations);
    }
    const setSelectedTypesInCookie = (selectedTypes) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedTypes', selectedTypes, { path: '/' });

        setTypesArray(selectedTypes);
    }
    const setSelectedRoomsNumbersInCookie = (selectedRoomsNumbers) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedRoomsNumbers', selectedRoomsNumbers, { path: '/' });

        setRoomsNumbersArray(selectedRoomsNumbers);
    }
    const setSelectedPlacesInCookie = (selectedPlaces) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedPlaces', selectedPlaces, { path: '/' });

        setPlacesArray(selectedPlaces);
    }
    const setRangePriceInCookie = (rangePrice) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedRangePrices', rangePrice, { path: '/' });
        
        setRangePrice(rangePrice);
    }
    const setSearchSortByInCookie = (searchSortBy) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedSearchSortBy', searchSortBy, { path: '/' });
        
        setSearchSortBy(searchSortBy);
    }
    const setSearchSortByDirectionInCookie = (searchSortByDirection) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedSearchSortByDirection', searchSortByDirection, { path: '/' });

        setSearchSortByDirection(searchSortByDirection);


    }
    const setLanguageInCookie = (lang) => {

        // Set the theme in the cookies
        const { cookies } = props;
        cookies.set('selectedLang', lang, { path: '/' });

        setLang(lang);
    }

    // -----------------------------------
    //            2. Filters
    // -----------------------------------
    const cleanFilters = () => {
        // Clean all filters
        // console.log('4. Cleaning Filters...');
        setSelectedOperationsInCookie([]);
        setSelectedTypesInCookie([]);
        setSelectedRoomsNumbersInCookie([]);
        setSelectedPlacesInCookie([]);
        setRangePriceInCookie([0, Constants.rangePrice.max + 1]);
    }
    const cleanFiltersAndSearch = () => {

        cleanFilters();
        cleanSearch();
    }
    const areFiltersEmtpy = () => {
        if( operationsArray.length === 0 && 
            typesArray.length === 0 && 
            roomsNumbersArray.length === 0 && 
            placesArray.length === 0 
            && rangePrice[0] === 0 && 
            rangePrice[1] === Constants.rangePrice.max + 1) {
            return true;
        } else {
            return false;
        }
    }
    const isSearchTextEmpty = () => {
            
        if (textSearch === '') {
            return true;
        } else {
            return false;
        }
    }
    const initializeSomeFilters = () => {

        cleanSearch();

        setOperationsArray(['Alquilar', 'Comprar']);
        setTypesArray(['Casa', 'Dúplex']);
        setRoomsNumbersArray(['2', '3']);
        setPlacesArray (['Arrecife']);
        setRangePrice([50000, Constants.rangePrice.max]);
        
        // Start searching
        setIsSearching(true);
    }

    // -----------------------------------
    //            3. Search
    // -----------------------------------
    const cleanSearch = () => {

        setIsSearching(false);
        setResults(null);
        setSelected(null);
        setSelectedSection(null);
        setCodOffer(null);
        // console.log('5. Cleaning Search...');
    }
    const getSetSortByField =  (searchSortByField) => {
        let setSortByField = 'Relevancia';
        if (searchSortByField === 'Relevancia') {
            setSortByField = 'repercusion';
        } else if (searchSortByField === 'Habitaciones') {
            setSortByField = 'total_hab';
        } else if (searchSortByField === 'Precio') {
            setSortByField = 'precioinmo, precioalq';
        }
        return setSortByField;
    }
    const searchData = () => {

        // Generate inmovilla filters using our filters
        const orderBy = getSetSortByField(searchSortBy);
        const sortBy = searchSortByDirection === 'Ascendente' ? 'asc' : 'desc';
        const inmovillaFilters = utils.getInmoVillaFilters (operationsArray, typesArray, roomsNumbersArray, placesArray, rangePrice, textSearch, codOffer, currentPage, resultsPerPage, orderBy, sortBy);
        

        console.log('6. Searching...');        
        // debugger;
        // Get the data from the API
        api.getData(inmovillaFilters).then( data => {
            // ;
            // Set the loading to false
            setIsSearching(false);
            // Set the data in the state
            let newData = JSON.parse(data);
            // newData = null;
            // If data, order the results using the filters 
            if (newData !== null) {

                let realDataArray = newData.paginacion;

                // Remove first element from the array (it's the summary)
                let paginationInfo = {
                    "posicion": 0,
                    "elementos": 0,
                    "total": 0
                };

                // If there are results, get the pagination info, and shift the array
                if (realDataArray) {
                    paginationInfo = realDataArray.shift();
                    // Assign a numeric id to each element in the array
                    realDataArray = realDataArray.map((element, index) => {
                        element.id = index;
                        return element;
                    });

                    // Create a new field with the price calculated
                    realDataArray = realDataArray.map((element) => {
                        element.precio_calculado = utils.getPriceFromProperty(element);
                        return element;
                    });


                    // If we did a search by text, remove the results that don't have the text in the title
                    if (textSearch !== '') {
                        realDataArray = realDataArray.filter( result => {
                            let textSearchCleant = textSearch.toLowerCase().trim();
                            let zoneCleant = result.zona.toLowerCase().trim();

                            // Remove accents 
                            textSearchCleant = utils.removeAccents(textSearchCleant);
                            zoneCleant = utils.removeAccents(zoneCleant);

                            return zoneCleant.includes(textSearchCleant);
                        });
                        // We update the pagination info with the updated results
                        paginationInfo.elementos = realDataArray.length > resultsPerPage ? resultsPerPage : realDataArray.length;
                        paginationInfo.total = realDataArray.length;
                        
                    }

                    // Set the results in the state
                    updateSearchInfo(paginationInfo, realDataArray);
                } else {
                    updateSearchInfo(paginationInfo,[]);
                }

                // If there's only one result and the codOffer is set, select the property and go to the detail
                if (realDataArray.length === 1 && codOffer !== null) {
                    
                    const house = realDataArray[0];
                    const targetPath = 'search-simple';
                    const section  = 'title';
                    
                    // Set the selected property and the selected tab in the state
                    setSelected(house);
                    setIsMapFullScreen(false);
                    setSelectedTab(targetPath);
                    setSelectedSection(section);
            
                    // Store the current result into the history of results
                    storeCurrentResultInHistory(results, house, paginationInfo, targetPath, section);
                }

            } else {
                updateSearchInfo(paginationInfo,[]);
                // console.log('6. No results from Search... %o', results);
            }

        }).catch(utils.showError);
    }
    const updateSearchInfo = (paginationInfo, results) => {

        // Filter the results with the filters
        // let filteredResults = utils.filterResults (results, operationsArray, typesArray, roomsNumbersArray, placesArray, rangePrice);
        let filteredResults = results;

        // Sort the results with the filters
        // const IS_ASC = searchSortByDirection === 'asc';
        // const setSortByFieldName = getSetSortByField(searchSortBy);

        // filteredResults = utils.sortResults(filteredResults, setSortByFieldName, IS_ASC);

        // Log the results
        // console.log('6. Results from Search... %o', filteredResults);

        // Set the results in the state
        setResults(filteredResults);

        // Set the pagination info in the state
        setPaginationInfo(paginationInfo);

        // Store the current result into the history of results
        storeCurrentResultInHistory(filteredResults, selected, paginationInfo, selectedTab, selectedSection);

    }
    const updateSelectedOffer = (propertyDetails) => {

        // Convert to JSON
        const propertyDetailsAsJson = JSON.parse(propertyDetails);
        console.log('7. Updating selected offer...', propertyDetailsAsJson);

        // Look for that property in the results and update the new info
        const newResults = results.map( (result) => {
            if (result.cod_ofer === propertyDetailsAsJson.ficha[1].cod_ofer) {
                return {...result, ...propertyDetailsAsJson};
            } else {
                return result;
            }
        });

        // Update the results
        setResults(newResults);

        // Update the selected
        const mergedProperty = {...selected, ...propertyDetailsAsJson};
        setSelected(mergedProperty);
        
    }

    // -----------------------------------
    //          4. State history
    // -----------------------------------
    const processBackAndForwardButtons = (event, who) => {
        // console.log('8. Processing back and forward buttons...', event);
        
        // Check if the user is going back or forward
        if (event.state !== null) {
            
            // Get the data from the history
            const historyData = event.state;
            
            // Update the state with the data from the history
            setResults(historyData.results);
            setPaginationInfo(historyData.paginationInfo);
            setSelected(historyData.selected);
            setSelectedSection(historyData.selectedSection);
            setSelectedTab(historyData.selectedTab);
            
        }
    }
    const storeCurrentResultInHistory = (results, selected, paginationInfo, selectedTab, selectedSection) => {

        // Save the results in the undo/redo array
        const counter = getCounterFromCurrentHistory();

        const stateElement = {counter: counter + 1, results, selected, paginationInfo, selectedTab, selectedSection};
        // console.log ('stateElement: ', stateElement);

        // Include all parameters in the URL
        const orderBy = getSetSortByField(searchSortBy);
        const sortBy = searchSortByDirection === 'Ascendente' ? 'asc' : 'desc';
        const inmovillaFilters = utils.getInmoVillaFilters (operationsArray, typesArray, roomsNumbersArray, placesArray, rangePrice, textSearch, codOffer, currentPage, resultsPerPage, orderBy, sortBy);

        const urlParams = utils.getURLParamsFromFilters(inmovillaFilters);
        // console.log(urlParams);
        
        // Construct url for search
        const domainUrl = window.location.origin;
        let targetURL = results ? domainUrl + urlParams : domainUrl;

        // Construct the url for details
        if (selected) {
            
            // Get the lang section
            const langSectionUrl = lang === 'es' ? '' : '/'+ lang + '/';

            targetURL = domainUrl + langSectionUrl + '?codOffer=' + selected.cod_ofer;
        }

        // Store the section in the history
        window.history.pushState(stateElement, '', targetURL);
    }
    const getCounterFromCurrentHistory = () => {
        const stateElement = window.history.state;
        if (stateElement) {
            return isNaN (stateElement.counter) ? 0 : stateElement.counter;
        }
        return 0;
    }
    
    // -----------------------------------
    //          5. Handlers
    // -----------------------------------
    const handleNotSelected = () => {
        // console.log('handleNotSelected');
        setSelected(null);
        setSelectedSection(null);
        setSelectedTab ('search-simple')

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, null, paginationInfo, selectedTab, null);
    }
    const handleChangeRangePrices = (event, newValue, activeThumb) => {

        const minDistance = Constants.rangePrice.max;
        if (!Array.isArray(newValue)) {
          return;
        }
    
        if (activeThumb === 0) {
          setRangePriceInCookie([Math.min(newValue[0], rangePrice[1] - minDistance), rangePrice[1]]);
        } else {
            setRangePriceInCookie([rangePrice[0], Math.max(newValue[1], rangePrice[0] + minDistance)]);
        }
    };    
    const handleChangeOperations = (event) => {
        const {
          target: { value },
        } = event;
        const valueAsArray = typeof value === 'string' ? value.split(',') : value;
        setSelectedOperationsInCookie(valueAsArray.sort());
    };
    const handleChangeTypes = (event) => {
        const {
        target: { value },
        } = event;
        const valueAsArray = typeof value === 'string' ? value.split(',') : value;
        setSelectedTypesInCookie(valueAsArray.sort());
    };
    const handleChangeRoomsNumber = (event) => {
        const {
        target: { value },
        } = event;
        const valueAsArray = typeof value === 'string' ? value.split(',') : value;
        setSelectedRoomsNumbersInCookie(valueAsArray.sort());
    };
    const handleChangePlaces = (event) => {
        const {
            target: { value },
        } = event;
        const valueAsArray = typeof value === 'string' ? value.split(',') : value;
        setSelectedPlacesInCookie(valueAsArray.sort());
    };
    const handleSearchButtonClick = () => {
        
        // console.log('1. Search Button Clicked...');
 
        setSelectedTab('search-simple');

        // If results are empty, search
        // if (results === null) { 
            cleanSearch();
            setIsSearching (true);
        // }
        // search();
    }
    const handleSearchMapButtonClick = () => {
        
        // console.log('1. Search In map Button Clicked...');
 
        setSelectedTab('search-map');

        // If results are empty, search
        if (results === null) { 
            cleanSearch();
            setIsSearching (true);
        }
    }
    const handleShowElementInMap = (event) => {

        // console.log('1. Show In Map Button Clicked...');
        
        // Get the id of the house
        const id = event.currentTarget.getAttribute('itemid');
        const idNumber = Number(id);

        // Get the house from the results
        const house = results.find(house => house.id === idNumber);

        // ;
        // Set the selected house
        setSelected(house);
        setSelectedSection(null);
        setIsMapFullScreen(true);
        
        // Set the tab to map
        setSelectedTab('search-map');

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, house, paginationInfo, 'search-map', null);


    }
    const handleLanguageChange = (event) => {
        const {
            target: { value },
        } = event;
        setLanguageInCookie(value);
    };
    const handleNextSelected = () => {
        // ;
        // console.log('2. Next Button Clicked...');

        // Get the id of the house
        let id = null;
        if (selected) {
            id = selected.id;
        } else {
            // Take the first element in the results array
            id = results[0].id;
        }

        // Get the index of the house in the results
        const index = results.findIndex(house => house.id === id);

        // Get the next house
        const nextHouse = results[index + 1];

        // Check if last house
        if (nextHouse === undefined) {
            // ;
            // Set the first house
            setSelected(results[0]);
        } else {
            // ;
            // Set the next house
            setSelected(nextHouse);
        }
        
        setSelectedSection(null);

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, nextHouse, paginationInfo, selectedTab, null);

    }
    const handlePreviousSelected = () => {
        // ;
        // console.log('2. Previous Button Clicked...');

        // Get the id of the house
        const id = selected.id;
        
        // Get the index of the house in the results
        const index = results.findIndex(house => house.id === id);
        
        // Get the previous house
        const previousHouse = results[index - 1];

        // Check if first house
        if (previousHouse === undefined) {
            // ;
            // Set the last house
            setSelected(results[results.length - 1]);
        } else {
            // ;
            // Set the previous house
            setSelected(previousHouse);
        }

        setSelectedSection(null);

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, previousHouse, paginationInfo, selectedTab, null);

    
    }
    const handleFirstSelected = () => {
        // ;
        // console.log('2. First Button Clicked...');
        setSelected(results[0]);
        setSelectedSection(null);

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, results[0], paginationInfo, selectedTab, null);
    }
    const handleLastSelected = () => {
        // ;
        // console.log('2. Last Button Clicked...');
        setSelected(results[results.length - 1]);
        setSelectedSection(null);

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, results[results.length - 1], paginationInfo, selectedTab, null);


    }
    const handleSetSelectedAndOpenPhotos = (event) => {
        
        // console.log('4. Photos Button Clicked...');
        handleSetSelectedAndOpenDetail(event, 'photos');

    }
    const handleSetSelectedAndOpenVideo = (event) => {

        // console.log('5. Video Button Clicked...');
        handleSetSelectedAndOpenDetail(event,  'video');

    }
    const handleSetSelectedAndOpenDescription = (event) => {

        // console.log('6. Description Button Clicked...');
        handleSetSelectedAndOpenDetail(event, 'description');
    }
    const handleSetSelectedAndOpenLocation = (event) => {

        // console.log('6. Description Button Clicked...');
        handleSetSelectedAndOpenDetail(event, 'location');
    }
    const handleSetSelectedAndOpenDetail = (event, section) => {
        // Get the id of the house
        const id = event.currentTarget.getAttribute('itemid');

        // If there's no id, then we get the cod_ofer from the event
        if (id === null) {
            const cod_ofer = event.currentTarget.getAttribute('data-cod-ofer');
            setCodOffer(cod_ofer);
            return;
        }
            
        const idNumber = Number(id);

        // Get the house from the results
        const house = results.find(house => house.id === idNumber);

        // ;
        // Set the selected house
        setSelected(house);
        // setSelectedSection(null);


        // Looking the target path in function of the current tab
        let targetPath = 'search-simple';
        // if (selectedTab === 'search-simple' && selected !== null) {
        //     targetPath = 'search-map';
        // }
        // if (selectedTab === 'search-map' && selected !== null) {
        //     targetPath = 'search-simple';
        // }
        // if (selectedTab === 'search-simple' && selected === null) {
        //     targetPath = 'search-simple';
        // }
        setIsMapFullScreen(false);
        setSelectedTab(targetPath);

        // Hacemos esto para volver a relanzar este efecto (que se ejecuta al cambiar el selectedSection). problema de estados aquí :S:S
        // Se combina con su propio efecto para que se ejecute siempre que cambie el selectedSection (useEffect)
        if (section !== null) {
            if (section === selectedSection) {
                console.log('Refresh the section: ' + section);
                setSelectedSection('refresh-' + section);
            } else {
                setSelectedSection(section);
            }
        }

        // Store the current result into the history of results
        storeCurrentResultInHistory(results, house, paginationInfo, targetPath, section);

    }
    const handleDeleteEntryFromSearch = (event) => {

        // Get the id of the house
        const id = event.currentTarget.getAttribute('itemid');
        const idNumber = Number(id);
        
        // Set the selected house

        // Delete the house from the results
        const newResults = results.filter(house => house.id !== idNumber);
        if (selected !== null) {
            // Get the next house from the results
            const nextHouse = results.find(house => house.id === idNumber + 1);
            setSelected(nextHouse);
        } else {
            setSelected (null);
        }
        setSelectedSection(null);

        // Set the new results
        updateSearchInfo(paginationInfo, newResults);
        
    }
    const toggleAuth = () => {
        setIsAuthenticated(!isAuthenticated);
    }
    const handleSetPage = (currentPage) => {
        cleanSearch();
        setCurrentPage(currentPage);
        setIsSearching (true);
    };
    const toggleIsSearching = () => {
        
        setIsSearching(!isSearching);
    }
    // -----------------------------------
    //          3. Renders
    // -----------------------------------
    // 
    return (
            <AuthContext.Provider value={{
                isAuthenticated: isAuthenticated,
                // setIsAuthenticated: setIsAuthenticated,
                toggleAuth: toggleAuth,
            }}> 
                <ErrorBoundary key="error-boundary">

                    {false && <CazaTuCasaCarousel
                        // App
                        t={t}
                        lang={lang}
                        loading={loading}
                        theme={theme}
                        selectedTab={selectedTab}
                        
                        // Results
                        featuredResults={featuredResults}

                        handleSetSelectedAndOpenDetail={handleSetSelectedAndOpenDetail}
                        handleSetSelectedAndOpenPhotos={handleSetSelectedAndOpenPhotos}
                        handleSetSelectedAndOpenVideo={handleSetSelectedAndOpenVideo}
                        handleSetSelectedAndOpenDescription={handleSetSelectedAndOpenDescription}
                        handleSetSelectedAndOpenLocation={handleSetSelectedAndOpenLocation}
                        handleDeleteEntryFromSearch = {handleDeleteEntryFromSearch}
                    />}

{true && <CazatuCasaSearchEngine
                        // App
                        t={t}
                        lang={lang}
                        mode={mode}
                        loading={loading}
                        theme={theme}
                        isFullScreen={isFullScreen}
                        selectedTab={selectedTab}
                        setIsFullScreen={setIsFullScreen}
                        setSelectedTab={setSelectedTabInCookie}
                        setTheme={setThemeInCookie}
                        cookies={cookies}
                        isMapFullScreen={isMapFullScreen}
                        setIsMapFullScreen={setIsMapFullScreen}
                        setLang={setLanguageInCookie}

                        // Handlers
                        handleChangeOperations={handleChangeOperations}
                        handleChangePlaces={handleChangePlaces}
                        handleChangeTypes={handleChangeTypes}
                        handleChangeRoomsNumber={handleChangeRoomsNumber}
                        handleChangeRangePrices={handleChangeRangePrices}
                        handleSearchButtonClick={handleSearchButtonClick}
                        handleSearchMapButtonClick={handleSearchMapButtonClick}
                        handleShowElementInMap = {handleShowElementInMap}
                        handleLanguageChange={handleLanguageChange}
                        handleSetPage={handleSetPage}
                        toggleIsSearching={toggleIsSearching}

                        // Filters
                        operationsArray={operationsArray}
                        typesArray={typesArray}
                        roomsNumbersArray={roomsNumbersArray}
                        placesArray={placesArray}
                        rangePrice={rangePrice}
                        setRangePrice={setRangePriceInCookie}                    
                        initializeSomeFilters={initializeSomeFilters}
                        areFiltersEmtpy={areFiltersEmtpy}                
                        isSearchTextEmpty={isSearchTextEmpty}

                        // Search
                        textSearch={textSearch}
                        codOffer={codOffer}
                        setTextSearch={setTextSearch}
                        isSearching={isSearching}
                        setIsSearching={setIsSearching}

                        // Pagination
                        setResultsPerPageInCookie={setResultsPerPageInCookie}
                        resultsPerPage={resultsPerPage}
                        setResultsPerPage={setResultsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        paginationInfo={paginationInfo}

                        // Clean Search & Filters
                        cleanFilters={cleanFilters}
                        cleanSearch={cleanSearch}
                        cleanFiltersAndSearch={cleanFiltersAndSearch}
                        
                        // Results
                        results={results}
                        setResults={setResults}
                        searchSortBy={searchSortBy}
                        setSearchSortBy={setSearchSortByInCookie}
                        searchSortByDirection={searchSortByDirection}
                        setSearchSortByDirection={setSearchSortByDirectionInCookie}
                        setFeaturedResults={setFeaturedResults}
                        setTotalPages={setTotalPages}

                        // Detail
                        setSelected={setSelected}
                        selected={selected}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        handleNotSelected={handleNotSelected}

                        // Search Buttons
                        handleNextSelected={handleNextSelected}
                        handlePreviousSelected={handlePreviousSelected}
                        handleFirstSelected={handleFirstSelected}
                        handleLastSelected={handleLastSelected}
                        handleSetSelectedAndOpenDetail={handleSetSelectedAndOpenDetail}
                        handleSetSelectedAndOpenPhotos={handleSetSelectedAndOpenPhotos}
                        handleSetSelectedAndOpenVideo={handleSetSelectedAndOpenVideo}
                        handleSetSelectedAndOpenDescription={handleSetSelectedAndOpenDescription}
                        handleSetSelectedAndOpenLocation={handleSetSelectedAndOpenLocation}
                        handleDeleteEntryFromSearch = {handleDeleteEntryFromSearch}
                    />}



                </ErrorBoundary>
            </AuthContext.Provider>
    );
}

export default withCookies(App);
