// Theming
import { Button } from 'react-bulma-components';
import * as React from 'react';
import { FirstPageTwoTone, LastPageTwoTone, ChevronLeftTwoTone, ChevronRightTwoTone, RoofingTwoTone } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import ReactTooltip from 'react-tooltip';
  
export default function SummaryNavigator (props) {

    // Rebuild tooltips
    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    const renderPageResultsTextNoSelected = () => {
        
        // Calculate the start and the end

        
        let start = Number ((props.currentPage-1) * (props.results.length - 1)) + 1;
        let end = Number (start + props.results.length - 1);
        if (end > (props.paginationInfo.total-1)) {
            end = (props.paginationInfo.total);
        }

        // debugger;
        return (
            <div className="page-results-text">
                <RoofingTwoTone />
                <span className="subtitle has-text">
                    {start} - {end}{props.t("searchEngine.label.of")}<b>{(props.paginationInfo.total)}</b> {props.t("searchEngine.label.resultados")}
                </span>
            </div>
        );
    };


    let isNextDisabled = props.currentPage >= Math.ceil((props.paginationInfo.total-1) / props.results.length - 1);
    let isLastDisabled = props.currentPage >= Math.ceil((props.paginationInfo.total-1) / props.results.length - 1);
    let isFirstDisabled = props.currentPage === 1;
    let isPreviousDisabled = props.currentPage <= 1;
   return (
            <div className="page-results-buttons">
                <div className="mobile-page-results-page">
                {renderPageResultsTextNoSelected()}
                </div>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button
                        className="page-results-button"
                        onClick={() => props.handleSetPage(1)}
                        disabled={isFirstDisabled}
                    >
                        <FirstPageTwoTone />
                    </Button>
                    <Button
                        className="page-results-button"
                        onClick={() => props.handleSetPage(props.currentPage - 1)}
                        disabled={isPreviousDisabled}
                    >
                        <ChevronLeftTwoTone />
                    </Button>
                    {renderPageResultsTextNoSelected()}
                    <Button
                        className="page-results-button"
                        onClick={() => props.handleSetPage(props.currentPage + 1)}
                        disabled={isNextDisabled}
                    >
                        <ChevronRightTwoTone />
                    </Button>
                    <Button

                        className="page-results-button"
                        onClick={() => props.handleSetPage(Math.max(0, Math.ceil((props.paginationInfo.total-1) / props.results.length - 1)))}
                        disabled={isLastDisabled}
                    >
                        <LastPageTwoTone />
                    </Button>
                </ButtonGroup>
            </div>
    )
}
