export const translation = {
    navbar: {
        label: {
            reactVersion: 'Versión de React',
            lang: {
                language: 'Idioma',
                es: 'Español',
                en: 'Inglés',
                fr: 'Francés',
                de: 'Alemán'
            },
            undoRedo: {
                step: 'Paso',
                undo: 'Deshacer',
                redo: 'Rehacer',
            },
        }
    },
    searchEngine: {  
        label: {

            // Search Engine Labels
            // searchBoxPlaceholder: 'Wohnung zur Miete in Playa Blanca, Teguise...',
            searchBoxPlaceholder: 'Playa Blanca, Teguise...',
            searchBoxSlogan: 'Sie sind nur einen Klick davon entfernt, <1>das Haus Ihrer Träume</1> zu finden.',
            searchBoxButton: 'Suche',
            searchMapButton: 'Karte',
            searchMapButtonTooltip: 'Auf der Karte anzeigen',
            searchMapButtonTooltipClose: 'Karte schließen',
            searchMapButtonTooltipOpen: 'Karte öffnen',
            priceRangeLabel: 'Preis',
            cleanFiltersLink: 'Saubere Filter',
            cleanFiltersMessage: 'Versuchen Sie, die Filter zu reinigen und erneut zu suchen',
            cleanFiltersMessageLink: 'Filter reinigen und starten',

            // Search Engine Results
            noResults: 'Keine Ergebnisse gefunden',
            dormitorio: 'Schlafzimmer',
            dormitorios: 'Schlafzimmer',
            resultado: 'Ergebnis',
            resultados: 'Ergebnisse',
            verEnElMapa: 'Ansicht auf Karte',
            ordenarPor: 'Sortieren nach',
            precio: 'Preis',
            relevancia: 'Relevanz',
            habitaciones: 'Zimmer',
            price: 'Preis',
            baños: 'Bäder',
            superficie: 'Oberfläche',
            from: 'Von',
            to: 'An',
            of: ' von ',
            sale: 'zu verkaufen',
            rent: 'zu vermieten',
            on: ' unter ',
            in: ' unter ',
            for: ' von ',
            map: ' Karte',
            viewLocation: 'Standort',
            yes: 'Ja',
            no: 'Nein',
            noLimit: 'Keine Grenze',
            code: 'Code',
            ref: 'Referenz',

            // Detail of the property
            photo: 'Foto',
            photos: 'Fotos',
            video: 'Video',
            videos: 'Videos',
            noVideos: 'Es sind keine Videos für diese Immobilie verfügbar.',
            features: 'Eigenschaften',
            description: 'Beschreibung',
            basicFeatures: 'Grundlegende Merkmale',
            location: 'Standort',
            equipment: 'Ausrüstung',
            offerInfo: 'Informationen zum Angebot',
            energeticCertification: 'Energieausweis',

            // Actions
            actions: 'Aktionen', 
            callAgent: 'Rufen Sie den Agenten an',
            deleteFromSearch: 'Aus der Suche entfernen',
            agent: 'Agent',
            seeProfile: 'Profil ansehen',
            sendMessage: 'Nachricht senden',

            // Description of the property
            thisBuildingText: 'Dieses Gebäude',
            isInText: 'befindet sich in',
            andHasText: 'und hat',
            roomsText: 'Zimmer',
            bathroomsText: 'Badezimmer',
            roomText: 'Zimmer',
            bathroomText: 'Badezimmer',
            sizeText: 'mit einer Gesamtfläche von',
            priceText: 'und einem Preis von ',
            roomTextCompressed: 'zimmer',
            goBackToSearch: 'Zurück zur Suche',
            seeVideo: 'Siehe Video',
            seePhotos: 'Siehe Fotos',

            // Map 
            fullScreenMap: 'Vollbild',
            exitFullScreenMap: 'Vollbild verlassen',
            
            // Actions
            seePhone: 'Ansicht Telefon', 
            contact: 'Kontakt',
            delete: 'entfernen', 
            
            // Footer Toolbar
            show: 'anzeigen', 
            resultsPerPageText: 'Ergebnisse pro Seite',
            gotoTopText: 'Zurück zum Anfang',

            // Tour Virtual
            tourVirtual: 'Virtueller Rundgang',
            noTourVirtual: 'Es ist kein virtueller Rundgang für diese Immobilie verfügbar.',
            seeTourVirtual: 'Siehe virtuellen Rundgang',
            
        },
        filters: {
            operations: {
                placeholder: 'Wonach suchen Sie?',
                options: {
                    alquilar: 'Miete',
                    comprar: 'Kaufen',
                    traspasar: 'Übertragung',
                }
            },
            locations: {
                placeholder: 'Standort',
                options: {
                    arrecife: 'Arrecife',
                    haria: 'Haría',
                    tias: 'Tías',
                    sanBartolome: 'San Bartolomé',
                    teguise: 'Teguise',
                    tinajo: 'Tinajo',
                    yaiza: 'Yaiza',
                }
            },
            types: {
                placeholder: 'Eigentum',
                options: {
                    apartamento: 'Wohnung',
                    ático: 'Penthouse',
                    atico: 'Penthouse',
                    casa: 'Haus',
                    chalet: 'Chalet',
                    duplex: 'Duplex',
                    dúplex: 'Duplex',
                    localComercial: 'Gewerbliche Räume',
                    "local comercial": 'Gewerbliche Räume',
                    piso: 'Wohnung',
                    restaurante: 'Restaurant',
                    solar: 'Grundstücke',
                    terrenoUrbano: 'Urban Plot',
                    "terreno urbano": 'Urban Plot',
                    villa: 'Villa',
                    parcela: 'Grundstücke',

                },
            },
            roomsNumber: {
                placeholder: 'Anzahl von Schlafzimmern',
                options: {
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    masDe5: 'Mehr als 5'
                }
            },
            sortBy: {
                placeholder: 'Sortieren nach',
                options: {
                    precio: 'Preis',
                    relevancia: 'Relevanz', 
                    habitaciones: 'Zimmer',
                    metros: 'Oberfläche (m²)',
                }
            }    
        },
        features: {
            fechacambio: 'Zuletzt Ändern Sie',
            agencia: 'Agentur',
            cod_ofer: 'Angebotscode',
            ref: 'Referenz',
            vistasalmar: 'Blick aufs Meer',
            energiarecibido: 'Erhaltene Energie',
            vistasdespejadas: 'Klare Ansichten',
            tipomensual: 'Monatliche Rate',
            tipomensual_options: {
                'MES': 'Monat',
                'TRIMESTRE': 'Trimester',
                'SEMESTRE': 'Semester',
                'AÑO': 'Jahr',
            },
            price: 'Preis',
            habitaciones: 'Bedr. Einzeln',
            habdobles: 'Zimmer. Doppelter',
            bathrooms: 'Bäder',
            size: 'Fläche',
            city: 'Stadt',
            operation: 'Operation',
            type: 'Typ',
            place: 'Zone',
            latitude: 'Breitengrad',
            longitude: 'Längengrad',
            m_parcela: 'M. Grundstücke',
            balcon: 'Balkon',
            m_cons: 'M. Gebaut',
            m_uties: 'M. Verwendbar',
            conservacion: 'Konservierung',
            calefacentral: 'Zentralheizung',
            airecentral: 'Zentrale Klimatisierung',
            plaza_gara: 'Garagenplatz',
            terraza: 'Terrasse',
            ascensor: 'Aufzug',
            montacargas: 'Aufzug',
            muebles: 'Möbel',
            calefaccion: 'Heizung',
            aire_con: 'Eigene A/C',
            primera_line: 'Frontlinie',
            piscina_com: 'Schwimmbad',
            piscina_prop: 'Eigener Pool',
            total_hab: 'Total Zimmer',
            sumaseos: 'Total Badezimmer',
            parking: 'Parken',
            todoext: 'Alle Exterieur',
            distmar: 'Entfernung zum Meer',
            ciudad: 'Stadt',
            zona: 'Zone',
            nbtipo: 'Typ',
            nbconservacion: 'Status',
            nbconservacion_options: {
                "Para reformar": "Zu renovieren",
                "De origen": "Ursprünglich",
                "Reformar Parcialmente": "Teilweise renoviert",
                "Entrar a vivir": "Einziehen",
                "Buen estado": "Guter Zustand",
                "Semi Reformado": "Teilweise renoviert",
                "Reformado": "Renoviert",
                "Semi nuevo": "Semi neu",
                "Nuevo": "Neu",
                "Obra Nueva": "Neubau",
                "En construcción": "In Bau",
                "En proyecto": "In Projekt",
                "Ninguno": "Keine",
            },
            precio_calculado: 'Preis',
            mascotas: 'Haustiere',
            aconsultar: 'Auf Anfrage',
            outlet: 'Auslass', 
            aseos: 'Toiletten',
            m_terraza: 'M. Terrasse',
            keycalefa: 'Heizung',
            keycalefa_options: {
                1: "Gas",
                2: "Öl",
                3: "Elektrizität",
                4: "Pellets",
                5: "Solar",
                6: "Biomasse",
                7: "Andere",
                8: "Nein",
            },            
            eninternet: 'Internet',
            zonaauxiliar: 'Hilfsbereich',
            urbanizacion: 'Urbanisierung',
        }     
    }
}