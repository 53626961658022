import { KitchenTwoTone, AcUnitTwoTone, BeachAccessTwoTone, PoolTwoTone, WbSunnyTwoTone, AttachMoneyTwoTone, PetsTwoTone, HelpTwoTone, LocalOfferTwoTone, WcTwoTone, SingleBedTwoTone, VisibilityTwoTone, EuroTwoTone, KingBedTwoTone, BathtubTwoTone, AspectRatioTwoTone, HomeTwoTone, AssignmentTwoTone, LocationOnTwoTone, MyLocationTwoTone, LocationCityTwoTone, BusinessCenterTwoTone, EuroSymbolTwoTone, Grid3x3TwoTone, BalconyTwoTone, ElevatorTwoTone, LocalParkingTwoTone, WaterDamageTwoTone, LocalFireDepartmentTwoTone, WifiTwoTone, CalendarViewMonthTwoTone } from '@mui/icons-material';
import { ConsoleGray, ConsoleGrayDS, ConsoleGrayJS, ConsoleGrayJSDS, ConsoleOrange, ConsoleOrangeDS, ConsoleOrangeJS, ConsoleOrangeJSDS } from 'colorize-console-log';
import EmojiObjectsTwoToneIcon from '@mui/icons-material/EmojiObjectsTwoTone';
import * as Constants from './constants';

import { red, orange, blue } from '@mui/material/colors';

// Log Functions
export function showError(err) {
  console.log(err);
}
export function errorLog(message) {
    log(message,'ORANGE');
}
export function log(message, kind, subMessage, emoji1, emoji2) {

    // Set defaults
    if(!kind) kind = 'REGULAR';
    if(!emoji1) emoji1 = '🐛';
    if(!emoji2) emoji2 = '🐛';
    let colorizedMessage = null;
    
    // Set special types
    if (kind === 'MAIN_START') {kind = 'GRAY'; emoji1 = '🔥'; emoji2 = '🔥';}
    if (kind === 'LOADING') {kind = 'ORANGEJS'; emoji1 = '⌛'; emoji2 = '⌛';}
    if (kind === 'MAIN_END') {kind = 'GRAYDS'; emoji1 = '🔥'; emoji2 = '🔥';}

    // Select the proper console
    switch(kind) {
        case '':
            colorizedMessage = ConsoleGray(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
        case 'ORANGE':
            colorizedMessage = ConsoleOrange(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
        case 'ORANGEJS':
            colorizedMessage = ConsoleOrangeJS(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
        case 'ORANGEDS':
            colorizedMessage = ConsoleOrangeDS(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
        case 'ORANGEJSDS':
            colorizedMessage = ConsoleOrangeJSDS(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;

        case 'GRAY':
            colorizedMessage = ConsoleGray(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
        case 'GRAYJS':
            colorizedMessage = ConsoleGrayJS(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
        case 'GRAYDS':
            colorizedMessage = ConsoleGrayDS(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
        case 'GRAYJSDS':
            colorizedMessage = ConsoleGrayJSDS(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
    
        default:
            colorizedMessage = ConsoleGray(emoji1 + ' ' + message + ' ' + emoji2, subMessage? subMessage: '');
            break;
    }
    console.log(...colorizedMessage);
}
  
// Aux Functions
export function base64Decode(encoded) {
    // console.log('encoded: ' + encoded);
    const b = new Buffer(encoded, 'base64');
    return b.toString();
}
export function capitalizeFirst(value) {
    if (value !== null) {
        const regex = /(\b[a-z](?!\s))/g;
        return value ? value.replace(regex, (v) => {
            return v.toUpperCase();
        }) : '';
    }
    return value;
}
export function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}
export function getWidth(el) {
  const rect = el.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height
  };
}
// Create a promise that resolves after "ms" Milliseconds
export function delay (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//
export function htmlToText(html) {
    var temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
}
export function isValidJson(jsonAsString) {
    let IS_VALID_JSON = true;
    try {
            JSON.parse(jsonAsString);
    } catch (e) {
        IS_VALID_JSON = false;
    }
    return IS_VALID_JSON;
}
// Get iso date withouth timeZone
export const getIsoDate = (date) => {

    if (date) {
        return new Date(date).toISOString().split('.')[0];    
    }
    return new Date().toISOString().split('.')[0];
}
export const convertToKFormat = (value) => {
    return value / 1000 + 'k';
}
export function wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }

export const formatAsMoney = (value) => {
    let formattedValue = value.toLocaleString('en-US', { style: 'currency', currency: 'EUR' });

    // Remove last 3 chars
    formattedValue = formattedValue.substring(0, formattedValue.length - 3);

    // Remove 1st char
    return formattedValue.substring(1, formattedValue.length);
}

export const sortResults = (array, prop, asc) => {
    console.log(' - Sort Results: ' + prop + ' - ' + asc);
    if (asc!==true) {
        return array.sort(function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        });
    } else {
        return array.sort(function(a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        });
    }
}

export const getPhoneLinkFromPhone = (element) => {

    let phone = element.telefono1agente.toString();
    
    // Return string if not valid or undefined
    if (!phone || phone.length < 9) {
        return phone;
    }
    // Remove all non numeric chars
    phone = phone.replace(/\D/g,'');

    // Remove 0 from the beginning
    if (phone.charAt(0) === '0') {
        phone = phone.substring(1, phone.length);
    }

    // Add 34
    phone = '34' + phone;

    // Generate html link
    const link = <a href='tel:{phone}' target={'_blank'} rel="noreferrer">{phone}</a>;

    // Return link
    return link;
}
export const getContactSubject = () => {
    return 'Contacto desde la web';
}
export const getContactBody = (element) => {
    const body = 'Hola Cazatucasa. Me gustaría contactar con vosotros acerca del inmueble con id \''+ element.cod_ofer +'\', ubicado en ' + element.ciudad + '...\n\nGracias.';
    // return 'Hola, me gustaría contactar con ' + element.nombreagente + ' ' + element.apellidosagente + ' (' + element.telefono1agente + ').';
    return body;
}
export const getEmailHref = (element) => {
    const subject = getContactSubject();
    const body = getContactBody(element);
    const email = element.emailagente;
    const href = 'mailto:' + email + '?subject=' + subject + '&body=' + body + '&html=true';
    return href;
}
export const getEmailLinkFromEmail = (element) => {
    // get the email
    const email = element.emailagente;
    
    // Return string if not valid or undefined
    if (!email || email.length < 5) {
        return email;
    }

    // Generate html link
    const mailLinkHref = getEmailHref(element);

    let link = <a href={mailLinkHref} target="_blank" rel="noreferrer">{email}</a>;

    // Return link
    return link;
}

export const renderArrayAsList = (t, type, elementsArray, asCommas) => {

    // If asCommas is true, then we will return a string with commas
    if (elementsArray) {
        if (asCommas) {

            const elementsArrayTranslated = elementsArray.map((element) => {
                return t("searchEngine.filters." + type + ".options." + camelize(element))
            });



            let elementsString = elementsArrayTranslated.join(', ');
            return <li key={elementsString} className="as-commas-item">{elementsString}</li>;

        }
        return elementsArray.map((value) => (
            <li key={value} className="is-size-7">
                {value}
            </li>
        ));
    }
}

export const getAgentPhone = (element) => {
    // get the phone
    const phone = element.telefono1agente;
    
    // Return string if not valid or undefined
    if (!phone || phone.length < 9) {
        return phone;
    }
    // Remove all non numeric chars
    const phoneClean = phone.toString().replace(/\D/g,'');

    // Remove 0 from the beginning
    if (phoneClean.charAt(0) === '0') {
        return phoneClean.substring(1, phoneClean.length);
    }

    // Return link
    return phoneClean;
}

export const filterResults = (results, operationsArray, typesArray, roomsNumbersArray, placesArray, rangePrice) => {

    let filteredResults = results;
    // debugger;
    // Filter by operation
    if (operationsArray && operationsArray.length > 0) {
        filteredResults = filteredResults.filter((result) => {
            const isRenting = result.precioalq > 0;
            const isSelling = result.precioinmo > 0 || result.precioreal > 0;

            // debugger;
            const isTraspassing = result.preciotraspaso > 0;

            
            if (operationsArray.includes('Alquilar') && isRenting) {
                return true;
            }

            if (operationsArray.includes('Comprar') && isSelling) {
                return true;
            }

            if (operationsArray.includes('Traspasar') && isTraspassing) {
                return true;
            }

            return false;
        });
    }
    // Filter by places
    if (placesArray.length > 0) {
        filteredResults = filteredResults.filter((result) => {
            return placesArray.includes(result.ciudad);
        });
    }
    // Filter by type
    if (typesArray.length > 0) {
        filteredResults = filteredResults.filter((result) => {
            return typesArray.includes(result.nbtipo);
        });
    }
    // Filter by rooms
    if (roomsNumbersArray.length > 0) {
        filteredResults = filteredResults.filter((result) => {
            return roomsNumbersArray.includes(result.total_hab);
        });
    }
    // Filter by price
    filteredResults = filteredResults.filter((result) => {
        const price = result.precio_calculado;
        return price >= rangePrice[0] && price <= rangePrice[1];
    });

    // Set the results in the state
    // debugger;
    // console.log('Filters Places: %o', placesArray);
    // console.log('Filters Rooms: %o', roomsNumbersArray);
    // console.log('Filters Types: %o', typesArray);
    // console.log('Filters Operations: %o', operationsArray);
    // console.log('Filters Range Price: %o', rangePrice);
    // console.log('Results: %o', results);
    console.log(' - Filtered Results: %o', filteredResults);
    console.log(' - Applied Filters: ' + operationsArray + ' - ' + typesArray + ' - ' + roomsNumbersArray + ' - ' + placesArray + ' - ' + rangePrice);

    return filteredResults;
}

export const getInmoVillaFilters = (operationsArray, typesArray, roomsNumbersArray, placesArray, rangePrice, textSearch, codOffer, currentPage, resultsPerPage, orderBy, sortBy) => {

    const inmoVillaFilters = {
        "currentPage": currentPage || 1,
        "resultsPerPage": resultsPerPage || 10,
        "operation": [],
        "type": [],
        "rooms": [],
        "places": [],
        "price": [],
        "textSearch": "",
        "orderBy": orderBy,
        "sortBy": sortBy
    };

    // Check if we have codOffer. If yes, fill it and return
    if (codOffer) {
        inmoVillaFilters.codOffer = codOffer;
        return inmoVillaFilters;
    }

    // Filter by operation --> keyacci (int) --> Tipo de acción (1:Venta,2:Alquiler,3:Traspaso)
    if (operationsArray && operationsArray.length > 0) {
        operationsArray.forEach((operation) => {
            switch (operation) {
                case 'Alquilar':
                    inmoVillaFilters.operation.push(2);
                    break;
                case 'Comprar':
                    inmoVillaFilters.operation.push(1);
                    break;
                case 'Traspasar':
                    inmoVillaFilters.operation.push(3);
                    break;
                default:
                    break;
            }
        });
    }
    // Filter by places
    if (placesArray.length > 0) {
        // inmoVillaFilters.places = getCodCityFromCityNameArray(placesArray);
        inmoVillaFilters.places = placesArray;
    }
    // Filter by type
    if (typesArray.length > 0) {
        // inmoVillaFilters.type = getCodTypesFromTtypesArray(typesArray);
        inmoVillaFilters.type = typesArray;
    }
    // Filter by rooms
    if (roomsNumbersArray.length > 0) {
        inmoVillaFilters.rooms = roomsNumbersArray;
    }

    // Filter by price
    if (rangePrice.length > 0) {


        let finalValue = rangePrice[1];
        if (finalValue === Constants.rangePrice.max + 1) {
           finalValue="*"; // If the final value is the max value, then we will set it to * 
        }


        // If the range is 0 - 0, then we will not filter by price
        if (rangePrice[0] === Constants.rangePrice.min && rangePrice[1] === Constants.rangePrice.max + 1) {
            inmoVillaFilters.rangePrice = [];
        } else {
            inmoVillaFilters.rangePrice = [rangePrice[0], finalValue];
        }
    }

    // Filter by text search
    if (textSearch) {
        
        // Look for cities inside the text
        const cities = getCitiesFromText(textSearch);

        // If there are cities, then we will add them to the places array
        if (cities.length > 0) {
            inmoVillaFilters.places = inmoVillaFilters.places.concat(cities);
        }

        // Remove the cities from the text
        const textSearchWithoutCities = removeCitiesFromText(cities, textSearch);

        // Look for types inside the text
        const types = getTypesFromText(textSearchWithoutCities);

        // If there are types, then we will add them to the types array
        if (types.length > 0) {
            inmoVillaFilters.type = inmoVillaFilters.type.concat(types);
        }

        // Remove the types from the text
        // const textSearchWithoutTypes = removeTypesFromText(types, textSearchWithoutCities);

/*        // Look for operations inside the text
        const operations = getOperationsFromText(textSearchWithoutTypes);

        // If there are operations, then we will add them to the operations array
        if (operations.length > 0) {
            inmoVillaFilters.operation = inmoVillaFilters.operation.concat(operations);
        }

        // Remove the operations from the text
        // const textSearchWithoutOperations = removeOperationsFromText(textSearchWithoutTypes);
        */
    }

    // console.log(' - Filtered Results: %o', inmoVillaFilters);
    // console.log(' - Applied Filters: ' + operationsArray + ' - ' + typesArray + ' - ' + roomsNumbersArray + ' - ' + placesArray + ' - ' + rangePrice);

    return inmoVillaFilters;
}
export const getURLParamsFromFilters = (filters) => {
    // Initialize vars
    let url = '';

    // Get the URL
    if (filters) {
        url = '?' + 'currentPage=' + filters.currentPage + '&resultsPerPage=' + filters.resultsPerPage + '&operation=' + filters.operation + '&type=' + filters.type + '&rooms=' + filters.rooms + '&places=' + filters.places + '&price=' + filters.price + '&textSearch=' + filters.textSearch + '&orderBy=' + filters.orderBy + '&sortBy=' + filters.sortBy;
    }

    // Remove the empty filters from url
    url = url.replace('operation=&', '');
    url = url.replace('type=&', '');
    url = url.replace('rooms=&', '');
    url = url.replace('places=&', '');
    url = url.replace('price=&', '');
    url = url.replace('textSearch=&', '');
    url = url.replace('orderBy=&', '');
    url = url.replace('sortBy=&', '');

    // Remove the last & from url
    url = url.replace(/&$/, '');

    // Return the url
    return url;
}
// Property getters
export const getPriceFromProperty = (property) => {
    // Initialize vars
    let price = 0;
    // debugger;
    // Get the price
    if (property.precioalq) {
        price = property.precioalq;
    } else if (property.precioinmo) {
        price = property.precioinmo;
    } else if (property.preciotraspaso) {
        price = property.preciotraspaso;
    }

    return formatAsMoney(price);
}
export const getTotalRoomsForProperty = (property) => {
    // Initialize vars
    let totalRooms = 0;
    
    // Get the total rooms
    if (property.habdobles) {
        totalRooms = property.habdobles;
    }
    if (property.habitaciones) {
        totalRooms += property.habitaciones;
    }
    // debugger;
    return totalRooms.toString();
}
export const getDescriptionForPropertyFallback = (property, t, lang) => {
    // Initialize vars
    let description = '';
    let rooms = '';
    let bathrooms = '';
    let size = '';
    let price = '';
    let operation = '';
    const IS_ALQUILER = property.precioalq > 0;
    const IS_VENTA = property.precioinmo > 0 || property.precioreal > 0;
    // debugger;

    const IS_TRASPASO = property.preciotraspaso > 0;

    // Get the operation
    if (IS_ALQUILER) {
        operation = t("searchEngine.label.rent");
    } else if (IS_VENTA) {
        operation = t("searchEngine.label.sale");
    } else if (IS_TRASPASO) {
        operation = t("searchEngine.label.transfer");
    }

    // Get the rooms 
    rooms = getTotalRoomsForProperty(property);

    // Get the bathrooms
    if (property.banyos) {
        bathrooms = property.banyos;
    }

    // Get the size
    if (property.superficie) {
        size = property.superficie;
    }

    // Get the price
    price = getPriceFromProperty(property);
    size = getSizeFromProperty(property);
    bathrooms = getBathroomsFromProperty(property);

    // Get the description
    const thisBuildingText = t("searchEngine.label.thisBuildingText");
    const on = t("searchEngine.label.on");
    const isInText = t("searchEngine.label.isInText");
    const andHasText = t("searchEngine.label.andHasText");
    const roomsText = rooms === 1 ? t("searchEngine.label.roomText") : t("searchEngine.label.roomsText");
    const bathroomsText = bathrooms === 1 ? t("searchEngine.label.bathroomText") : t("searchEngine.label.bathroomsText");
    const sizeText = t("searchEngine.label.sizeText");
    const priceText = t("searchEngine.label.priceText");

    // description = `${operation} ${rooms} ${roomsText} ${andHasText} ${bathrooms} ${bathroomsText} ${andHasText} ${size} ${sizeText} ${andHasText} ${price} ${priceText} ${isInText} ${property.ciudad} ${thisBuildingText}`;

    description = `${thisBuildingText} ${on} ${operation} ${isInText} ${property.ciudad} (Lanzarote), ${andHasText} ${rooms} ${roomsText}, ${bathrooms} ${bathroomsText}, ${sizeText} ${size}, ${priceText} ${price} €`;

    // Return the description
    return description;
}
export const getTitleForPropertyFallback = (property, t, lang) => {
    // Initialize vars
    const isAlquiler = property.precioalq > 0;
    const price = property.precio_calculado;
    const priceText = price + " €";
    const priceTextWithPeriod = priceText + (isAlquiler ? "/mes" : "");
    const priceTextWithPeriodAndCurrencyAndCity = priceTextWithPeriod;
    const typeAndName = t('searchEngine.filters.types.options.' + camelize(property.nbtipo)) + t("searchEngine.label.on") + (isAlquiler ? t("searchEngine.label.rent") : t("searchEngine.label.sale")) + t("searchEngine.label.in") + property.ciudad;
    return typeAndName + t("searchEngine.label.for") + priceTextWithPeriodAndCurrencyAndCity;
}
export const getDescriptionObjectForProperty = (property, t, lang) => {

    // The first in the index is the spanish description. The second the english one. Select the one that matches the language
    const HAS_DESCRIPTIONS = property.descripciones && property.descripciones[property.cod_ofer] && property.descripciones[property.cod_ofer] !== null;
    const descriptionsArrayLanguages = HAS_DESCRIPTIONS ? property.descripciones[property.cod_ofer]:[];

    if (HAS_DESCRIPTIONS === true) {
        // debugger;
    }

    // Get the description in function of the language. Languages are 1->es 2->en 3->de and 4->fr
    const descriptionObject = HAS_DESCRIPTIONS ? descriptionsArrayLanguages[getInmoVillaLanguageIndex(lang)] : null;
    const title = descriptionObject ? descriptionObject.titulo : getTitleForPropertyFallback(property, t, lang);
    const description = descriptionObject ? descriptionObject.descrip : getDescriptionForPropertyFallback(property, t, lang);
    
 
    // Return the description
    return {title, description};
}
export const getSizeFromProperty = (property) => {
    // Initialize vars
    let size = 0;

    // Get the size
    if (property.m_cons) {
        size = property.m_cons;
    }

    return size + ' m²';
}
export const getBathroomsFromProperty = (property) => {
    // Initialize vars
    let bathrooms = 0;

    // Get the bathrooms
    if (property.banyos) {
        bathrooms = property.banyos;
    }

    return bathrooms;
}
export const getRoomsFromProperty = (property) => {
    // Initialize vars
    let rooms = 0;

    // Get the rooms
    if (property.habitaciones) {
        rooms = property.habitaciones;
    }

    return rooms;
}
export const getDoubleRoomsFromProperty = (property) => {
    // Initialize vars
    let doubleRooms = 0;

    // Get the double rooms
    if (property.habdobles) {
        doubleRooms = property.habdobles;
    }

    return doubleRooms;
}
export const getCityFromProperty = (property) => {
    // Initialize vars
    let city = '';

    // Get the city
    if (property.ciudad) {
        city = property.ciudad;
    }

    return city;
}
export const getOperationFromProperty = (property) => {
    // Initialize vars
    let operation = '';

    // Get the operation
    if (property.precioalq) {
        operation = 'Alquiler';
    } else if (property.precioinmo) {
        operation = '{props.t("searchEngine.label.sale")}';
    } else if (property.preciotraspaso) {
        operation = 'Traspaso';
    }

    return operation;
}
export const getTypeFromProperty = (property) => {
    // Initialize vars
    let type = '';

    // Get the type
    if (property.nbtipo) {
        type = property.nbtipo;
    }

    return type;
}
export const getImagesFromProperty = (property) => {
    // Initialize vars
    let images = [];

    // Get the images
    if (property.fotos) {
        images = property.fotos;
    }

    return images;
}
export const getMainImageFromProperty = (property) => {
    // Initialize vars
    let mainImage = '';

    // Get the main image
    if (property.foto) {
        mainImage = property.foto;
    }

    return mainImage;
}
export const getReferenceFromProperty = (property) => {
    // Initialize vars
    let reference = '';

    // Get the reference
    if (property.referencia) {
        reference = property.referencia;
    }

    return reference;
}
export const getPlaceFromProperty = (property) => {
    // Initialize vars
    let place = '';

    // Get the place
    if (property.zona) {
        place = property.zona;
    }

    return place;
}
export const getLatitudeFromProperty = (property) => {
    // Initialize vars
    let latitude = '';

    // Get the latitude
    if (property.latitud) {
        latitude = property.latitud;
    }

    return latitude;
}
export const getLongitudeFromProperty = (property) => {
    // Initialize vars
    let longitude = '';

    // Get the longitude
    if (property.altitud) {
        longitude = property.altitud;
    }

    return longitude;
}
export const getHumanDate = (date) => {
    // Initialize vars
    let humanDate = '';

    // Get only the year, month and day
    humanDate = date.split(' ')[0];

    return humanDate;
}

export const getFeaturesFromProperty = (property) => {
    // Initialize vars
    let features = [];

    // Get the features
    if (property.caracteristicas) {
        features = property.caracteristicas;
    }

    return features;
}

export const getElementsForProperty = (property, elementKeyList, t) => {

    const yesText = t('searchEngine.label.yes');
    const noText = t('searchEngine.label.no');

    let elements = [
        {
            key: 'fechacambio',
            label: 'Últ. Cambio',
            value: getHumanDate(property.fechacambio),
            icon: <CalendarViewMonthTwoTone />
        },
        {
            key: 'agencia',
            label: 'Agencia',
            value: property.agencia,
            icon: <LocationCityTwoTone />
        },
        {
            key: 'cod_ofer',
            label: 'Cod. oferta',
            value: property.cod_ofer,
            icon: <Grid3x3TwoTone />
        },
        {
            key: 'ref',
            label: 'Referencia',
            value: property.ref,
            icon: <AssignmentTwoTone />
        },
        {
            key: 'vistasalmar',
            label: 'Vistas al mar',
            value: property.vistasalmar ? yesText : noText,
            icon: <VisibilityTwoTone />
        },
        {
            key: 'energiarecibido',
            label: 'Energía recibido',
            value: property.energiarecibido,
            icon: <EmojiObjectsTwoToneIcon  />
        },
        {
            key: 'vistasdespejadas',
            label: 'Vistas despejadas',
            value: property.vistasdespejadas ? yesText : noText,
            icon: <VisibilityTwoTone />
        },
        {   
            key:'tipomensual',
            label:'Tipo mensual',
            value:property.tipomensual,
            icon: <EuroTwoTone />
        },
        {
            key: 'price',
            label: 'Precio',
            value: getPriceFromProperty(property),
            icon: <EuroSymbolTwoTone />
        },
        {
            key: 'habitaciones',
            label: 'Habs. Simples',
            value: property.habitaciones,
            icon: <SingleBedTwoTone />
        },
        {
            key: 'habdobles',
            label: 'Habs. Dobles',
            value: property.habdobles,
            icon: <KingBedTwoTone />
        },
        {
            key: 'bathrooms',
            label: 'Baños',
            value: getBathroomsFromProperty(property),
            icon: <BathtubTwoTone />
        },
        {
            key: 'size',
            label: 'Superficie',
            value: getSizeFromProperty(property),
            icon: <AspectRatioTwoTone />
        },
        {
            key: 'city',
            label: 'Ciudad',
            value: getCityFromProperty(property),
            icon: <LocationCityTwoTone />
        },
        {
            key: 'operation',
            label: 'Operación',
            value: getOperationFromProperty(property),
            icon: <BusinessCenterTwoTone />
        },
        {
            key: 'type',
            label: 'Tipo',
            value: getTypeFromProperty(property),
            icon: <HomeTwoTone />
        },
        {
            key: 'place',
            label: 'Zona',
            value: getPlaceFromProperty(property),
            icon: <LocationOnTwoTone />
        },
        {
            key: 'latitude',
            label: 'Latitud',
            value: getLatitudeFromProperty(property),
            icon: <MyLocationTwoTone />
        },
        {
            key: 'longitude',
            label: 'Longitud',
            value: getLongitudeFromProperty(property),
            icon: <MyLocationTwoTone />
        },
        {
            key: "m_parcela",
            label: "M. Parcela",
            value: property.m_parcela + 'm²',
            icon: <AspectRatioTwoTone />
    
        },
        {
            key: "balcon",
            label: "Balcon",
            value: property.balcon ? yesText : noText,
            icon: <BalconyTwoTone />
    
        },
        {
            key: "m_cons",
            label: "M. Construidos",
            value: property.m_cons + 'm²',
            icon: <AspectRatioTwoTone />
    
        },
        {
            key: "m_uties",
            label: "M. Utiles",
            value: property.m_uties + 'm²',
            icon: <AspectRatioTwoTone />
    
        },
        {
            key: "conservacion",
            label: "Conservación",
            value: property.conservacion,
            icon: <WaterDamageTwoTone />
    
        },
        {
            key: "calefacentral",
            label: "Calefaccion Central",
            value: property.calefacentral ? yesText : noText,
            icon: <LocalFireDepartmentTwoTone />
        },
        {
            key: "airecentral",
            label: "A/C Central",
            value: property.airecentral ? yesText : noText,
            icon: <AcUnitTwoTone />
    
        },
        {
            key: "plaza_gara",
            label: "Plaza de Garaje",
            value: property.plaza_gara ? yesText : noText,
            icon: <LocalParkingTwoTone />
    
        },
        {
            key: "terraza",
            label: "Terraza",
            value: property.terraza ? yesText : noText,
            icon: <BalconyTwoTone />
    
        },
        {
            key: "ascensor",
            label: "Ascensor",
            value: property.ascensor ? yesText : noText,
            icon: <ElevatorTwoTone />
    
        },
        {
            key: "montacargas",
            label: "Montacargas",
            value: property.montacargas ? yesText : noText,
            icon: <ElevatorTwoTone />
    
        },
        {
            key: "muebles",
            label: "Muebles",
            value: property.muebles ? yesText : noText,
            icon: <KitchenTwoTone />
    
        },
        {
            key: "calefaccion",
            label: "Calefaccion",
            value: property.calefaccion ? yesText : noText,
            icon: <LocalFireDepartmentTwoTone />
    
        },
        {
            key: "aire_con",
            label: "A/C Propio",
            value: property.aire_con ? yesText : noText,
            icon: <AcUnitTwoTone />
    
        },
        {
            key: "primera_line",
            label: "Primera Linea",
            value: property.primera_line ? yesText : noText,
            icon: <BeachAccessTwoTone />
    
        },
        {
            key: "piscina_com",
            label: "Piscina",
            value: property.piscina_com ? yesText : noText,
            icon: <PoolTwoTone />
    
        },
        {
            key: "piscina_prop",
            label: "Piscina Propia",
            value: property.piscina_prop ? yesText : noText,
            icon: <PoolTwoTone />
    
        },
        {
            key: "total_hab",
            label: "Total Habitaciones",
            value: property.total_hab,
            icon: <SingleBedTwoTone />
    
        },
        {
            key: "sumaseos",
            label: "Suma de Baños",
            value: property.sumaseos,
            icon: <BathtubTwoTone />
    
        },
        {
            key: "parking",
            label: "Parking",
            value: property.parking ? yesText : noText,
            icon: <LocalParkingTwoTone />
    
        },
        {
            key: "todoext",
            label: "Todo Exterior",
            value: property.todoext ? yesText : noText,
            icon: <WbSunnyTwoTone />
    
        },
        {
            key: "distmar",
            label: "Distancia al Mar",
            value: property.distmar,
            icon: <BeachAccessTwoTone />
    
        },
        {
            key: "ciudad",
            label: "Ciudad",
            value: getCityFromProperty(property),
            icon: <LocationCityTwoTone />
    
        },
        {
            key: "zona",
            label: "Zona",
            value: property.zona,
            icon: <LocationOnTwoTone />
    
        },
        {
            key: "nbtipo",
            label: "Tipo",
            value: property.nbtipo,
            icon: <HomeTwoTone />
    
        },
        {
            key: "nbconservacion",
            label: "Estado",
            value: property.nbconservacion,
            icon: <WaterDamageTwoTone />
    
        },
        {
            key: "precio_calculado",
            label: "Precio",
            value: property.precio_calculado,
            icon: <AttachMoneyTwoTone />
    
        },
        {
            key: "mascotas",
            label: "Mascotas",
            value: property.mascotas ? yesText : noText,
            icon: <PetsTwoTone />
    
        },
        {
            key: "aconsultar",
            label: "A Consultar",
            value: property.aconsultar,
            icon: <HelpTwoTone />
    
        },
        {
            key: "outlet",
            label: "Outlet", 
            value: property.outlet ? yesText : noText,
            icon: <LocalOfferTwoTone />
    
        },
        {
            key: "aseos",
            label: "Aseos",
            value: property.aseos === 0 ? noText : property.aseos,
            icon: <WcTwoTone />
    
        },
        {
            key: "m_terraza",
            label: "M. Terraza",
            value: property.m_terraza + 'm²',
            icon: <AspectRatioTwoTone />
    
        },
        {
            key: "keycalefa",
            label: "Calefaccion",
            value: t('searchEngine.features.keycalefa_options.' + property.keycalefa),
            icon: <LocalFireDepartmentTwoTone />
    
        },
        {
            key: "eninternet",
            label: "Internet",
            value: property.eninternet === 1 ? yesText : noText,
            icon: <WifiTwoTone />
    
        },
        {
            key: "zonaauxiliar",
            label: "Zona Auxiliar",
            value: property.zonaauxiliar,
            icon: <LocationOnTwoTone />
    
        },
        {
            key: "urbanizacion",
            label: "Urbanizacion",
            value: property.urbanizacion === "0" ? noText : yesText,
            icon: <HomeTwoTone />
    
        }
    ];
    
    // Initialize vars
    let filteredElements = [];

    // Filter elements by key using the elementKeyList
    elementKeyList.forEach((elementKey) => {
        const element = elements.find((element) => element.key === elementKey);
        if (element) {
            filteredElements.push(element);
        }
    });
    
    // Return filtered elements
    return filteredElements;
}
export const removeElementsForPropertyIfEmptyOrZero = (elements) => {
    return elements.filter((element) => {
        // If the value is empty or zero, remove the element
        if (element.value === "" || element.value === 0) {
            return false;
        }
        
        // If the value is undefined, remove the element
        if (typeof element.value === "undefined") {
            return false;
        }

        // If the value is null, remove the element
        if (element.value === null) {
            return false;
        }

        // If the value is a string and it's '0m²', remove the element
        if (typeof element.value === "string" && element.value === "0m²") {
            return false;
        }

        return true;

    });
}

export const camelize = (str) => {
    
    // Remove hyphens and accents
    str = str.replace(/-/g, ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    // Camelize
    str = str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
    return str;
}

export const getCodCityFromCityName = (cityName) => {
    const city = Constants.INMOVILLA_CITIES.find((city) => city.city === cityName);
    return city ? city.cod_ciu : null;
}

export const getCodCityFromCityNameArray = (cityNameArray,) => {

    // For each city name in the array, get the cod_ciu
    const codCityArray = cityNameArray.map((cityName) => {
        return getCodCityFromCityName(cityName);
    });

    // Return cod_ciu array
    return codCityArray;
}
export const getCodTypesFromTtypesArray = (typesArray, constantsTypes) => {
    
    // For each type in the array, get the cod_tipo
    const codTypesArray = typesArray.map((type) => {
        return Constants.INMOVILLA_TYPES.find((t) => t.tipo === type).tipo;
    });

    // Return cod_tipo array
    return codTypesArray;
}
export const removeAccents = (str) => {
        // Remove accents
    str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return str;
}

export const getCitiesFromText = (text) => {

    let parsedCity = text;

    // Remove accents
    parsedCity = parsedCity.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    // Remove hyphens
    parsedCity = parsedCity.replace(/-/g, ' ');

    // To lowercase
    parsedCity = parsedCity.toLowerCase();

    // Substitute little city names by big city names in Lanzarote
    /*
    "Arrecife"
    "Haria"
    "San Bartolome"
    "Teguise"
    "Tias"
    "Tinajo"
    "Yaiza"
    */
    parsedCity = parsedCity.replace(/arrecife/g, 'Arrecife');
    parsedCity = parsedCity.replace(/haria/g, 'Haria');
    parsedCity = parsedCity.replace(/san bartolome/g, 'San Bartolome');
    parsedCity = parsedCity.replace(/teguise/g, 'Teguise');
    parsedCity = parsedCity.replace(/tias/g, 'Tias');
    parsedCity = parsedCity.replace(/tinajo/g, 'Tinajo');
    parsedCity = parsedCity.replace(/yaiza/g, 'Yaiza');

    parsedCity = parsedCity.replace(/puerto del carmen/g, 'Tias');
    parsedCity = parsedCity.replace(/playa blanca/g, 'Yaiza');
    parsedCity = parsedCity.replace(/costa teguise/g, 'Teguise');

    // Localidades de lanzarote
    
    parsedCity = parsedCity.replace(/la villa de tias/g, 'Tias');
    parsedCity = parsedCity.replace(/arrecife/g, 'Arrecife');
    parsedCity = parsedCity.replace(/haria/g, 'Haria');
    parsedCity = parsedCity.replace(/tias/g, 'Tias');
    parsedCity = parsedCity.replace(/san bartolome/g, 'San Bartolome');
    parsedCity = parsedCity.replace(/yaiza/g, 'Yaiza');
    parsedCity = parsedCity.replace(/tinajo/g, 'Tinajo');
    parsedCity = parsedCity.replace(/playa blanca/g, 'Yaiza');
    parsedCity = parsedCity.replace(/costa teguise/g, 'Teguise');
    parsedCity = parsedCity.replace(/teguise/g, 'Teguise');
    parsedCity = parsedCity.replace(/maguez/g, 'Tinajo');
    parsedCity = parsedCity.replace(/caleta de famara/g, 'Teguise');
    parsedCity = parsedCity.replace(/guatiza/g, 'Teguise');
    parsedCity = parsedCity.replace(/orzola/g, 'Haria');
    parsedCity = parsedCity.replace(/arrieta/g, 'Haria');
    parsedCity = parsedCity.replace(/mala/g, 'Teguise');
    parsedCity = parsedCity.replace(/masdache/g, 'Haria');
    parsedCity = parsedCity.replace(/uga/g, 'Yaiza');
    parsedCity = parsedCity.replace(/el rincon/g, 'Teguise');
    parsedCity = parsedCity.replace(/janubio/g, 'Yaiza');
    parsedCity = parsedCity.replace(/playa honda/g, 'Teguise');
    parsedCity = parsedCity.replace(/charco del palo/g, 'Haria');
    parsedCity = parsedCity.replace(/la concepcion/g, 'Teguise');
    parsedCity = parsedCity.replace(/los valles/g, 'Haria');
    parsedCity = parsedCity.replace(/punta mujeres/g, 'Haria');
    parsedCity = parsedCity.replace(/soo/g, 'Tinajo');
    parsedCity = parsedCity.replace(/guime/g, 'Teguise');
    parsedCity = parsedCity.replace(/tres palmeras/g, 'Haria');
    parsedCity = parsedCity.replace(/femes/g, 'Yaiza');
    parsedCity = parsedCity.replace(/la asomada/g, '');
    parsedCity = parsedCity.replace(/la calera/g, 'Teguise');
    parsedCity = parsedCity.replace(/la atalaya/g, 'Teguise');
    parsedCity = parsedCity.replace(/las coloradas/g, 'Yaiza');
    parsedCity = parsedCity.replace(/montana roja/g, 'Yaiza');
    parsedCity = parsedCity.replace(/punta del papagayo/g, 'Yaiza');
    parsedCity = parsedCity.replace(/el jable/g, 'Teguise');
    parsedCity = parsedCity.replace(/la santa/g, 'Tinajo');
    parsedCity = parsedCity.replace(/la geria/g, 'Tinajo');
    parsedCity = parsedCity.replace(/la graciosa/g, 'Haria');
    parsedCity = parsedCity.replace(/los hervideros/g, 'Yaiza');
    parsedCity = parsedCity.replace(/los miradores/g, 'Haria');
    parsedCity = parsedCity.replace(/las salinas/g, 'Yaiza');
    parsedCity = parsedCity.replace(/los cuchillos/g, 'Yaiza');
    parsedCity = parsedCity.replace(/el cuchillo/g, 'Yaiza');
    parsedCity = parsedCity.replace(/las breñas/g, 'Tinajo');
    parsedCity = parsedCity.replace(/el golfo/g, 'Yaiza');
    parsedCity = parsedCity.replace(/famara/g, 'Teguise');
    parsedCity = parsedCity.replace(/puerto del carmen/g, 'Tias');
    parsedCity = parsedCity.replace(/macher/g, 'Tias');
    parsedCity = parsedCity.replace(/conil/g, 'Tias');
    parsedCity = parsedCity.replace(/madasche/g, 'Tias');

    // Get cities from parsedCity
    const cities = Constants.INMOVILLA_CITIES.filter((city) => parsedCity.toLowerCase().includes(city.city.toLowerCase()));
    
    // debugger;
    // Return the name of the cities
    return cities.map((city) => city.city);
    
}
export const removeCitiesFromText = (cities, textSearch) => {
    
    // Remove cities from text
    cities.forEach((city) => {
        textSearch = textSearch.replace(city.city, '');
    });

    // Return text
    return textSearch;
}
export const getTypesFromText = (text) => {
    const types = Constants.INMOVILLA_TYPES.filter((type) => text.toLowerCase().includes(type.tipo.toLowerCase()));

    // Return the name of the types
    return types.map((type) => type.tipo);
}
export const removeTypesFromText = (types, textSearch) => {

    // Remove types from text
    types.forEach((type) => {
        textSearch = textSearch.replace(type.tipo, '');
    });

    // Return text
    return textSearch;
}
export const getOperationsFromText = (text) => {
    const operations = Constants.operations.filter((operation) => text.toLowerCase().includes(operation.toLowerCase()));

    // Return the name of the operations
    return operations.map((operation) => operation);
}

export const removeOperationsFromText = (operations, textSearch) => {
    
    // Remove operations from text
    operations.forEach((operation) => {
        textSearch = textSearch.replace(operation.tipo, '');
    });

    // Return text
    return textSearch;
}

export const getInmoVillaLanguageIndex = (language) => {
    
    switch (language) {
        case 'es':
            return 1;
        case 'en':
            return 2;
        case 'de':
            return 3;
        case 'fr':
            return 4;
        default :
            return 1;
    }
}
export const getCazatucasaPhones = () => {
    return <div><a href="tel:0034928821153" rel="noreferrer">928 821 153</a> | <a href="https://wa.link/hsf8uh" target="_blank" rel="noreferrer">607 561 293</a></div>;
}  
export const getLinkForTourVirtualFor = (property) => {
    const link = `http://ap.apinmo.com/fotosvr/tour.php?cod=${property.cod_ofer}.${property.numagencia}`;
    console.log("Tour Virtual Link: %o",  link);
    return link;
}
export const getHumanizedDate = (date, lang) => {

    return date;

}   


export const getOperationInitialLetter = (operation, t, lang) => {

    // Tipo de acción (1:Venta,2:Alquiler,3:Traspaso)
    let key = "";
    switch (operation) {
        case 1:
            key = "comprar";
            break;
        case 2:
            key = "alquilar";
            break;
        case 6:
            key = "traspasar";
            break;
        default:
            key = "-";
            break;
    }

    const operationInLanguage = t ('searchEngine.filters.operations.options.' + key);
    // debugger;
    return operationInLanguage.charAt(0);
}

export const getOperationTranslated = (operation, t, lang) => {

    // Tipo de acción (1:Venta,2:Alquiler,3:Traspaso)
    let key = "";
    switch (operation) {
        case 1:
            key = "comprar";
            break;
        case 2:
            key = "alquilar";
            break;
        case 6:
            key = "traspasar";
            break;
        default:
            key = "-";
            break;
    }

    const operationInLanguage = t ('searchEngine.filters.operations.options.' + key);
    // debugger;
    return operationInLanguage;
}

export const getOperationColor = (operation) => {
    
        // Tipo de acción (1:Venta,2:Alquiler,3:Traspaso)
        let color = null;
        switch (operation) {
            case 1:
                color = red[500];
                break;
            case 2:
                color = blue[500];
                break;
            case 6:
                color = orange[500];
                break;
            default:
                color = "grey";
                break;
        }
    
        return color;
    }

    export const getOperationColorClass = (operation) => {
    
        // Tipo de acción (1:Venta,2:Alquiler,3:Traspaso)
        let color = null;
        switch (operation) {
            case 1:
                color = "venta-class";
                break;
            case 2:
                color = "alquiler-class";
                break;
            case 6:
                color = "traspaso-class";
                break;
            default:
                color = "grey-class";
                break;
        }
    
        return color;
    }


    export const getFontSizeClass = (text) => {
    
        // 3 clases: small: 12px, medium: 14px, large: 16px
        
        let size = null;

        const textSize = text.length;
        if (textSize <= 10) {
            size = "small";
        } else if (textSize <= 25) {
            size = "medium";
        } else {
            size = "large";
        }

        return "with-text-"+size;
    }