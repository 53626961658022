import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CookiesProvider } from 'react-cookie';

// NEW !! React 18.x
const root = ReactDOM.createRoot(document.getElementById('browser_root'));

// Get Language from 'data-lang' attribute
const lang = document.getElementById('browser_root').getAttribute('data-lang');
root.render(
    <CookiesProvider>
        <App lang={lang} />
    </CookiesProvider>,
);
