export const translation = {
    navbar: {
        label: {
            reactVersion: 'Versión de React',
            lang: {
                language: 'Idioma',
                es: 'Español',
                en: 'Inglés',
                fr: 'Francés',
                de: 'Alemán'

            },
            undoRedo: {
                step: 'Paso',
                undo: 'Deshacer',
                redo: 'Rehacer',
            },
        }
    },
    searchEngine: {  
        label: {

            // Search Engine Labels
            // searchBoxPlaceholder: 'Appartement à louer à Playa Blanca, Teguise...',
            searchBoxPlaceholder: 'Playa Blanca, Teguise...',
            searchBoxSlogan: "Vous n'êtes qu'à un clic de trouver <1>la maison de vos rêves</1>.",
            searchBoxButton: 'Recherche',
            searchMapButton: 'Carte',
            searchMapButtonTooltip: 'Voir sur la carte',
            searchMapButtonTooltipClose: 'Fermer la carte',
            searchMapButtonTooltipOpen: 'Ouvrir la carte',
            priceRangeLabel: 'Prix',
            cleanFiltersLink: 'Nettoyer les filtres',
            cleanFiltersMessage: 'Essayez de nettoyer les filtres et recommencez la recherche',
            cleanFiltersMessageLink: 'Nettoyer les filtres et démarrer',

            // Search Engine Results
            noResults: 'Aucun résultat trouvé',
            dormitorio: 'chambre ',
            dormitorios: 'chambres',
            resultado: 'résultat',
            resultados: 'résultats',
            verEnElMapa: 'Voir sur la carte',
            ordenarPor: 'Trier par',
            precio: 'Prix',
            relevancia: 'Pertinence',
            habitaciones: 'Chambres',
            price: 'Prix',
            baños: 'Salles de bains',
            superficie: 'Surface',
            from: 'De',
            to: 'A',
            of: ' de ',
            sale: 'a vendre',
            rent: 'a louer',
            on: " à l'adresse ",
            in: " à l'adresse ",
            for: ' par ',
            map: ' Carte',
            viewLocation: 'Localisation',
            yes: 'Oui', 
            no: 'Non', 
            noLimit: 'Pas de limite',
            code: 'Code',
            ref: 'Référence',
            
            // Detail of the property
            photo: 'photo',
            photos: 'photos',
            video: 'vidéo',
            videos: 'vidéos',
            noVideos: "Il n'y a pas de vidéos disponibles pour cette propriété.",
            features: 'Caractéristiques',
            description: 'Description',
            basicFeatures: 'Caractéristiques de base',
            location: 'Localisation',
            equipment: 'Équipement',
            offerInfo: "Informations sur l'offre",
            energeticCertification: 'Certification énergétique',

            // Actions
            actions: 'Actions', 
            callAgent: "Appelez l'agent",
            deleteFromSearch: 'Supprimer de la recherche',
            agent: 'Agent',
            seeProfile: 'Voir le profil',
            sendMessage: 'Envoyer un message',

            // Description of the property
            thisBuildingText: 'Ce bâtiment',
            isInText: 'est situé à',
            andHasText: 'et a',
            roomsText: 'chambres',
            bathroomsText: 'salles de bains',
            roomText: 'chambre',
            bathroomText: 'salle de bains',
            sizeText: "d'une superficie totale de",
            priceText: 'et un prix de ',
            roomTextCompressed: 'chambres',
            goBackToSearch: 'Retour à la recherche',
            seeVideo: 'Voir la vidéo',
            seePhotos: 'Voir les photos',

            // Map 
            fullScreenMap: 'Plein écran',
            exitFullScreenMap: 'Quitter le plein écran',
            
            // Actions
            seePhone: 'Voir le téléphone', 
            contact: 'Contact',
            delete: 'Retirer', 
            
            // Footer Toolbar
            show: 'Afficher',
            resultsPerPageText: 'Résultats par page',
            gotoTopText: 'Retour au début',

            // Tour Virtual
            tourVirtual: 'Visite virtuelle',
            noTourVirtual:  "Il n'y a pas de visite virtuelle disponible pour cette propriété.",
            seeTourVirtual: 'Voir la visite virtuelle',
            
        },
        filters: {
            operations: {
                placeholder: 'Que cherchez-vous ?',
                options: {
                    alquilar: 'Location',
                    comprar: 'Acheter',
                    traspasar: 'Transfert',
                }
            },
            locations: {
                placeholder: 'Localisation',
                options: {
                    arrecife: 'Arrecife',
                    haria: 'Haría',
                    tias: 'Tías',
                    sanBartolome: 'San Bartolomé',
                    teguise: 'Teguise',
                    tinajo: 'Tinajo',
                    yaiza: 'Yaiza',
                }
            },
            types: {
                placeholder: 'Propriété',
                options: {
                    apartamento: 'Appartement',
                    ático: 'Penthouse',
                    atico: 'Penthouse',
                    casa: 'Maison',
                    chalet: 'Chalet',
                    duplex: 'Duplex',
                    dúplex: 'Duplex',
                    localComercial: 'Locaux commerciaux',
                    "local comercial": 'Locaux commerciaux',
                    piso: 'Appartement',
                    restaurante: 'Restaurant',
                    solar: 'Terrain',
                    terrenoUrbano: 'Terrain urbain',
                    "terreno urbano": 'Terrain urbain',
                    villa: 'Villa',
                    parcela: 'Parcelle',
                },
            },
            roomsNumber: {
                placeholder: 'Nombre de chambres',
                options: {
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    masDe5: 'Plus de 5'
                }
            },
            sortBy: {
                placeholder: 'Trier par',
                options: {
                    precio: 'Prix',
                    relevancia: 'Pertinence',
                    habitaciones: 'Chambres',
                    metros: 'Mètres carrés',
                }
            }    
        },
        features: {
            fechacambio: 'Dernier changement',
            agencia: 'Agence',
            cod_ofer: "Code de l'offre",
            ref: 'Référence',
            vistasalmar: 'Vue sur la mer',
            energiarecibido: 'Énergie reçue',
            vistasdespejadas: 'Vues dégagées',
            tipomensual: 'Taux mensuel',
            tipomensual_options: {
                'MES': 'Par mois',
                'TRIMESTRE': 'Par trimestre',
                'SEMESTRE': 'Par semestre',
                'AÑO': 'Par an',
            },
            price: 'Prix',
            habitaciones: 'Ch. Simples',
            habdobles: 'Ch. Doubles',
            bathrooms: 'Salles de bains',
            size: 'Superficie',
            city: 'Ville',
            operation: 'Opération',
            type: 'Type',
            place: 'Zone',
            latitude: 'Latitude',
            longitude: 'Longitude',
            m_parcela: 'M. Plot',
            balcon: 'Balcon',
            m_cons: 'M. Construit',
            m_uties: 'M. Utilisable',
            conservacion: 'Conservation',
            calefacentral: 'Chauffage central',
            airecentral: 'Central A/C',
            plaza_gara: 'Espace garage',
            terraza: 'Terrasse',
            ascensor: 'Ascenseur',
            montacargas: 'Ascenseur',
            muebles: 'Meubles',
            calefaccion: 'Chauffage',
            aire_con: 'Propre A/C',
            primera_line: 'Front Line',
            piscina_com: 'Piscine',
            piscina_prop: 'Piscine privée',
            total_hab: 'Total des chambres',
            sumaseos: 'Total des salles de bain',
            parking: 'Parking',
            todoext: 'Tous les extérieurs',
            distmar: 'Distance par rapport à la mer',
            ciudad: 'Ville',
            zona: 'Zone',
            nbtipo: 'Type',
            nbconservacion: 'Statut',
            nbconservacion_options: {
                "Para reformar": "Pour rénover",
                "De origen": "De l'origine",
                "Reformar Parcialmente": "Partiellement rénové",
                "Entrar a vivir": "Prêt à vivre",
                "Buen estado": "Bon état",
                "Semi Reformado": "Semi rénové",
                "Reformado": "Rénové",
                "Semi nuevo": "Semi neuf",
                "Nuevo": "Neuf",
                "Obra Nueva": "Nouvelle construction",
                "En construcción": "En construction",
                "En proyecto": "En projet",
                "Ninguno": "Aucun",
            },
            precio_calculado: 'Prix',
            mascotas: 'Animaux domestiques',
            aconsultar: 'Sur demande',
            outlet: 'Sortie', 
            aseos: 'Toilettes',
            m_terraza: 'M. Terrasse',
            keycalefa: 'Chauffage',
            keycalefa_options: {
                1: "Gaz naturel",
                2: "Gazole",
                3: "Électricité",
                4: "Granulés",
                5: "Solaire",
                6: "Biomasse",
                7: "Autres",
                8: "Non",
            },            
            eninternet: 'Internet',
            zonaauxiliar: 'Zone auxiliaire',
            urbanizacion: 'Urbanisation',
        }     
    }
}