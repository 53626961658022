import * as React from 'react';

export default function VideoMiniPlayer (props) {

   const HAS_VIDEO = props.for.videos && props.for.videos[props.for.cod_ofer] && props.for.videos[props.for.cod_ofer].length > 0;
   const youtubeIds = HAS_VIDEO ? props.for.videos[props.for.cod_ofer] : [];
    const className = 'video-player-container ' + (HAS_VIDEO ? 'has-video' : 'no-video');
    return (
        <div className={className}>
            {HAS_VIDEO ? (
                <div className="video-mini-player">
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${youtubeIds[0]}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            ) : (
                <div className="video-mini-player">
                    <div className="video-mini-player-placeholder">
                        <div className="video-mini-player-placeholder-text result-info-description">
                            <span>{props.t("searchEngine.label.noVideos")}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
