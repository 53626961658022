export function getData (filters) {
    return new Promise((resolve, reject) => {
    // e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    
    // Include the filters in the request
    Object.keys(filters).forEach(key => {
        urlencoded.append(key, filters[key]);
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://cazatucasa.es/browser/api/api_cliente/cliente/cliente.php", requestOptions)
      .then(response => response.text())
      .then(result => {
            // Resolvemos la promesa con los datos de la propiedad
            resolve(result);
        })
      .catch(error => console.log('error', error));
    });
}

export function getPropertyDetails (property) {
    return new Promise((resolve, reject) => {
    // e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("cod_ofer", property.cod_ofer);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://cazatucasa.es/browser/api/api_cliente/cliente/pedir-ficha.php", requestOptions)
      .then(response => response.text())
      .then(result => {
            // Resolvemos la promesa con los datos de la propiedad
            resolve(result);
        })
      .catch(error => console.log('error', error));
    });
}

export function getFeaturedResults (filters) {
    return new Promise((resolve, reject) => {
    // e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    var urlencoded = new URLSearchParams();
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch("https://cazatucasa.es/browser/api/api_cliente/cliente/carrusel.php", requestOptions)
      .then(response => response.text())
      .then(result => {
            // Resolvemos la promesa con los datos de la propiedad
            resolve(result);
        })
      .catch(error => console.log('error', error));
    });
}
