export const translation = {
    navbar: {
        label: {
            reactVersion: 'Versión de React',
            lang: {
                language: 'Idioma',
                es: 'Español',
                en: 'Inglés',
                fr: 'Francés',
                de: 'Alemán'
            },
            undoRedo: {
                step: 'Paso',
                undo: 'Deshacer',
                redo: 'Rehacer',
            },
        }
    },
    searchEngine: {  
        label: {

            // Search Engine Labels
            // searchBoxPlaceholder: 'Apartamento en Playa Blanca, Unifamiliar de alquiler en Teguise...',
            searchBoxPlaceholder: 'Playa Blanca, Teguise...',
            searchBoxSlogan: 'Estás a un click de encontrar <1>la casa de tus sueños</1>',
            searchBoxButton: 'Buscar',
            searchMapButton: 'Mapa',
            searchMapButtonTooltip: 'Ver en mapa',
            searchMapButtonTooltipClose: 'Cerrar mapa',
            searchMapButtonTooltipOpen: 'Abrir mapa',
            priceRangeLabel: 'Precio',
            cleanFiltersLink: 'Limpiar filtros',
            cleanFiltersMessage: 'Prueba a limpiar los filtros y volver a buscar',
            cleanFiltersMessageLink: 'Limpiar filtros y comenzar',

            // Search Engine Results
            noResults: 'No se han encontrado resultados',
            dormitorio: 'dormitorio',
            dormitorios: 'dormitorios',
            resultado: 'resultado',
            resultados: 'resultados',
            verEnElMapa: 'Ver en el Mapa',
            ordenarPor: 'Ordenar por',
            precio: 'Precio',
            relevancia: 'Relevancia',
            habitaciones: 'Habitaciones',
            price: 'Precio',
            baños: 'Baños',
            superficie: 'Superficie',
            from: 'Desde',
            to: 'Hasta',
            of: ' de ',
            sale: 'venta',
            rent: 'alquiler',
            on: ' en ',
            in: ' en ',
            for: ' por ',
            map: ' Mapa',
            viewLocation: 'Ubicación',
            yes: 'Sí',
            no: 'No',
            noLimit: 'Sin límite',
            code: 'Código',
            ref: 'Referencia',
            
            // Detail of the property
            photo: 'foto',
            photos: 'fotos',
            video: 'video',
            videos: 'videos',
            noVideos: 'No hay videos disponibles para esta propiedad.',
            features: 'Características',
            description: 'Descripción',
            basicFeatures: 'Características básicas',
            location: 'Ubicación',
            equipment: 'Equipamiento',
            offerInfo: 'Información de la oferta',
            energeticCertification: 'Certificación energética',

            // Actions
            actions: 'Acciones', 
            callAgent: 'Llamar al agente',
            deleteFromSearch: 'Eliminar de la búsqueda',
            agent: 'Agente',
            seeProfile: 'Ver perfil',
            sendMessage: 'Enviar mensaje',

            // Description of the property
            thisBuildingText: 'Este edificio',
            isInText: 'se encuentra en',
            andHasText: 'y cuenta con',
            roomsText: 'habitaciones',
            bathroomsText: 'baños',
            roomText: 'habitación',
            bathroomText: 'baño',
            sizeText: 'con una superficie total de',
            priceText: 'y un precio de ',
            roomTextCompressed: 'hab.',
            goBackToSearch: 'Volver a la búsqueda',
            seeVideo: 'Ver video',
            seePhotos: 'Ver fotos',

            // Map 
            fullScreenMap: 'Pantalla completa',
            exitFullScreenMap: 'Salir de pantalla completa',
            
            // Actions
            seePhone: 'Ver teléfono', 
            contact: 'Contactar',
            delete: 'Eliminar', 
            
            // Footer Toolbar
            show: 'Mostrar',  
            resultsPerPageText: 'Resultados por página',
            gotoTopText: 'Volver al inicio',

            // Tour Virtual
            tourVirtual: 'Tour virtual',
            noTourVirtual: 'No hay tour virtual disponible para esta propiedad.',
            seeTourVirtual: 'Ver tour virtual',
            
        },
        filters: {
            operations: {
                placeholder: '¿Qué buscas?',
                options: {
                    alquilar: 'Alquilar',
                    comprar: 'Comprar',
                    traspasar: 'Traspasar'
                }
            },
            locations: {
                placeholder: 'Ubicación',
                options: {
                    arrecife: 'Arrecife',
                    haria: 'Haría',
                    tias: 'Tías',
                    sanBartolome: 'San Bartolomé',
                    teguise: 'Teguise',
                    tinajo: 'Tinajo',
                    yaiza: 'Yaiza',
                }
            },
            types: {
                placeholder: 'Inmueble',
                options: {
                    apartamento: 'Apartamento',
                    ático: 'Ático',
                    atico: 'Ático',
                    casa: 'Casa',
                    chalet: 'Chalet',
                    duplex: 'Dúplex',
                    dúplex: 'Dúplex',
                    localComercial: 'Local Comercial',
                    "local comercial": 'Local Comercial',
                    piso: 'Piso',
                    restaurante: 'Restaurante',
                    solar: 'Solar',
                    terrenoUrbano: 'Terreno Urbano',
                    "terreno urbano": 'Terreno Urbano',
                    villa: 'Villa',
                    parcela: 'Parcela',
                },
            },
            roomsNumber: {
                placeholder: 'Nº Dormitorios',
                options: {
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    masDe5: 'Más de 5'
                }
            },
            sortBy: {
                placeholder: 'Ordenar por',
                options: {
                    precio: 'Precio',
                    relevancia: 'Relevancia', 
                    habitaciones: 'Habitaciones',
                    metros: 'Metros cuadrados',
                }
            }    
        },
        features: {
            fechacambio: 'Últ. Cambio',
            agencia: 'Agencia',
            cod_ofer: 'Cod. oferta',
            ref: 'Referencia',
            vistasalmar: 'Vistas al mar',
            energiarecibido: 'Energía recibido',
            vistasdespejadas: 'Vistas despejadas',
            tipomensual: 'Tipo mensual',
            tipomensual_options: {
                'MES': 'Mes',
                'TRIMESTRE': 'Trimestre',
                'SEMESTRE': 'Semestre',
                'AÑO': 'Año',
            },
            price: 'Precio',
            habitaciones: 'Habs. Simples',
            habdobles: 'Habs. Dobles',
            bathrooms: 'Baños',
            size: 'Superficie',
            city: 'Ciudad',
            operation: 'Operación',
            type: 'Tipo',
            place: 'Zona',
            latitude: 'Latitud',
            longitude: 'Longitud',
            m_parcela: 'M. Parcela',
            balcon: 'Balcon',
            m_cons: 'M. Construidos',
            m_uties: 'M. Utiles',
            conservacion: 'Conservación',
            calefacentral: 'Calefaccion Central',
            airecentral: 'A/C Central',
            plaza_gara: 'Plaza de Garaje',
            terraza: 'Terraza',
            ascensor: 'Ascensor',
            montacargas: 'Montacargas',
            muebles: 'Muebles',
            calefaccion: 'Calefaccion',
            aire_con: 'A/C Propio',
            primera_line: 'Primera Linea',
            piscina_com: 'Piscina',
            piscina_prop: 'Piscina Propia',
            total_hab: 'Total Habitaciones',
            sumaseos: 'Suma de Baños',
            parking: 'Parking',
            todoext: 'Todo Exterior',
            distmar: 'Distancia al Mar',
            ciudad: 'Ciudad',
            zona: 'Zona',
            nbtipo: 'Tipo',
            nbconservacion: 'Estado',
            nbconservacion_options: {
                "Para reformar":"Para reformar",
                "De origen":"De origen",
                "Reformar Parcialmente": "Reformar Parcialmente",
                "Entrar a vivir": "Entrar a vivir",
                "Buen estado": "Buen estado",
                "Semi Reformado": "Semi Reformado",
                "Reformado": "Reformado",
                "Semi nuevo": "Semi nuevo",
                "Nuevo": "Nuevo",
                "Obra Nueva": "Obra Nueva",
                "En construcción": "En construcción",
                "En proyecto": "En proyecto",
                "Ninguno": "Ninguno", 
            },
            precio_calculado: 'Precio',
            mascotas: 'Mascotas',
            aconsultar: 'A Consultar',
            outlet: 'Outlet', 
            aseos: 'Aseos',
            m_terraza: 'M. Terraza',
            keycalefa: 'Calefaccion',
            keycalefa_options: {
                1: "Gas Natural",
                2: "Gasoleo",
                3: "Electricidad",
                4: "Pellets",
                5: "Solar",
                6: "Biomasa",
                7: "Otros",
                8: "No",
            },            
            eninternet: 'Internet',
            zonaauxiliar: 'Zona Auxiliar',
            urbanizacion: 'Urbanizacion',
        }     
    }
}
