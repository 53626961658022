// APP CONSTANTS
export const APP_DEFAULT_ICON = 'cog-alt';

// URL CONSTANTS
export const URL_HOME = window.location.href;
export const URL_PARTNERS_SELECTOR_END = `?callBackURL=${URL_HOME}&callBackAppName=Deploy+Global+Config`;

// CONSOLE LOG ELEMENTS
export const textTemplate = 'font-weight: bold; color: #7ebf99;';
export const textBlack = 'color: black;font-weight: normal;';
export const textBlue = 'font-weight: normal; color: #0000b773;';

// ALGORITHM SELECTOR
 export const roomsNumbers = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      'Más de 5',
]; 
export const searchSort = [
// 'Relevancia',
'Precio',
// 'Distancia',
// 'Fecha',
'Habitaciones',
'Metros',
// 'Baños',
];
export const searchSortDirection = [
'asc',
'desc'
];
export const resultsPerPageOptions = [
'1',
'2',
'5',
'10',
'20',
'50'
];

// KEYS FOR THE ICONS IN THE DESCRIPTION
export const basicInfoKeys = [
        "nbtipo",
        "precio_calculado",
        "total_hab",
        "habitaciones",
        "habdobles",
        "sumaseos",
        "aseos",
        "m_parcela",
        "m_cons",
        "m_uties",
        "m_terraza",
        "nbconservacion",
        "conservacion",
        "mascotas",
        "todoext",
        "energia",
        "clase_energia",
        "energiarecibido",

];
export const locationInfoKeys = [
        "zona",
        "zonaauxiliar",
        "municipio",
        "ciudad",
        "urbanizacion",
        "provincia",
        "pais",
        "distmar",
        "primera_line",
        "vistasalmar",
        "vistasdespejadas",
];
export const equipmentKeys = [
        "eninternet",
        "parking",
        "airecentral",
        "aire_con",
        "ascensor",
        "balcon",
        "terraza",
        "calefaccion",
        "montacargas",
        "muebles",
        "outlet",
        "piscina_com",
        "piscina_prop",
];
export const offerInfoKeys = [
        "agencia",
        "cod_ofer",
        "ref",
        "tipomensual",
        "fechacambio",
];
export const energyInfoKeys = [
        "energia",
        "clase_energia",
        "energiarecibido",

];

// Secciones de detalle
export const SEARCH_DETAIL_SECTION_TITLE = 'title';
export const SEARCH_DETAIL_SECTION_PHOTOS = 'photos';
export const SEARCH_DETAIL_SECTION_VIDEOS = 'video';
export const SEARCH_DETAIL_SECTION_DESCRIPTION = 'description';
export const SEARCH_DETAIL_SECTION_LOCATION = 'location';
export const SEARCH_DETAIL_SECTION_BASIC_INFO = 'basicInfo';


export const langs = {
    es: { nativeName: 'Español' },
    en: { nativeName: 'English' },
    fr: {nativeName: 'Français'},
    de: {nativeName: 'Deutsch'}
};

export const LANGUAGE_ES = 'es';
export const LANGUAGE_EN = 'en';
export const LANGUAGE_FR = 'fr';
export const LANGUAGE_DE = 'de';


// InmoVilla objects
export const INMOVILLA_CITIES = [
    {
        "cod_ciu": 395299,
        "city": "Arrecife",
        "provincia": "LAS PALMAS",
        "isla": "Lanzarote",
        "codprov": 57,
        "num": 1,
        "keysdistrito": ""
    },
    {
        "cod_ciu": 395899,
        "city": "Haria",
        "provincia": "LAS PALMAS",
        "isla": "Lanzarote",
        "codprov": 57,
        "num": 1,
        "keysdistrito": ""
    },
    {
        "cod_ciu": 396699,
        "city": "San Bartolome",
        "provincia": "LAS PALMAS",
        "isla": "Lanzarote",
        "codprov": 57,
        "num": 1,
        "keysdistrito": ""
    },
    {
        "cod_ciu": 397299,
        "city": "Teguise",
        "provincia": "LAS PALMAS",
        "isla": "Lanzarote",
        "codprov": 57,
        "num": 1,
        "keysdistrito": ""
    },
    {
        "cod_ciu": 397699,
        "city": "Tias",
        "provincia": "LAS PALMAS",
        "isla": "Lanzarote",
        "codprov": 57,
        "num": 1,
        "keysdistrito": ""
    },
    {
        "cod_ciu": 397799,
        "city": "Tinajo",
        "provincia": "LAS PALMAS",
        "isla": "Lanzarote",
        "codprov": 57,
        "num": 1,
        "keysdistrito": ""
    },
    {
        "cod_ciu": 398299,
        "city": "Yaiza",
        "provincia": "LAS PALMAS",
        "isla": "Lanzarote",
        "codprov": 57,
        "num": 1,
        "keysdistrito": ""
    }
];
export const INMOVILLA_TYPES = [
    {
        "cod_tipo": 2799,
        "tipo": "Apartamento",
        "num": 1
    },
    {
        "cod_tipo": 2899,
        "tipo": "Ático",
        "num": 1
    },
    {
        "cod_tipo": 399,
        "tipo": "Casa",
        "num": 1
    },
    {
        "cod_tipo": 499,
        "tipo": "Chalet",
        "num": 1
    },
    {
        "cod_tipo": 2999,
        "tipo": "Dúplex",
        "num": 1
    },
    {
        "cod_tipo": 1299,
        "tipo": "Local comercial",
        "num": 1
    },
    {
        "cod_tipo": 3399,
        "tipo": "Piso",
        "num": 1
    },
    {
        "cod_tipo": 7899,
        "tipo": "Restaurante",
        "num": 1
    },
    {
        "cod_tipo": 3899,
        "tipo": "Solar",
        "num": 1
    },
    {
        "cod_tipo": 4199,
        "tipo": "Terreno urbano",
        "num": 1
    },
    {
        "cod_tipo": 4999,
        "tipo": "Villa",
        "num": 1
    }
]
export const operations = [
    'Comprar',
    'Alquilar',
    'Traspasar',
];
export const rangePrice = {
    min: 0,
    max: 500000
}